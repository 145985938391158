import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { type z } from 'zod'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/verticals/criteria/constants'
import { criteriaKeys } from '#src/routes/calibrate/verticals/criteria/queries'
import { verticalKeys } from '#src/routes/calibrate/verticals/queries'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { unassignCriterion } from '../mutations'

export type ActionResType = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(type: z.infer<typeof CRITERION_TYPE_ENUM>) =>
	async ({ params }: ActionFunctionArgs) => {
		validateRouteParams(params, ['ecosystemId', 'criterionId'])
		const { company, companyId } = await getCompany(params)

		try {
			await unassignCriterion(company.id, params.ecosystemId, params.criterionId)

			await Promise.all([
				client.invalidateQueries({ queryKey: criteriaKeys.all }),
				client.invalidateQueries({ queryKey: ecosystemKeys.all }),
				client.invalidateQueries({ queryKey: verticalKeys.all }),
			])
		} catch (e) {
			captureException(e)
			showToast({
				message: 'Unexpected error while unassigning criterion',
				type: 'error',
			})
		}

		return redirect(
			routes.calibrate.criteria.editAnswers({
				companyId,
				ecosystemId: params.ecosystemId,
				criterionType: type,
			}),
		)
	}
