import { type LoaderFunctionArgs } from 'react-router-dom'
import { companyChatRoleplayScenarioQueries } from '#src/api/icp/company/chat/roleplay/scenario/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['scenarioId'])

	return {
		scenario: await client.fetchQuery(companyChatRoleplayScenarioQueries.item(company.id, params.scenarioId)),
	}
}
