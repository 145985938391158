import { useMemo } from 'react'
import { useLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { type VoicesAPISchema } from '#src/api/icp/company/chat/roleplay/scenario/schemas'

type UseVoiceLoaderType = z.infer<typeof VoicesAPISchema>

export const useVoice = () => {
	const { voices } = useLoaderData() as { voices: UseVoiceLoaderType }

	const voiceOptions = useMemo(() => {
		return voices.map(voice => ({
			value: voice.voiceId,
			label: voice.name,
		}))
	}, [voices])

	return { voiceOptions }
}
