import { type RouteObject } from 'react-router-dom'
import { action as deleteAction } from './actions/delete'
import { action as inviteAction } from './actions/invite'
import { action as itemAction } from './actions/item'
import { action as newAction } from './actions/new'
import { loader as attemptsLoader } from './loaders/attempts'
import { loader as deleteLoader } from './loaders/delete'
import { loader as inviteLoader } from './loaders/invite'
import { loader as itemLoader, type LoaderRes as RoleplayScenarioLoaderResponse } from './loaders/item'
import { loader as listLoader } from './loaders/list'
import { loader as newLoader } from './loaders/new'
import { loader as rootLoader } from './loaders/root'
import { loader as testSessionLoader } from './loaders/test-session'
import Attempts from './views/attempts'
import Invite from './views/invite'
import Item from './views/item'
import List from './views/list'
import New from './views/new'

const roleplayScenariosRouter = {
	path: 'roleplay/scenarios',
	loader: rootLoader,
	handle: {
		layout: {
			enableHeader: true,
		},
		crumb: () => [
			{
				label: 'Roleplay scenarios',
				icon: 'user-group',
			},
		],
	},
	children: [
		{
			index: true,
			element: <List />,
			loader: listLoader,
		},
		{
			path: ':scenarioId',
			element: <Item />,
			loader: itemLoader,
			action: itemAction,

			handle: {
				crumb: (data: RoleplayScenarioLoaderResponse) => [
					{
						label: data.scenario.name ?? data.scenario.title,
						icon: 'user-service-desk',
					},
				],
			},
			children: [
				{
					path: 'invite',
					element: <Invite />,
					loader: inviteLoader,
					action: inviteAction,
				},
				{
					path: 'test-session',
					loader: testSessionLoader,
				},
				{
					path: 'attempts/:sessionId',
					loader: attemptsLoader,
					element: <Attempts />,
				},
				{
					path: 'delete',
					loader: deleteLoader,
					action: deleteAction,
				},
			],
		},
		{
			path: 'new',
			element: <New />,
			loader: newLoader,
			action: newAction,
			handle: {
				layout: {
					disableSidebar: true,
					noPadding: true,
					fullWidth: true,
					enableHeader: false,
				},
			},
		},
	],
} satisfies RouteObject

export default roleplayScenariosRouter
