import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useRouteLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { PlaysFormSchema } from '#src/api/icp/company/plays/schemas'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Button } from '#src/components/ui/button'
import { Sidebar, SidebarFooter, SidebarHeader } from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import useCompany from '#src/hooks/useCompany'
import { type PlaysItemLoaderRes } from '#src/routes/enable/plays/routes/item'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import PlayFormFields from './components/PlayFormFields'

const FORM_ID = (id: number) => `edit-play-form-${id}`

export const View = () => {
	const { companyId } = useCompany()
	const {
		data: { play },
	} = useRouteLoaderData('plays-item') as PlaysItemLoaderRes

	const form = useForm<z.infer<typeof PlaysFormSchema>>({
		resolver: zodResolver(PlaysFormSchema),
		defaultValues: {
			name: play.name,
			instructions: play.instructions,
			inCopilot: play.inCopilot,
			inCrm: play.inCrm,
			personaTypes: play.personaTypes,
			expertise: play.expertise,
			tags: play.tags,
		},
		mode: 'onSubmit',
	})

	const isPending = useIsPending()
	const cancelTo = routes.enable.plays.index({ companyId })

	useEffect(
		() =>
			form.reset({
				name: play.name,
				instructions: play.instructions,
				inCopilot: play.inCopilot,
				inCrm: play.inCrm,
				personaTypes: play.personaTypes,
				expertise: play.expertise,
				tags: play.tags,
			}),
		[form, play],
	)

	return (
		<Sidebar
			size="xl"
			header={<SidebarHeader heading="Edit Play" closeTo={cancelTo} />}
			main={
				<FormWrapper formId={FORM_ID(play.id)} formProps={form} className="flex flex-col gap-6">
					<PlayFormFields />
				</FormWrapper>
			}
			footer={
				<SidebarFooter>
					<Button asChild variant="outline" size="sm">
						<Link to={cancelTo}>Cancel</Link>
					</Button>
					<StatusButton
						status={isPending ? 'pending' : 'idle'}
						size="sm"
						type="submit"
						form={FORM_ID(play.id)}
						disabled={isPending}
					>
						Save
					</StatusButton>
				</SidebarFooter>
			}
		/>
	)
}
