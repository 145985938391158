import { type z } from 'zod'
import { EcosystemListAPISchema, type EcosystemsOrderFormSchema } from '#src/routes/calibrate/ecosystem/schema'
import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'

export const reorderEcosystems = async (companyId: string, data: z.infer<typeof EcosystemsOrderFormSchema>) => {
	await safeFetch(EcosystemListAPISchema, api.company.sort(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}
