import { useMemo } from 'react'
import { Form, useLoaderData } from 'react-router-dom'
import { Dialog } from '#src/components/ui/dialog'
import useCompany from '#src/hooks/useCompany'
import { type PublishLoaderRes } from '#src/routes/calibrate/value-proposition/routes'
import { formatCredits, minutesToHours, useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const PublishStatsModal = () => {
	const { companyId } = useCompany()
	const {
		data: { statistics, statisticsDashboard },
	} = useLoaderData() as PublishLoaderRes

	const formId = 'publishValuePropositionForm'
	const method = 'POST'
	const action = routes.calibrate.valueProposition.publish({ companyId })
	const isPending = useIsPending({ formAction: action, formMethod: method })
	const closeTo = routes.calibrate.valueProposition.index({ companyId })

	const stats = useMemo(() => {
		return {
			...statistics,
			ecosystemsCount: statisticsDashboard?.calibrate.ecosystem_count,
			verticalsCount: statisticsDashboard?.calibrate.vertical_count,
			personasCount: statisticsDashboard?.calibrate.persona_count,
		}
	}, [statistics, statisticsDashboard])

	return (
		<Dialog
			open={true}
			dialogHeading="Are you sure?"
			dialogDescription="Changing the value proposition will affect:"
			closeTo={closeTo}
			actions={[
				{
					label: 'Cancel',
					type: 'cancel',
					to: closeTo,
				},
				{
					label: 'Yes, Publish',
					type: 'submit',
					formId,
				},
			]}
			isSubmitting={isPending}
		>
			<Form id={formId} action={action} method={method}>
				<div className="flex flex-col gap-0.5">
					<p className="flex gap-1.5 text-body-lg">
						<span className="font-bold text-neutral-2-fg">{stats.ecosystemsCount}</span> segments
					</p>
					<p className="flex gap-1.5 text-body-lg">
						<span className="font-bold text-neutral-2-fg">{stats.verticalsCount}</span> verticals
					</p>
					<p className="flex gap-1.5 text-body-lg">
						<span className="font-bold text-neutral-2-fg">{stats.personasCount}</span> persona cards
					</p>
				</div>
				<p className="mt-5 space-x-1 text-body-lg">
					{`This update would typically take `}
					<span className="font-bold text-neutral-2-fg">{minutesToHours(stats.timeSaved)} hours</span> if done manually,
					but it will only take
					<span className="font-bold text-neutral-2-fg">{Math.ceil(stats.time)} minutes</span> with AI and require you
					to invest
					<span className="font-bold text-neutral-2-fg">{formatCredits(stats.cost)} credits</span>!
				</p>
			</Form>
		</Dialog>
	)
}
