import { type LoaderFunctionArgs } from 'react-router-dom'
import { statsQueries } from '#src/api/icp/company/stats/queries'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const [statistics, ecosystems] = await Promise.all([
		client.fetchQuery(statsQueries.dashboard(company.id)),
		client.fetchQuery(ecosystemsQuery(company.id)),
	])

	return {
		data: {
			ecosystems,
			statistics,
		},
	}
}
