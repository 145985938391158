import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { client } from '#src/main'
import { copyValidatePersonas } from '#src/routes/calibrate/personas/mutations'
import { personaKeys } from '#src/routes/calibrate/personas/queries'
import { CopyValidatePersonaFormSchema } from '#src/routes/calibrate/personas/schema'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const copyValidatePersonasAction = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId'])
	const { companyId, company } = await getCompany(params)
	const formData = await request.formData()

	const submission = parseWithZod(formData, {
		schema: CopyValidatePersonaFormSchema,
	})

	if (submission.status !== 'success') {
		throw Error('Failed to parse form data')
	}

	const personasIdsArray = JSON.parse(submission.value.personas) as string[]

	const res = await copyValidatePersonas(company.id, {
		personas: personasIdsArray,
	})

	client.setQueryData(personaKeys.copyValidate(company.id), {
		data: res,
		personaIdsArray: personasIdsArray,
	})

	return redirect(
		routes.calibrate.verticals.copyPersonas({
			companyId,
			ecosystemId: params.ecosystemId,
		}),
	)
}
