import { type LoaderFunctionArgs, redirect } from 'react-router-dom'
import { orgListsQueries } from '#src/api/organise/lists/queries'
import { LeadResearchAgentListItemAPISchema } from '#src/api/organise/lists/schemas'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { getValidSortKeys } from '#src/utils/requests'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	const { companyId, company } = await getCompany(params)
	const page = parseInt(new URL(request.url).searchParams.get('page') ?? '1')
	const sort = getValidSortKeys(new URL(request.url).searchParams.getAll('sort'), LeadResearchAgentListItemAPISchema)

	const values = {
		page: Number.isNaN(page) ? 1 : page,
		sort: sort.length ? sort : ['-createdAt'],
	}

	try {
		const [statistics, jobs] = await Promise.all([
			client.fetchQuery(orgListsQueries.statistics(company.id)),
			client.fetchQuery(orgListsQueries.list(company.id, values.page, values.sort)),
		])

		return {
			values,
			data: {
				statistics,
				jobs,
			},
		}
	} catch (e) {
		showToast({
			message: `${(e as Response).status}: ${(e as Response).statusText} | ${(e as Response).url}`,
			type: 'error',
		})
		throw redirect(routes.organize.index({ companyId }))
	}
}
