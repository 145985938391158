import { useNavigate } from 'react-router-dom'
import DeleteConfirm from '#src/components/delete-confirm'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

const View = () => {
	const { companyId } = useCompany()
	const params = useParsedRouteParams(['conversationId'])
	const navigate = useNavigate()

	return (
		<DeleteConfirm
			defaultOpen={true}
			action={routes.enable.roleplay.session.end({
				companyId,
				conversationId: params.conversationId,
			})}
			actionTitle="Yes, end"
			title={`End Session`}
			description="Are you sure you want to end this session?"
			onOpenChange={isOpen => {
				if (!isOpen) {
					navigate(
						routes.enable.roleplay.session.index({
							companyId,
							conversationId: params.conversationId,
						}),
					)
				}
			}}
		/>
	)
}

export default View
