import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { safeFetch, safeFetchMeta } from '#src/utils/safeFetch'
import { orgDataEnrichmentWorkflowsEndpoints } from './endpoints'
import {
	ContactWaterfallingStatisticsAPISchema,
	ContactWaterfallingListItemAPISchema,
	ContactWaterfallingItemAPISchema,
} from './schemas'

const keys = {
	all: ['org', 'data-enrichment', 'workflows'] as const,
	list: (companyId: z.infer<typeof CompanySchema.shape.id>, page: number, sort: string[]) =>
		[...keys.all, 'list', companyId, page, ...sort] as const,
	item: (jobId: z.infer<typeof ContactWaterfallingItemAPISchema.shape.id>) => [...keys.all, 'item', jobId] as const,
	statistics: (companyId: z.infer<typeof CompanySchema.shape.id>) => [...keys.all, 'statistics', companyId] as const,
}

export const listQuery = (companyId: z.infer<typeof CompanySchema.shape.id>, page: number, sort: string[]) => ({
	queryKey: keys.list(companyId, page, sort),
	queryFn: async () => {
		return await safeFetchMeta(
			PaginatedAPIResSchema(ContactWaterfallingListItemAPISchema),
			orgDataEnrichmentWorkflowsEndpoints.list(companyId, page, sort),
			{ method: 'GET' },
		)
	},
	staleTime: 30000,
})

export const itemQuery = (jobId: z.infer<typeof ContactWaterfallingItemAPISchema.shape.id>) => ({
	queryKey: keys.item(jobId),
	queryFn: async () => {
		return await safeFetch(ContactWaterfallingItemAPISchema, orgDataEnrichmentWorkflowsEndpoints.item(jobId), {
			method: 'GET',
		})
	},
	staleTime: 30000,
})

export const statisticsQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) => ({
	queryKey: keys.statistics(companyId),
	queryFn: async () => {
		return await safeFetch(
			ContactWaterfallingStatisticsAPISchema,
			orgDataEnrichmentWorkflowsEndpoints.statistics(companyId),
			{
				method: 'GET',
			},
		)
	},
	staleTime: 30000,
})

const queries = {
	list: listQuery,
	item: itemQuery,
	statistics: statisticsQuery,
}

export { queries as orgDataEnrichmentWorkflowsQueries, keys as orgDataEnrichmentWorkflowsQueriesKeys }
