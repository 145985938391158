import { type LoaderFunctionArgs, redirect } from 'react-router-dom'
import {
	companyChatRoleplaySessionQueries,
	companyChatRoleplaySessionQueriesKeys,
} from '#src/api/icp/company/chat/roleplay/session/queries'
import { SessionStatus } from '#src/api/icp/company/chat/roleplay/session/schemas'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'
import { restartRoleplaySession } from '../mutations'

export const action = async ({ params }: LoaderFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['conversationId'])
	const { user } = await getUser()

	const conversationData = await client.fetchQuery(
		companyChatRoleplaySessionQueries.item(company.id, params.conversationId),
	)

	if (user.email !== conversationData.conversation.user)
		throw new Response('Access Denied', {
			status: 403,
			statusText: 'Forbidden',
		})

	if ([SessionStatus.Closed, SessionStatus.Crashed].includes(conversationData.conversation.status)) {
		// if closed or crashed, we need to do restart and wait until ready then we redirect user to chat
		const response = await restartRoleplaySession(company.id, params.conversationId)
		const checkStatus = async () => {
			let status = null

			while (status !== SessionStatus.Ready) {
				const statusCheck = await client.fetchQuery({
					...companyChatRoleplaySessionQueries.itemStatus(company.id, response.conversation.id),
					staleTime: 0,
				})
				status = statusCheck.status

				if (statusCheck.status === SessionStatus.Ready) {
					return status
				}

				await new Promise(resolve => setTimeout(resolve, 15 * 1000)) // 15 seconds
			}

			return status
		}

		await checkStatus()

		await client.invalidateQueries({
			queryKey: companyChatRoleplaySessionQueriesKeys.all,
		})
	}

	return redirect(routes.enable.roleplay.session.index({ companyId, conversationId: params.conversationId }))
}
