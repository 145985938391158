import { baseApi } from '#src/utils/endpoints'

const base = `${baseApi}/company`

const endpoints = {
	list: `${base}/list`,
	update: (companyId: string) => `${base}/${companyId}`,
}

export { endpoints as companyEndpoints }
