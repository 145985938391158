import { useQuery } from '@tanstack/react-query'
import { useRouteLoaderData } from 'react-router-dom'
import { userQuery } from '#src/routes/auth/queries'
import { type MainLoaderResponse } from '#src/routes/main/loaders/root'
import {
	checkIsAdminSession,
	checkIsEnableOnlySession,
	checkIsReadOnlySession,
	checkIsUserSession,
} from '#src/utils/misc'

const useAuth = () => {
	const { user: initialUser } = useRouteLoaderData('main-loader') as MainLoaderResponse
	const { data: user } = useQuery({
		...userQuery(),
		initialData: initialUser,
	})

	return {
		user,
		enableOnlySession: checkIsEnableOnlySession(user.roles),
		adminSession: checkIsAdminSession(user.roles),
		userSession: checkIsUserSession(user.roles),
		readOnlySession: checkIsReadOnlySession(user.roles),
	}
}

export default useAuth
