import { type z } from 'zod'
import {
	type ContactWaterfallingItemAPISchema,
	DATA_ENRICHMENT_WORKFLOW_STATUS,
} from '#src/api/organise/data-enrichment/workflows/schemas'

export const canStop = (status: z.infer<typeof ContactWaterfallingItemAPISchema.shape.status>) =>
	[DATA_ENRICHMENT_WORKFLOW_STATUS.InProgress, DATA_ENRICHMENT_WORKFLOW_STATUS.Pending].includes(status)

export const canDownload = (status: z.infer<typeof ContactWaterfallingItemAPISchema.shape.status>) =>
	[
		DATA_ENRICHMENT_WORKFLOW_STATUS.Completed,
		DATA_ENRICHMENT_WORKFLOW_STATUS.InProgress,
		DATA_ENRICHMENT_WORKFLOW_STATUS.Cancelled,
		DATA_ENRICHMENT_WORKFLOW_STATUS.Error,
	].includes(status)
