import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs, redirect, type LoaderFunctionArgs } from 'react-router-dom'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { ecosystemKeys } from '../ecosystem/queries'
import { createPersonas, updatePersona } from './mutations'
import { personaKeys, personaQuery } from './queries'
import { PersonaEditFormSchema, PersonaUpdateFormSchema } from './schema'

export type PersonaActionResponse = Awaited<ReturnType<typeof action>>

export type PersonaLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	if (params.ecosystemId && params.personaId) {
		return {
			persona: await client.fetchQuery(personaQuery(company.id, params.personaId)),
		}
	}

	return {
		persona: null,
	}
}

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId', 'verticalId'])
	const { companyId, company } = await getCompany(params)

	const formData = await request.formData()
	const intent = formData.get('intent')

	if (!intent || intent !== 'persona')
		throw redirect(
			routes.calibrate.persona.index({
				companyId,
				ecosystemId: params.ecosystemId,
				verticalId: params.verticalId,
				personaId: params.personaId || null,
			}),
		)

	let personaId = formData.get('id')?.toString()

	if (personaId) {
		const submission = parseWithZod(formData, {
			schema: PersonaUpdateFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		const data = submission.value

		// NOTE: clever way to remove a keys from an object
		// NOTE: do not send redundant data to API
		// prettier-ignore

		const {
            reportsTo,
            reportsOverride,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
            prompt,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
            pain,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
            personaMapping,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
            vertical,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
            id,
            ...persona
        } = await client.ensureQueryData(
            personaQuery(company.id, personaId.toString()),
        )

		await updatePersona(company.id, personaId.toString(), {
			...(persona ?? {}),
			reportsTo: data.reportsTo || reportsTo?.id || null,
			reportsOverride: data.reportsOverride || reportsOverride || null,
			...data,
		})
	} else {
		const submission = parseWithZod(formData, {
			schema: PersonaEditFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		const data = submission.value

		const response = await createPersonas(company.id, data)

		if (!response?.length) throw Error('Failed to create persona')

		if (response[0].id) personaId = response[0].id.toString()
	}

	await client.invalidateQueries({
		queryKey: personaKeys.all,
	})
	void client.invalidateQueries({
		queryKey: ecosystemKeys.list(company.id),
	})

	return redirect(
		routes.calibrate.persona.index({
			companyId,
			ecosystemId: params.ecosystemId,
			verticalId: params.verticalId,
			personaId: personaId!,
		}),
	)
}
