import { type LoaderFunctionArgs } from 'react-router-dom'
import { type z } from 'zod'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/verticals/criteria/constants'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { generateEditAnswersContext } from '../utils'

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(type: z.infer<typeof CRITERION_TYPE_ENUM>) =>
	async ({ params }: LoaderFunctionArgs) => {
		validateRouteParams(params, ['ecosystemId'])
		const { company, companyId } = await getCompany(params)
		const { defaultValues, updatedCriteria, schema } = await generateEditAnswersContext(
			company.id,
			params.ecosystemId,
			type,
		)

		return {
			values: {
				companyId,
				ecosystemId: params.ecosystemId,
				type,
				schema,
				defaultValues,
			},
			data: {
				criteria: updatedCriteria,
			},
		}
	}
