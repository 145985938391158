import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { type z } from 'zod'
import { PlaysFormSchema } from '#src/api/icp/company/plays/schemas'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Button } from '#src/components/ui/button'
import { Sidebar, SidebarFooter, SidebarHeader } from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import useCompany from '#src/hooks/useCompany'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import PlayFormFields from './components/PlayFormFields'

const FORM_ID = 'create-play-form'

export const View = () => {
	const { companyId } = useCompany()

	const form = useForm<z.infer<typeof PlaysFormSchema>>({
		resolver: zodResolver(PlaysFormSchema),
		defaultValues: {
			name: '',
			instructions: '',
			inCopilot: true,
			inCrm: false,
			personaTypes: [],
			expertise: [],
			tags: [],
		},
		mode: 'onSubmit',
	})

	const isPending = useIsPending()
	const cancelTo = routes.enable.plays.index({ companyId })

	return (
		<Sidebar
			size="xl"
			header={<SidebarHeader heading="Create new Play" closeTo={cancelTo} />}
			main={
				<FormWrapper formId={FORM_ID} formProps={form} className="flex flex-col gap-6">
					<PlayFormFields />
				</FormWrapper>
			}
			footer={
				<SidebarFooter>
					<Button asChild variant="outline" size="sm">
						<Link to={cancelTo}>Cancel</Link>
					</Button>
					<StatusButton
						status={isPending ? 'pending' : 'idle'}
						size="sm"
						type="submit"
						form={FORM_ID}
						disabled={isPending}
					>
						Create
					</StatusButton>
				</SidebarFooter>
			}
		/>
	)
}
