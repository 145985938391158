import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { cn } from '#src/utils/misc'

export const PersonaMappingNavigator = ({ items }: { items: { id: string; heading: string }[] }) => {
	const location = useLocation()
	useEffect(() => {
		if (!location.hash) {
			return
		}
		const targetElement = document.querySelector(location.hash)
		if (!targetElement) {
			return
		}
		targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}, [location])

	return (
		<aside>
			<ul className="sticky top-[calc(var(--builder-header-height)+1rem)]">
				{items.map(({ id, heading }) => (
					<li
						key={id}
						className={cn(
							'border-l-2 border-neutral-1-bd px-4 py-1.5 transition-all hover:border-brand-1-bd-hover',
							location.hash === `#${id}` ? 'border-bd-brand-1 text-brand-1-fg' : 'text-neutral-2-fg',
						)}
					>
						<Link to={`${location.pathname}${location.search}#${id}`} className={cn('text-body-md font-medium')}>
							{heading}
						</Link>
					</li>
				))}
			</ul>
		</aside>
	)
}
