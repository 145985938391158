import { z } from 'zod'
import { CompanySchema } from '#src/routes/company/schema'
import { zNumber } from '#src/utils/validation'
import { ACCEPTED_FILE_TYPES, FILE_TYPES, LRA_JOB_STATUS, MAX_UPLOAD_SIZE } from './constants'

export const LeadResearchAgentStatisticsAPISchema = z.object({
	inQueueCount: z.number(),
	inProgressCount: z.number(),
	completedCount: z.number(),
})

export const LeadResearchAgentListItemAPISchema = z.object({
	id: z.string(),
	createdAt: z.string(),
	status: z.nativeEnum(LRA_JOB_STATUS),
	title: z.string(),
	contactFinderEnabled: z.boolean(),
	contactWaterfallingEnabled: z.boolean(),
	comment: z.string().nullable(),
	importType: z.nativeEnum(FILE_TYPES).nullable(),
	statistic: z.object({
		accountCompletedCount: z.number(),
		accountContactCount: z.number(),
		accountCount: z.number(),
		accountInProgressCount: z.number(),
		accountInQueueCount: z.number(),
		accountWithErrorsCount: z.number(),
		contactCompletedCount: z.number(),
		contactCount: z.number(),
		contactInProgressCount: z.number(),
		contactInQueueCount: z.number(),
		contactWithEmailPhoneCount: z.number(),
		contactWithErrorsCount: z.number(),
		ibpRatio: z.number(),
		icpRatio: z.number(),
		// new fields after api changes
		icpStatusYesCount: z.number(),
		icpStatusNoCount: z.number(),
		icpStatusInconclusiveCount: z.number(),
		icpStatusNullCount: z.number(),
		ibpStatusYesCount: z.number(),
		ibpStatusNoCount: z.number(),
		ibpStatusInconclusiveCount: z.number(),
		ibpStatusNullCount: z.number(),
	}),
	accountsContactsImported: z.number().nullable(),
})
export const LeadResearchAgentItemAPISchema = LeadResearchAgentListItemAPISchema.merge(
	z.object({
		verticals: z.array(
			z.object({
				id: z.string(),
				title: z.string(),
			}),
		),
		importFileUrl: z.string().nullable(),
		inconclusiveInsights: z.string().nullable(),
	}),
)

export const LeadResearchAgentJobFileFormSchema = z.object({
	type: z.nativeEnum(FILE_TYPES),
	file: z
		.instanceof(File, { message: 'Please select a file' })
		.refine(file => {
			return !file || file.size <= MAX_UPLOAD_SIZE
		}, 'File size must be less than 3MB')
		.refine(file => {
			return !file || ACCEPTED_FILE_TYPES.includes(file.type)
		}, 'File must be a CSV'),
})

export const LeadResearchAgentJobVerticalsFormSchema = z.object({
	verticals: z.array(z.string()).nonempty('You need to select at least one vertical'),
})

export enum CONTACT_FINDER_SETTINGS_TYPE {
	ContactsPerAccount = 'contacts_per_account',
	ContactsPerPersonas = 'contacts_per_persona',
}

export const LeadResearchAgentJobSettingsContactFinderSettingsSchema = z.discriminatedUnion('type', [
	z.object({
		type: z.literal(CONTACT_FINDER_SETTINGS_TYPE.ContactsPerAccount),
		contactsPerAccount: zNumber(z.number().min(1).max(5)),
	}),
	z.object({
		type: z.literal(CONTACT_FINDER_SETTINGS_TYPE.ContactsPerPersonas),
		contactsPerPersonas: z.record(z.string(), z.record(z.string(), zNumber(z.number().min(0).max(3)))),
	}),
])

export const LeadResearchAgentJobSettingsFormSchema = z.discriminatedUnion('contactFinderEnabled', [
	z.object({
		contactFinderEnabled: z.literal(true),
		contactWaterfallingEnabled: z.boolean(),
		contactFinderSettings: LeadResearchAgentJobSettingsContactFinderSettingsSchema,
	}),
	z.object({
		contactFinderEnabled: z.literal(false),
		contactWaterfallingEnabled: z.boolean(),
	}),
])

export const LeadResearchAgentJobAdditionalInfoFormSchema = z.object({
	title: z.string(),
	comment: z.string().nullable(),
})

// export const LeadResearchAgentJobFormSchema = z
// 	.object({ companyId: CompanySchema.shape.id })
// 	.merge(LeadResearchAgentJobFileFormSchema)
// 	.merge(LeadResearchAgentJobVerticalsFormSchema)
// 	.merge(LeadResearchAgentJobSettingsFormSchema)
// 	.merge(LeadResearchAgentJobAdditionalInfoFormSchema)

export const LeadResearchAgentJobFormSchema = LeadResearchAgentJobSettingsFormSchema.and(
	z
		.object({ companyId: CompanySchema.shape.id })
		.merge(LeadResearchAgentJobFileFormSchema)
		.merge(LeadResearchAgentJobVerticalsFormSchema)
		.merge(LeadResearchAgentJobAdditionalInfoFormSchema),
)
