import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { type z } from 'zod'
import { AdminCompanyDashboardFormSchema } from '#src/api/icp/admin/company/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { StatusButton } from '#src/components/ui/status-button'
import { type CompanySchema } from '#src/routes/company/schema'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

type Props = {
	company: z.infer<typeof CompanySchema>
}

export const EditCompanyDetailsForm = (props: Props) => {
	const { company } = props
	const formProps = useForm<z.infer<typeof AdminCompanyDashboardFormSchema>>({
		resolver: zodResolver(AdminCompanyDashboardFormSchema),
		defaultValues: {
			dashboard: company.dashboard ?? [],
		},
		mode: 'onSubmit',
	})

	const action = routes.admin.company.edit({ companyId: company.id })
	const method = 'PATCH'

	const isPending = useIsPending({
		formMethod: method,
		formAction: action,
	})
	const formId = 'editCompany'

	return (
		<FormWrapper
			formProps={formProps}
			formId={formId}
			action={action}
			method={method}
			className="flex w-full flex-col gap-6"
		>
			<h1 className="text-center text-title-md">Company Dashboard URLs</h1>
			{company.dashboard?.map((el, index) => (
				<FormField
					key={`company-dashboard-${el.id}`}
					fieldType="text"
					name={`dashboard[${index}].url`}
					label={el.name}
					placeholder={`Enter url`}
				/>
			))}
			<div className="flex justify-end">
				<StatusButton
					status={isPending ? 'pending' : 'idle'}
					disabled={isPending}
					type="submit"
					size="sm"
					className="min-w-32"
				>
					Save
				</StatusButton>
			</div>
		</FormWrapper>
	)
}
