import { type RouteObject } from 'react-router-dom'
import contactWaterfallingRouter from '#src/routes/organize/contact-waterfalling/router'
import leadResearchAgentRouter from '#src/routes/organize/lead-research-agent/router'
import { organizeDashboardLoader, OrganizeDashboardView } from '#src/routes/organize/root/routes/dashboard'

export default {
	path: 'organize',
	handle: {
		crumb: () => [
			{
				label: 'Organize',
				icon: 'data-unreal',
			},
		],
	},
	children: [
		{
			index: true,
			loader: organizeDashboardLoader,
			element: <OrganizeDashboardView />,
		},
		leadResearchAgentRouter,
		contactWaterfallingRouter,
	],
} satisfies RouteObject
