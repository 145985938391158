import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs } from 'react-router-dom'
import { orgListsCsvMutations } from '#src/api/organise/lists-csv/mutations'
import { LeadResearchAgentTemplateDownloadFormSchema } from '#src/api/organise/lists-csv/schemas'
import { showToast } from '#src/context/ToastContext'
import { makeDownloadableFromRes } from '#src/utils/files'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData()

	const submission = parseWithZod(formData, {
		schema: LeadResearchAgentTemplateDownloadFormSchema,
	})

	if (submission.status !== 'success') {
		showToast({ message: 'Unsupported template type', type: 'error' })
		return { success: false }
	}

	try {
		const res = await orgListsCsvMutations.downloadTemplate(submission.value)
		await makeDownloadableFromRes(res, `${submission.value.type}-template`)

		return {
			success: true,
		}
	} catch (e) {
		const res = (e as Response).clone()
		const body = (await res.json()) as { success: boolean; message?: string }
		showToast({
			message: body?.message ?? res?.statusText,
			type: 'error',
		})
		return body
	}
}
