import { type LoaderFunctionArgs } from 'react-router-dom'
import { statsQueries } from '#src/api/icp/company/stats/queries'
import { client } from '#src/main'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const [statistics, statisticsDashboard] = await Promise.all([
		statsQueries.valueProposition(company.id),
		client.fetchQuery(statsQueries.dashboard(company.id)),
	])

	return {
		data: {
			statistics,
			statisticsDashboard,
		},
	}
}
