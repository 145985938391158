import { useQuery } from '@tanstack/react-query'
import { type z } from 'zod'
import { PERSONA_DETAILS_REFRESH_INTERVAL } from '#src/constants/refetchIntervals'
import useCompany from '#src/hooks/useCompany'
import { verticalQuery } from '#src/routes/calibrate/verticals/queries'
import { type VerticalAPISchema } from '#src/routes/calibrate/verticals/schema'

type Props = {
	verticalId: number | string
	initialData: z.infer<typeof VerticalAPISchema>
}

const useVerticalDetails = (props: Props) => {
	const { verticalId, initialData } = props
	const { company } = useCompany()

	const { data, isLoading } = useQuery({
		...verticalQuery(company.id, String(verticalId)),
		initialData,
		refetchInterval: ({ state: { data: currentData } }) =>
			currentData?.prompt?.status && ['Pending', 'In Progress'].includes(currentData.prompt.status)
				? PERSONA_DETAILS_REFRESH_INTERVAL
				: false,
		refetchOnWindowFocus: true,
	})

	return {
		data,
		isLoading,
	}
}

export default useVerticalDetails
