import { cva, type VariantProps } from 'class-variance-authority'
import { type ComponentPropsWithoutRef, Fragment, type ReactNode } from 'react'
import { Checkbox, type checkboxVariants } from '#src/components/ui/checkbox'
import {
	selectionControlDisabledVariants,
	defaultLabelSizeVariants,
	selectionControlLayoutVariants,
	selectionControlSpacingVariants,
} from '#src/theme'
import { cn } from '#src/utils/misc'

export type CheckboxGroupOption = {
	value: string | number
	label: ReactNode
	content?: ReactNode
}

const checkboxItemVariants = cva('peer rounded-sm', {
	variants: {
		disabled: selectionControlDisabledVariants,
	},
	defaultVariants: {
		disabled: false,
	},
})

const labelVariants = cva('ml-2', {
	variants: {
		size: defaultLabelSizeVariants,
		disabled: selectionControlDisabledVariants,
	},
	defaultVariants: {
		size: 'md',
		disabled: false,
	},
})

export type CheckboxGroupProps = {
	options: CheckboxGroupOption[]
	value?: (string | number)[]
	onChange?: (value: (string | number)[]) => void
	layout?: keyof typeof selectionControlLayoutVariants
	spacing?: keyof (typeof selectionControlSpacingVariants)['horizontal']
	size?: VariantProps<typeof checkboxVariants>['size']
	disabled?: boolean
} & ComponentPropsWithoutRef<'div'>

export const CheckboxGroup = ({
	options,
	value = [],
	onChange,
	layout = 'vertical',
	spacing = 'md',
	size = 'md',
	disabled = false,
	...rest
}: CheckboxGroupProps) => {
	const normalizedValue = Array.isArray(value) ? value : [value]

	const handleChange = (optionValue: string | number) => {
		const newValue = normalizedValue.includes(optionValue)
			? normalizedValue.filter(v => v !== optionValue)
			: [...normalizedValue, optionValue]

		if (onChange) {
			onChange(newValue)
		}
	}

	return (
		<div
			className={cn(
				checkboxItemVariants({ disabled: null }),
				selectionControlLayoutVariants[layout],
				selectionControlSpacingVariants[layout][spacing],
			)}
			{...rest}
		>
			{options.map(
				option =>
					option.value !== '-' && (
						<Fragment key={option.value}>
							<div className="flex items-center">
								<Checkbox
									id={String(option.value)}
									checked={normalizedValue.includes(option.value)}
									onCheckedChange={() => handleChange(option.value)}
									disabled={disabled}
									size={size}
								/>
								<label htmlFor={String(option.value)} className={cn(labelVariants({ size, disabled }))}>
									{option.label}
								</label>
							</div>
							{normalizedValue.includes(option.value) && option.content && (
								<div className="px-6 pb-2 pt-2.5">{option.content}</div>
							)}
						</Fragment>
					),
			)}
		</div>
	)
}
