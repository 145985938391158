import { Link, useLocation, useRevalidator } from 'react-router-dom'
import { Dropdown, DropdownItem } from '#src/components/ui/dropdown'
import { Icon, type IconName } from '#src/components/ui/icon'
import { Logo } from '#src/components/ui/logo'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '#src/components/ui/tooltip'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { logout } from '#src/routes/auth/utils'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const navLinkClasses = (currentPath: boolean | number, isDisabled: boolean = false) =>
	cn(
		'flex shrink-0 items-center justify-center rounded-sm p-1.5 transition-colors relative',
		currentPath && !isDisabled
			? 'bg-neutral-3-bg-selected text-brand-1-fg hover:bg-neutral-3-bg-selected-hover'
			: 'bg-neutral-3-bg text-neutral-3-fg hover:bg-neutral-3-bg-hover',
		isDisabled ? 'cursor-not-allowed hover:bg-neutral-3-bg' : 'cursor-pointer',
	)

export const useRouteMatches = () => {
	const { pathname } = useLocation()
	const { companyId } = useCompany()
	const companyMatch = pathname === routes.company.index({ companyId })
	const calibrateMatch = pathname.startsWith(`${companyId ? `/${companyId}` : ''}/calibrate`)
	const enrichMatch = pathname.startsWith(`${companyId ? `/${companyId}` : ''}/prioritize`)
	const organizeMatch = pathname.startsWith(`${companyId ? `/${companyId}` : ''}/organize`)
	const enableMatch = pathname.startsWith(`${companyId ? `/${companyId}` : ''}/enable`)
	const settingsMatch = pathname.startsWith(`${companyId ? `/${companyId}` : ''}/settings`)
	const reportMatch = pathname.startsWith(`${companyId ? `/${companyId}` : ''}/report`)

	return {
		companyMatch,
		calibrateMatch,
		enrichMatch,
		organizeMatch,
		enableMatch,
		settingsMatch,
		reportMatch,
	}
}

const Sidebar = () => {
	const { user, enableOnlySession, adminSession, userSession } = useAuth()

	const { companyId } = useCompany()

	const { companyMatch, calibrateMatch, enrichMatch, organizeMatch, enableMatch, settingsMatch, reportMatch } =
		useRouteMatches()

	const revalidator = useRevalidator()

	const modules: { to: string; icon: IconName; title: string; enabled: boolean; active: boolean }[] = [
		{
			to: routes.company.index({ companyId }),
			icon: 'home',
			title: 'Dashboard',
			enabled: !enableOnlySession,
			active: companyMatch,
		},
		{
			to: routes.calibrate.index({ companyId }),
			icon: 'machine-learning-model',
			title: 'Calibrate',
			enabled: !enableOnlySession,
			active: calibrateMatch,
		},
		{
			to: routes.organize.index({ companyId }),
			icon: 'data-unreal',
			title: 'Organize',
			enabled: !enableOnlySession,
			active: organizeMatch,
		},
		{
			to: routes.prioritize.index({ companyId }),
			icon: 'upstream',
			title: 'Prioritize',
			enabled: !enableOnlySession,
			active: enrichMatch,
		},
		{
			to: routes.enable.index({ companyId }),
			icon: 'data-enrichment',
			title: 'Enable',
			enabled: true,
			active: enableMatch,
		},
		{
			to: routes.report.index({ companyId }),
			icon: 'report-data',
			title: 'Report',
			enabled: !enableOnlySession,
			active: reportMatch,
		},
	]

	return (
		<div
			id="layout-sidebar"
			className="pointer-events-none fixed left-0 top-0 z-50 h-screen w-14 border-r border-r-neutral-1-bd bg-neutral-2-bg"
		>
			<div className="pointer-events-auto flex h-full flex-col items-center gap-2">
				<Link
					to={routes.company.index({ companyId })}
					onClick={() => revalidator.revalidate()} //todo: i dont know if we need this, it looks like it does nothing
					className="flex items-center p-4"
				>
					<Logo type="symbol" size="xs" />
				</Link>
				{modules
					.filter(module => module.enabled)
					.map(module => (
						<TooltipProvider key={`module-${module.title}`}>
							<Tooltip delayDuration={100}>
								<TooltipTrigger asChild>
									<Link to={module.to} className={navLinkClasses(module.active)}>
										<Icon name={module.icon} size="font" className="text-[1.25rem]" />
									</Link>
								</TooltipTrigger>
								<TooltipContent side="right" align="center">
									{module.title}
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					))}
				<div className="my-3 mt-auto flex flex-col items-center gap-3">
					{(adminSession || userSession) && (
						<TooltipProvider>
							<Tooltip delayDuration={100}>
								<TooltipTrigger asChild>
									<Link to={routes.settings.index({ companyId })} className={navLinkClasses(settingsMatch)}>
										<Icon name="settings" size="font" className="text-[1.25rem]" />
									</Link>
								</TooltipTrigger>
								<TooltipContent side="right" align="center">
									Settings
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					)}
					{import.meta.env.DEV && (
						<TooltipProvider>
							<Tooltip delayDuration={100}>
								<TooltipTrigger asChild>
									<Link to={routes.developers.playground()}>
										<Icon name="magic-wand" size="font" className="text-[1.25rem] text-neutral-3-fg" />
									</Link>
								</TooltipTrigger>
								<TooltipContent side="right" align="center">
									Developers: Playground
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					)}

					{/*<a*/}
					{/*	href="https://docs.evergrowth.com"*/}
					{/*	target="__blank"*/}
					{/*	aria-description="Evergrowth Docs"*/}
					{/*	title="Evergrowth Docs"*/}
					{/*	className="flex shrink-0 items-center justify-center rounded-sm bg-neutral-3-bg p-1.5 text-neutral-3-fg transition-colors hover:bg-neutral-3-bg-hover"*/}
					{/*>*/}
					{/*	<Icon name="help-filled" size="font" className="text-[1.25rem]" />*/}
					{/*</a>*/}

					<Dropdown
						trigger={
							<button
								type="button"
								className="flex h-8 w-8 items-center justify-center rounded-full bg-brand-1-bg text-body-sm font-medium text-brand-1-fg"
							>
								{user.email.slice(0, 1).toUpperCase()}
							</button>
						}
						contentProps={{
							side: 'right',
							align: 'end',
						}}
					>
						{adminSession && (
							<DropdownItem asChild className="justify-start">
								<Link to={routes.admin.index}>
									<Icon name="settings-services" size="sm" />
									Admin
								</Link>
							</DropdownItem>
						)}
						<DropdownItem asChild className="min-w-32 justify-start">
							<button
								type="button"
								onClick={() => {
									logout()
								}}
							>
								<Icon name="logout" size="sm" />
								Log out
							</button>
						</DropdownItem>
					</Dropdown>
				</div>
			</div>
		</div>
	)
}

export default Sidebar
