import { clsx } from 'clsx'
import { DATA_ENRICHMENT_WORKFLOW_STATUS } from '#src/api/organise/data-enrichment/workflows/schemas'
import { Icon, type IconName } from '#src/components/ui/icon'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'
import { JobStatusName } from '../../constants'

type Props = {
	status: DATA_ENRICHMENT_WORKFLOW_STATUS
	progress?: {
		current: number
		total: number
	}
}

const LABEL_BG: Record<DATA_ENRICHMENT_WORKFLOW_STATUS, ClassName> = {
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Pending]: 'bg-brand-1-bg',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.InProgress]: 'bg-brand-1-bg',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Completed]: 'bg-green-10',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Cancelled]: 'bg-red-10',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Error]: 'bg-red-10',
} as const

const ICON: Record<DATA_ENRICHMENT_WORKFLOW_STATUS, IconName> = {
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Pending]: 'loading-sm',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.InProgress]: 'loading-sm',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Completed]: 'checkmark-filled',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Cancelled]: 'stop-sign-filled',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Error]: 'stop-sign-filled',
} as const

const COLOR: Record<DATA_ENRICHMENT_WORKFLOW_STATUS, ClassName> = {
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Pending]: 'text-brand-1-fg',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.InProgress]: 'text-brand-1-fg',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Completed]: 'text-green-80',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Cancelled]: 'text-red-60',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Error]: 'text-red-60',
} as const

const PROGRESS_BG: Record<DATA_ENRICHMENT_WORKFLOW_STATUS, ClassName> = {
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Pending]: 'bg-brand-3-bg',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.InProgress]: 'bg-brand-3-bg',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Completed]: 'bg-green-80',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Cancelled]: 'bg-red-60',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Error]: 'bg-red-60',
} as const

const PROGRESS_COLOR: Record<DATA_ENRICHMENT_WORKFLOW_STATUS, ClassName> = {
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Pending]: 'text-brand-3-fg',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.InProgress]: 'text-brand-3-fg',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Completed]: 'text-green-10',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Cancelled]: 'text-red-10',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Error]: 'text-red-10',
} as const

export const StatusLabel = (props: Props) => {
	const { status, progress } = props

	return (
		<div className={clsx('inline-flex flex-row items-center gap-1 rounded-xl p-1', LABEL_BG[status], COLOR[status])}>
			<Icon name={ICON[status]} className={cn('h-4 w-4', ICON[status].includes('loading') && 'animate-spin')} />
			<div className={cn('text-body-sm', !progress && 'mr-1')}>{JobStatusName[status]}</div>
			{!!progress && (
				<div
					className={clsx(
						'rounded-xl px-2 py-0.5 text-label-sm text-brand-3-fg',
						PROGRESS_BG[status],
						PROGRESS_COLOR[status],
					)}
				>
					{progress.current}/{progress.total}
				</div>
			)}
		</div>
	)
}
