import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { type z } from 'zod'
import { PersonaData } from '#src/components/persona'
import { Icon } from '#src/components/ui/icon'
import useAuth from '#src/hooks/useAuth'
import { type EcosystemAPIPersonaSchema } from '#src/routes/calibrate/ecosystem/schema'
import {
	type AssignedSignalsAPISchema,
	type AssignedSignalsFormSchema,
	type SignalTemplateAPISchema,
} from '#src/routes/prioritize/schema'
import { AssignedPersonaManualInput } from '#src/routes/prioritize/views/components/item/AssignedPersonaManualInput'
import { SignalUsageExample } from '#src/routes/prioritize/views/components/item/SignalUsageExample'
import { Table } from '#src/routes/prioritize/views/components/Table'
import { TableAction } from '#src/routes/prioritize/views/components/TableAction'

type Props = {
	signalId: string
	ecosystemId: number
	verticalId: number
	personas: z.infer<typeof EcosystemAPIPersonaSchema>[]
	assignedSignals: z.infer<typeof AssignedSignalsAPISchema>
	manualInputRequired: boolean
	defaultManualInput: boolean
	templateManualInput: z.infer<typeof SignalTemplateAPISchema>['config']['manualInput']
}

type FormType = z.infer<typeof AssignedSignalsFormSchema>

export const PersonasTable = (props: Props) => {
	const {
		signalId,
		ecosystemId,
		verticalId,
		personas,
		assignedSignals,
		manualInputRequired,
		defaultManualInput,
		templateManualInput,
	} = props
	const { readOnlySession } = useAuth()

	const form = useFormContext<FormType>()
	const personasIds = useMemo(() => personas.map(i => String(i.id)), [personas])

	const selectedPersonasLength = Object.entries(form.watch('personas')).filter(
		([personaId, { selected }]) => personasIds.includes(personaId) && selected === 'on',
	).length

	return (
		<Table
			data={personas}
			columns={[
				{
					hidden: readOnlySession,
					name: 'check',
					heading: {
						className: 'w-8',
						title: (
							<TableAction
								checked={
									selectedPersonasLength === personas.length ? true : selectedPersonasLength ? 'indeterminate' : false
								}
								onCheckedChange={checked =>
									personas.forEach(item => {
										form.setValue(`personas.${item.id}.selected`, checked ? 'on' : 'off')
									})
								}
							/>
						),
					},
					body: {
						render: item => (
							<TableAction
								checked={form.watch(`personas.${item.id}.selected`) === 'on'}
								onCheckedChange={checked => form.setValue(`personas.${item.id}.selected`, checked ? 'on' : 'off')}
							/>
						),
					},
				},
				{
					name: 'persona',
					heading: {
						title: 'Persona',
					},
					body: {
						render: item => (
							<PersonaData
								id={item.id}
								type={item.type}
								status={item.status}
								priority={item.priority}
								expertise={item.expertise}
								ecosystemId={ecosystemId}
								verticalId={verticalId.toString()}
							/>
						),
					},
				},
				{
					name: 'status',
					heading: {
						title: 'Status',
					},
					body: {
						render: item =>
							assignedSignals.some(s => s.personaId === item.id) ? (
								<span className="inline-flex items-center gap-1 text-body-md text-neutral-1-fg">
									<Icon name="checkmark-filled" size="sm" className="text-green-70" />
									Mapped
								</span>
							) : (
								<span className="inline-flex items-center gap-1 text-body-md text-neutral-3-fg">
									<Icon name="checkmark-outline" size="sm" />
									Unmapped
								</span>
							),
					},
				},
				{
					hidden: readOnlySession,
					name: 'manual-input',
					heading: {
						title: <span>Manual Input {manualInputRequired && <span className="text-status-danger-fg">*</span>}</span>,
					},
					body: {
						render: item =>
							defaultManualInput ? (
								templateManualInput ? (
									<div className="text-body-sm text-neutral-2-fg">{templateManualInput}</div>
								) : (
									<div>
										<button
											onClick={() => document.getElementById('manualInput')?.focus()}
											type="button"
											className="inline-flex gap-1 text-left text-button-sm text-link hover:text-link-hover active:text-link-pressed"
										>
											<Icon name="add-alt" size="sm" />
											Enter default manual input
										</button>
									</div>
								)
							) : (
								<AssignedPersonaManualInput
									value={form.watch(`personas.${item.id}.manualInput`) ?? ''}
									onValueChange={value => {
										form.setValue(`personas.${item.id}.manualInput`, value)
										form.setValue(`personas.${item.id}.selected`, 'on')
									}}
									onChangeAll={value => {
										console.log('this one is called')
										personas.forEach(item => {
											form.setValue(`personas.${item.id}.selected`, 'on')
											form.setValue(`personas.${item.id}.manualInput`, value)
										})
									}}
								/>
							),
					},
				},
				{
					hidden: readOnlySession,
					name: 'usage-example',
					heading: {
						title: 'Usage example',
					},
					body: {
						render: item => (
							<SignalUsageExample
								signalId={signalId}
								personaId={String(item.id)}
								personaJobTitle={item.jobTitles ?? item.expertise}
							/>
						),
					},
				},
			]}
		/>
	)
}
