import { z } from 'zod'

export const EcosystemAPIPersonaSchema = z.object({
	id: z.number(),
	type: z.string(),
	jobTitles: z.string().nullable().optional(),
	jobSeniority: z.string().nullable().optional(),
	expertise: z.string(),
	status: z.string(),
	priority: z.string(),
})

export const EcosystemAPIVerticalSchema = z.object({
	id: z.number(),
	name: z.string(),
	personas: EcosystemAPIPersonaSchema.array(),
	status: z.string(),
	priority: z.string(),
})

export const EcosystemAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	verticals: EcosystemAPIVerticalSchema.array(),

	//TODO: Make not optional after migration
	verticalAsCompany: z.boolean().optional(),
})

export const EcosystemListAPISchema = z.array(EcosystemAPISchema)

export const EcosystemTemplate = z.object({
	id: z.number(),
	name: z.string(),
})

export const EcosystemTemplateListAPISchema = z.array(EcosystemTemplate)

export const EcosystemTemplateFormSchema = z.object({
	ecosystemId: z.string(),
})

export const EcosystemFormSchema = z.object({
	id: z.string().optional(),
	name: z.string(),
	verticalAsCompany: z
		.string()
		.optional()
		.transform(val => val === 'true'),
	template: z.string().nullable().optional(),
})

export const EcosystemsOrderFormSchema = z.object({
	ecosystems: z.array(z.number()),
})
