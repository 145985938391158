import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { companyChatArchiveQueriesKeys } from '#src/api/icp/company/chat/archive/queries'
import { companyChatRoleplaySessionQueriesKeys } from '#src/api/icp/company/chat/roleplay/session/queries'
import { client } from '#src/main'
import { endRoleplaySession } from '#src/routes/enable/roleplay-sessions/mutations'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['conversationId'])

	await endRoleplaySession(company.id, params.conversationId)

	await client.invalidateQueries({
		queryKey: companyChatRoleplaySessionQueriesKeys.all,
	})
	await client.invalidateQueries({
		queryKey: companyChatArchiveQueriesKeys.list(company.id, params.conversationId),
	})

	return redirect(
		routes.enable.roleplay.session.attempts({
			companyId,
			conversationId: params.conversationId,
		}),
	)
}
