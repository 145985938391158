import {
	CommunicationStyle,
	DecisionMakingProcess,
	EmotionalState,
	PersonalityTrait,
} from '#src/api/icp/company/chat/roleplay/session/schemas'

export const PERSONALITY_TRAIT_OPTIONS = [
	{ value: PersonalityTrait.Analytical, label: 'Analytical' },
	{ value: PersonalityTrait.Amiable, label: 'Amiable' },
	{ value: PersonalityTrait.Expressive, label: 'Expressive' },
	{ value: PersonalityTrait.Driver, label: 'Driver' },
]

export const EMOTIONAL_STATE_OPTIONS = [
	{ value: EmotionalState.Skeptical, label: 'Skeptical' },
	{ value: EmotionalState.Supportive, label: 'Supportive' },
	{ value: EmotionalState.Busy, label: 'Busy' },
	{ value: EmotionalState.Indecisive, label: 'Indecisive' },
]

export const COMMUNICATION_STYLE_OPTIONS = [
	{ value: CommunicationStyle.Direct, label: 'Direct' },
	{ value: CommunicationStyle.Indirect, label: 'Indirect' },
	{ value: CommunicationStyle.Formal, label: 'Formal' },
	{ value: CommunicationStyle.Informal, label: 'Informal' },
]

export const DECISION_MAKING_PROCESS_OPTIONS = [
	{ value: DecisionMakingProcess.Collaborative, label: 'Collaborative' },
	{ value: DecisionMakingProcess.Autonomous, label: 'Autonomous' },
	{ value: DecisionMakingProcess.Hierarchical, label: 'Hierarchical' },
]
