import { z, type ZodNumber, type ZodString, type ZodType, type ZodTypeDef } from 'zod'

/**
 * Cleans toggleable fields from a Zod Schema object for Form V2:
 * - Removes fields ending with 'Toggle'.
 * - Removes fields with values `'-'` or `undefined`.
 * - Removes arrays containing only `'-'`.
 *
 * Use in Form V2 to preprocess form data before submission.
 */
export const transformAndCleanToggleableFieldsSchema = <T extends Record<string, unknown>>(data: T): Partial<T> => {
	return Object.fromEntries(
		Object.entries(data).filter(([key, value]) => {
			if (key.endsWith('Toggle')) return false
			if (value === '-' || value === undefined || value === Infinity) return false

			// Cleans disabled regions field
			if (typeof value === 'object' && value !== null) {
				if ('included' in value || 'excluded' in value) {
					const included = (value as Record<string, unknown>).included

					if (Array.isArray(included) && included.length && included[0] === '-') {
						return false
					}
				}
			}

			if (value && Array.isArray(value) && value.length === 2 && value[0] === '0' && value[1] === '0') {
				return false
			}

			return !(Array.isArray(value) && value?.length && value[0] === '-')
		}),
	) as Partial<T>
}

/**
 * Creates a Zod schema for a non-empty array of strings.
 * Ensures arrays have at least one item and provides a default error message.
 *
 * Use in Form V2 to validate required array fields.
 */
export const nonEmptyArray = (message = 'Required') => z.array(z.string()).nonempty({ message })

export const rangeOnlyMin = z
	.array(z.string().optional())
	.refine(arr => arr.length > 0 && arr[0]?.trim().length, { message: 'Required' })
	.transform(arr => arr.filter(Boolean))

export const nonEmptyRegions = () =>
	RegionsSelectFieldSchema.refine(data => data.included.length > 0 || data.excluded.length > 0, {
		message: 'Please include or exclude at least one region',
	})

export const zString = <T extends ZodType<string | undefined | null> | ZodString>(
	schema?: T,
	message: string = 'Required',
): ZodType<string> => {
	const base = z.string().trim()
	const required = base.min(1, { message })

	if (!schema) {
		return required as ZodType<string>
	}

	if (schema.isOptional()) {
		return schema as ZodType<string, ZodTypeDef, string>
	}

	return required.and(schema)
}

export const transformFieldArrayValues = (options: string[][] | string[]): string[] => {
	return options?.map(item => (Array.isArray(item) ? item.join(',') : item)) || undefined
}

export const RegionsSelectFieldSchema = z.object({
	included: z.array(z.string()),
	excluded: z.array(z.string()),
})

/*
 * @description zNumber - use it in forms, because in server when looking at FormData we are getting values as strings, so this preprocessor
 * will make them as number to avoid failing validations
 * */
export const zNumber = (data: ZodNumber) =>
	z.preprocess((e: unknown) => {
		if (typeof e === 'string') {
			return Number(e)
		}
		return e
	}, data)
