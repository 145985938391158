import { generatePath } from 'react-router-dom'
import { type z } from 'zod'
import { type ContactWaterfallingItemAPISchema } from '#src/api/organise/data-enrichment/workflows/schemas'
import { type LeadResearchAgentItemAPISchema } from '#src/api/organise/lists/schemas'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/verticals/criteria/constants'
import { type CompanySchema } from '#src/routes/company/schema'

const generatePathWithQuery = ({
	path,
	queryArgs,
}: {
	path: string
	queryArgs: string | URLSearchParams | string[][] | Record<string, string>
}) => {
	const query = new URLSearchParams(queryArgs).toString()

	return `${path}?${query}`
}

export const routes = {
	company: {
		index: (params: { companyId: z.infer<typeof CompanySchema>['id'] | null }) => generatePath('/:companyId?', params),
		edit: (params: { companyId: z.infer<typeof CompanySchema>['id'] | null }) =>
			generatePath('/:companyId?/edit', params),
	},
	enable: {
		index: (params: { companyId: string | null }) => generatePath('/:companyId?/enable', params),
		glossary: {
			index: (params: { companyId: string | null }) => generatePath('/:companyId?/enable/glossary', params),
			save: (params: { companyId: string | null; termId: string | null }) =>
				generatePath('/:companyId?/enable/glossary/save/:termId?', params),
			edit: (params: { companyId: string | null; termId: string | null }) =>
				generatePath('/:companyId?/enable/glossary/edit/:termId?', params),
			delete: (params: { companyId: string | null; termId: string }) =>
				generatePath('/:companyId?/enable/glossary/delete/:termId', params),
		},
		copilot: {
			index: (params: { companyId: string | null; conversationId: string | null }) =>
				generatePath('/:companyId?/enable/copilot/:conversationId?', params),
			init: (params: { contactId: string }) => generatePath('/copilot/:contactId', params),
			new: (params: { companyId: string | null; conversationId: string | null }) =>
				generatePath('/:companyId?/enable/copilot/:conversationId?/new', params),
			restart: (params: { companyId: string | null; conversationId: string }) =>
				generatePath('/:companyId?/enable/copilot/:conversationId/restart', params),
			delete: (params: { companyId: string | null; conversationId: string }) =>
				generatePath('/:companyId?/enable/copilot/:conversationId/delete', params),
			settings: (params: { companyId: string | null; conversationId: string }) =>
				generatePath('/:companyId?/enable/copilot/:conversationId/settings', params),
			persona: (params: { companyId: string | null; conversationId: string }) =>
				generatePath('/:companyId?/enable/copilot/:conversationId/persona', params),
			contact: (params: { companyId: string | null; conversationId: string }) =>
				generatePath('/:companyId?/enable/copilot/:conversationId/contact', params),
		},
		roleplay: {
			index: (params: { companyId: string | null }) => generatePath('/:companyId?/enable/roleplay', params),
			session: {
				index: (params: { companyId: string | null; conversationId: string }) =>
					generatePath('/:companyId?/enable/roleplay/session/:conversationId', params),
				attempts: (params: { companyId: string | null; conversationId: string }) =>
					generatePath('/:companyId?/enable/roleplay/session/:conversationId/attempts', params),
				create: (params: { companyId: string | null }) =>
					generatePath('/:companyId?/enable/roleplay/session/new', params),
				join: (params: { companyId: string | null; conversationId: string }) =>
					generatePath('/:companyId?/enable/roleplay/session/:conversationId/join', params),
				end: (params: { companyId: string | null; conversationId: string }) =>
					generatePath('/:companyId?/enable/roleplay/session/:conversationId/end-session', params),
				restart: (params: { companyId: string | null; conversationId: string }) =>
					generatePath('/:companyId?/enable/roleplay/session/:conversationId/restart', params),
				start: (params: { companyId: string | null; conversationId: string }) =>
					generatePath('/:companyId?/enable/roleplay/session/:conversationId/start', params),
				delete: (params: { companyId: string | null; conversationId: string }) =>
					generatePath('/:companyId?/enable/roleplay/session/:conversationId/delete', params),
				review: (params: { companyId: string | null; conversationId: string; attemptId: string | null }) =>
					generatePath('/:companyId?/enable/roleplay/session/:conversationId/review/:attemptId', params),
				// deleteReviewed: (params: { companyId: string | null; conversationId: string }) =>
				// 	generatePath('/:companyId?/enable/roleplay/review/:conversationId/delete', params),
			},
			scenario: {
				index: (params: { companyId: string | null }) => generatePath('/:companyId?/enable/roleplay/scenarios', params),
				item: (params: { companyId: string | null; scenarioId: string }) =>
					generatePath('/:companyId?/enable/roleplay/scenarios/:scenarioId', params),
				attempts: (params: { companyId: string | null; scenarioId: string; sessionId: string }) =>
					generatePath('/:companyId?/enable/roleplay/scenarios/:scenarioId/attempts/:sessionId', params),
				create: (params: { companyId: string | null }) =>
					generatePath('/:companyId?/enable/roleplay/scenarios/new', params),
				edit: (params: { companyId: string | null; scenarioId: string }) =>
					generatePath('/:companyId?/enable/roleplay/scenarios/:scenarioId/edit', params),
				invite: (params: { companyId: string | null; scenarioId: string }) =>
					generatePath('/:companyId?/enable/roleplay/scenarios/:scenarioId/invite', params),
				testSession: (params: { companyId: string | null; scenarioId: string }) =>
					generatePath('/:companyId?/enable/roleplay/scenarios/:scenarioId/test-session', params),
				delete: (params: { companyId: string | null; scenarioId: string }) =>
					generatePath('/:companyId?/enable/roleplay/scenarios/:scenarioId/delete', params),
			},
		},
		personas: {
			index: (params: { companyId: string | null; verticalId: string | null }) =>
				generatePath('/:companyId?/enable/personas/:verticalId?', params),
			details: (params: { companyId: string | null; verticalId: string; personaId: string }) =>
				generatePath('/:companyId?/enable/personas/:verticalId/:personaId', params),
		},
		plays: {
			index: (params: { companyId: string | null }) => generatePath('/:companyId?/enable/plays', params),
			create: (params: { companyId: string | null }) => generatePath('/:companyId?/enable/plays/new', params),
			edit: (params: { companyId: string | null; playId: string }) =>
				generatePath('/:companyId?/enable/plays/:playId/edit', params),
			delete: (params: { companyId: string | null; playId: string }) =>
				generatePath('/:companyId?/enable/plays/:playId/delete', params),
		},
	},
	calibrate: {
		index: (params: { companyId: string | null }) => generatePath('/:companyId?/calibrate', params),
		ecosystem: {
			create: (params: { companyId: string | null }) => generatePath('/:companyId?/calibrate/ecosystem', params),
			createKeyAccounts: (params: { companyId: string | null }) =>
				generatePath('/:companyId?/calibrate/ecosystem/key-accounts', params),
			delete: (params: { companyId: string | null; ecosystemId: string }) =>
				generatePath('/:companyId?/calibrate/ecosystem/:ecosystemId/delete', params),
			edit: (params: { companyId: string | null; ecosystemId: string }) =>
				generatePath('/:companyId?/calibrate/ecosystem/:ecosystemId', params),
			save: (params: { companyId: string | null; ecosystemId: string | null }) =>
				generatePath('/:companyId?/calibrate/ecosystem/:ecosystemId?/save', params),
			sort: (params: { companyId: string | null }) => generatePath('/:companyId?/calibrate/ecosystem/sort', params),
		},
		verticals: {
			index: (params: { companyId: string | null; ecosystemId: string }) =>
				generatePath('/:companyId?/calibrate/verticals/:ecosystemId', params),
			create: (params: { companyId: string | null; ecosystemId: string }) =>
				generatePath('/:companyId?/calibrate/verticals/:ecosystemId/edit', params),
			edit: (params: { companyId: string | null; ecosystemId: string; verticalId: string }) =>
				generatePath('/:companyId?/calibrate/verticals/:ecosystemId/edit/:verticalId', params),
			duplicate: (params: {
				companyId: string | null
				ecosystemId: string
				verticalId: string
				autoFocus?: string | null
			}) =>
				generatePathWithQuery({
					path: generatePath('/:companyId?/calibrate/verticals/:ecosystemId/edit', params),
					queryArgs: {
						duplicate: params.verticalId,
						...(params.autoFocus && { autofocus: params.autoFocus }),
					},
				}),
			copyPersonas: (params: { companyId: string | null; ecosystemId: string }) =>
				generatePath('/:companyId?/calibrate/verticals/:ecosystemId/persona/copy', params),
			copyValidatePersonas: (params: { companyId: string | null; ecosystemId: string }) =>
				generatePath('/:companyId?/calibrate/verticals/:ecosystemId/persona/copy-validate', params),
			batchDeletePersonas: (params: { companyId: string | null; ecosystemId: string }) =>
				generatePath('/:companyId?/calibrate/verticals/:ecosystemId/persona/batch-delete', params),
			update: (params: { companyId: string | null; ecosystemId: string; verticalId: string }) =>
				generatePath('/:companyId?/calibrate/verticals/:ecosystemId/save/:verticalId', params),
			save: (params: { companyId: string | null; ecosystemId: string }) =>
				generatePath('/:companyId?/calibrate/verticals/:ecosystemId/save', params),
			delete: (params: { companyId: string | null; ecosystemId: string; verticalId: string }) =>
				generatePath('/:companyId?/calibrate/verticals/:ecosystemId/delete/:verticalId', params),
		},
		criteria: {
			create: (params: { companyId: string | null; ecosystemId: string }) =>
				generatePath('/:companyId?/calibrate/verticals/:ecosystemId/criteria/list', params),

			list: (params: {
				companyId: string | null
				ecosystemId: string
				criterionType: z.infer<typeof CRITERION_TYPE_ENUM>
			}) => generatePath('/:companyId?/calibrate/verticals/:ecosystemId/criteria/:criterionType/list', params),
			new: (params: {
				companyId: string | null
				ecosystemId: string
				criterionType: z.infer<typeof CRITERION_TYPE_ENUM>
			}) => generatePath('/:companyId?/calibrate/verticals/:ecosystemId/criteria/:criterionType/new', params),
			details: (params: {
				companyId: string | null
				ecosystemId: string
				criterionType: z.infer<typeof CRITERION_TYPE_ENUM>
				criterionId: string
			}) =>
				generatePath(
					'/:companyId?/calibrate/verticals/:ecosystemId/criteria/:criterionType/:criterionId/details',
					params,
				),
			editAnswers: (params: {
				companyId: string | null
				ecosystemId: string
				criterionType: z.infer<typeof CRITERION_TYPE_ENUM>
			}) => generatePath('/:companyId?/calibrate/verticals/:ecosystemId/criteria/:criterionType/answers', params),

			unassign: (params: {
				companyId: string | null
				ecosystemId: string
				criterionType: z.infer<typeof CRITERION_TYPE_ENUM>
				criterionId: string
			}) =>
				generatePath(
					'/:companyId?/calibrate/verticals/:ecosystemId/criteria/:criterionType/:criterionId/unassign',
					params,
				),
			assign: (params: {
				companyId: string | null
				ecosystemId: string
				criterionType: z.infer<typeof CRITERION_TYPE_ENUM>
				criterionId: string
			}) =>
				generatePath(
					'/:companyId?/calibrate/verticals/:ecosystemId/criteria/:criterionType/:criterionId/assign',
					params,
				),
			edit: (params: {
				companyId: string | null
				ecosystemId: string
				criterionType: z.infer<typeof CRITERION_TYPE_ENUM>
				criterionId: string
				returnTo?: 'index'
			}) =>
				generatePath(
					`/:companyId?/calibrate/verticals/:ecosystemId/criteria/:criterionType/:criterionId/edit${
						params.returnTo ? `?to=${params.returnTo}` : ''
					}`,
					params,
				),
		},
		persona: {
			index: (params: {
				companyId: string | null
				ecosystemId: string
				verticalId: string
				personaId: string | null
			}) => generatePath('/:companyId?/calibrate/personas/:ecosystemId/:verticalId/:personaId?', params),
			save: (params: { companyId: string | null; ecosystemId: string; verticalId: string; personaId: string | null }) =>
				generatePath('/:companyId?/calibrate/personas/:ecosystemId/:verticalId/:personaId?/save', params),
			edit: (params: { companyId: string | null; ecosystemId: string; verticalId: string; personaId: string | null }) =>
				generatePath('/:companyId?/calibrate/personas/:ecosystemId/:verticalId/:personaId?/edit', params),
			delete: (params: { companyId: string | null; ecosystemId: string; verticalId: string; personaId: string }) =>
				generatePath('/:companyId?/calibrate/personas/:ecosystemId/:verticalId/:personaId/delete', params),
			setup: (params: { companyId: string | null; ecosystemId: string; verticalId: string; personaId: string }) =>
				generatePath('/:companyId?/calibrate/personas/:ecosystemId/:verticalId/:personaId/setup', params),
			tune: (params: { companyId: string | null; ecosystemId: string; verticalId: string; personaId: string }) =>
				generatePath('/:companyId?/calibrate/personas/:ecosystemId/:verticalId/:personaId/tune', params),
			recalculate: (params: {
				companyId: string | null
				ecosystemId: string
				verticalId: string
				personaId: string | null
			}) => generatePath('/:companyId?/calibrate/personas/:ecosystemId/:verticalId/:personaId?/recalculate', params),
			duplicate: (params: {
				companyId: string | null
				ecosystemId: string
				verticalId: string
				personaId: string | null
				duplicate: string
			}) =>
				generatePathWithQuery({
					path: generatePath('/:companyId?/calibrate/personas/:ecosystemId/:verticalId/:personaId?/edit', params),
					queryArgs: {
						duplicate: params.duplicate,
					},
				}),
			coach: {
				index: (params: { companyId: string | null; ecosystemId: string; verticalId: string; personaId: string }) =>
					generatePath('/:companyId?/calibrate/personas/:ecosystemId/:verticalId/:personaId/coach', params),
				recalculate: (params: {
					companyId: string | null
					ecosystemId: string
					verticalId: string
					personaId: string
					salesCoachId: string
				}) =>
					generatePath(
						'/:companyId?/calibrate/personas/:ecosystemId/:verticalId/:personaId/coach/recalculate/:salesCoachId',
						params,
					),
			},
		},
		valueProposition: {
			index: (params: { companyId: string | null }) => generatePath('/:companyId?/calibrate/value-proposition', params),
			published: (params: { companyId: string | null }) =>
				generatePath('/:companyId?/calibrate/value-proposition/published', params),
			publish: (params: { companyId: string | null }) =>
				generatePath('/:companyId?/calibrate/value-proposition/publish', params),
			new: (params: { companyId: string | null }) =>
				generatePath('/:companyId?/calibrate/value-proposition/new', params),
			publishWarn: (params: { companyId: string | null }) =>
				generatePath('/:companyId?/calibrate/value-proposition/publish-warn', params),
			delete: (params: { companyId: string | null; pointId: string }) =>
				generatePath('/:companyId?/calibrate/value-proposition/:pointId/delete', params),
			update: (params: { companyId: string | null; pointId: string }) =>
				generatePath('/:companyId?/calibrate/value-proposition/:pointId/save', params),
		},
	},
	prioritize: {
		index: (params: { companyId: string | null }) => generatePath('/:companyId?/prioritize', params),
		filters: (params: { companyId: string | null }) => generatePath('/:companyId?/prioritize/filters', params),
		signal: {
			index: (params: { companyId: string | null; signalId: string }) =>
				generatePath('/:companyId?/prioritize/details/:signalId', params),
			assign: (params: { companyId: string | null; signalId: string }) =>
				generatePath('/:companyId?/prioritize/details/:signalId/assign', params),
			save: (params: { companyId: string | null; signalId: string }) =>
				generatePath('/:companyId?/prioritize/details/:signalId/save', params),
			example: (params: { companyId: string | null; signalId: string; personaId: string }) =>
				generatePath('/:companyId?/prioritize/details/:signalId/example/:personaId', params),
		},
	},
	organize: {
		index: (params: { companyId: string | null }) => generatePath('/:companyId?/organize', params),
		contactWaterfalling: {
			index: (params: { companyId: z.infer<typeof CompanySchema.shape.id> | null }) =>
				generatePath('/:companyId?/organize/contact-waterfalling', params),
			create: (params: { companyId: z.infer<typeof CompanySchema.shape.id> | null }) =>
				generatePath('/:companyId?/organize/contact-waterfalling/new', params),
			view: (params: {
				companyId: z.infer<typeof CompanySchema.shape.id> | null
				jobId: z.infer<typeof ContactWaterfallingItemAPISchema.shape.id>
			}) => generatePath('/:companyId?/organize/contact-waterfalling/:jobId', params),
			stop: (params: {
				companyId: z.infer<typeof CompanySchema.shape.id> | null
				jobId: z.infer<typeof ContactWaterfallingItemAPISchema.shape.id>
			}) => generatePath('/:companyId?/organize/contact-waterfalling/:jobId/stop', params),
			download: (params: {
				companyId: z.infer<typeof CompanySchema.shape.id> | null
				jobId: z.infer<typeof ContactWaterfallingItemAPISchema.shape.id>
			}) => generatePath('/:companyId?/organize/contact-waterfalling/:jobId/download', params),
		},
	},
	settings: {
		index: (params: { companyId: string | null }) => generatePath('/:companyId?/settings', params),
		team: {
			index: (params: { companyId: string | null }) => generatePath('/:companyId?/settings/team', params),
			invite: (params: { companyId: string | null }) => generatePath('/:companyId?/settings/team/invite', params),
		},
		copilot: {
			index: (params: { companyId: string | null }) => generatePath('/:companyId?/settings/copilot', params),
			defaultCommands: {
				edit: (params: { companyId: string | null; commandId: string }) =>
					generatePath('/:companyId?/settings/copilot/default-commands/:commandId', params),
			},
			generalSettings: {
				edit: (params: { companyId: string | null }) =>
					generatePath('/:companyId?/settings/copilot/general-settings', params),
			},
		},
		organize: {
			index: (params: { companyId: string | null }) => generatePath('/:companyId?/settings/organize', params),
			lra: {
				edit: (params: { companyId: string | null }) => generatePath('/:companyId?/settings/organize/lra', params),
			},
			waterfalling: {
				edit: (params: { companyId: string | null }) =>
					generatePath('/:companyId?/settings/organize/contact-waterfalling', params),
			},
		},
	},
	admin: {
		index: '/admin',
		company: {
			create: '/admin/company/new',
			edit: (params: { companyId: string }) => generatePath('/admin/company/:companyId/edit', params),
			editDetails: (params: { companyId: string }) => generatePath('/admin/company/:companyId/editDetails', params),
			delete: (params: { companyId: string }) => generatePath('/admin/company/:companyId/delete', params),
		},
	},
	developers: {
		playground: () => generatePath('/developers/playground'),
	},
	leadResearchAgent: {
		index: (params: { companyId: z.infer<typeof CompanySchema>['id'] | null }) =>
			generatePath('/:companyId?/organize/lra', params),
		create: (params: { companyId: z.infer<typeof CompanySchema>['id'] | null }) =>
			generatePath('/:companyId?/organize/lra/new', params),
		view: (params: {
			companyId: z.infer<typeof CompanySchema>['id'] | null
			jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']
		}) => generatePath('/:companyId?/organize/lra/:jobId', params),
		stop: (params: {
			companyId: z.infer<typeof CompanySchema>['id'] | null
			jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']
		}) => generatePath('/:companyId?/organize/lra/:jobId/stop', params),
		download: (params: {
			companyId: z.infer<typeof CompanySchema>['id'] | null
			jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']
		}) => generatePath('/:companyId?/organize/lra/:jobId/download', params),
		downloadTemplate: (params: { companyId: z.infer<typeof CompanySchema>['id'] | null }) =>
			generatePath('/:companyId?/organize/lra/download-template', params),
	},
	report: {
		index: (params: { companyId: string | null }) => generatePath('/:companyId?/report', params),
	},
}
