import { type RouteObject } from 'react-router-dom'
import SalesCoach, {
	action as salesCoachAction,
	loader as salesCoachLoader,
} from '#src/routes/calibrate/personas/coach'
import { action as recalculateSalesCoachAction } from '#src/routes/calibrate/personas/coach/recalculate'
import { action as deletePersonaAction } from '#src/routes/calibrate/personas/delete'
import PersonaEdit, { loader as personaEditLoader } from '#src/routes/calibrate/personas/edit'
import { action as recalculatePersonasAction } from '#src/routes/calibrate/personas/recalculate'
import { action as savePersonaAction } from '#src/routes/calibrate/personas/save'
import PersonaSetup, { loader as personaSetupLoader } from '#src/routes/calibrate/personas/setup'
import PersonaTune, { loader as personaTuneLoader } from '#src/routes/calibrate/personas/tune'
import { autofocusVerticalMutation } from '#src/routes/calibrate/verticals/autofocus'
import { routes } from '#src/utils/routes'
import { loader as itemLoader, type LoaderRes as PersonasItemLoaderRes } from './loaders/item'
import { loader as listLoader, type LoaderRes as PersonasListLoaderRes } from './loaders/list'
import { loader as personasRootLoader, type LoaderRes as PersonasRootLoaderRes } from './loaders/root'
import { View as ItemView } from './views/item'
import { View as ListView } from './views/list'

const calibratePersonasRouterChildren = [
	{
		path: 'edit',
		element: <PersonaEdit />,
		loader: personaEditLoader,
	},
	{
		path: 'save',
		action: savePersonaAction,
	},
	{
		path: 'recalculate',
		action: recalculatePersonasAction,
	},
] satisfies RouteObject[]

const calibratePersonasRouter = {
	path: 'personas/:ecosystemId/:verticalId',
	loader: personasRootLoader,
	handle: {
		layout: {
			noPadding: false,
		},
		crumb: (data: PersonasRootLoaderRes) => [
			{
				to: routes.calibrate.verticals.index({
					companyId: data.handle.companyId,
					ecosystemId: data.handle.ecosystemId,
				}),
				label: data.ecosystem.name || 'Ecosystem',
				icon: 'ibm-cloud-pak',
			},
			{
				to: routes.calibrate.persona.index({
					companyId: data.handle.companyId,
					ecosystemId: data.handle.ecosystemId,
					verticalId: data.handle.verticalId,
					personaId: null,
				}),
				label: data.vertical.name,
				icon: 'category',
				onClick: () => autofocusVerticalMutation(data.handle.verticalId),
			},
		],
	},
	children: [
		{
			path: '',
			element: <ListView />,
			loader: listLoader,
			handle: {
				action: (data: PersonasListLoaderRes) =>
					data.handle.readOnlySession
						? []
						: [
								{
									to: routes.calibrate.persona.edit({
										companyId: data.handle.companyId,
										ecosystemId: data.handle.ecosystemId,
										verticalId: data.handle.verticalId,
										personaId: null,
									}),
									label: 'Add persona',
									icon: 'user-follow',
								},
							],
			},
			children: calibratePersonasRouterChildren,
		},
		{
			path: ':personaId',
			element: <ItemView />,
			loader: itemLoader,
			handle: {
				crumb: (data: PersonasItemLoaderRes) => [
					{
						to: routes.calibrate.persona.index({
							companyId: data.handle.companyId,
							ecosystemId: data.handle.ecosystemId,
							verticalId: data.handle.verticalId,
							personaId: data.handle.personaId,
						}),
						label: data.persona?.type ?? 'Persona',
						icon: 'user',
					},
				],
				action: (data: PersonasItemLoaderRes) =>
					data.handle.readOnlySession
						? []
						: [
								{
									to: routes.calibrate.persona.edit({
										companyId: data.handle.companyId,
										ecosystemId: data.handle.ecosystemId,
										verticalId: data.handle.verticalId,
										personaId: data.handle.personaId,
									}),
									label: 'Add persona',
									icon: 'user-follow',
								},
							],
			},
			children: [
				...calibratePersonasRouterChildren,
				{
					path: 'delete',
					action: deletePersonaAction,
				},
				{
					path: 'setup',
					element: <PersonaSetup />,
					loader: personaSetupLoader,
				},
				{
					path: 'tune',
					element: <PersonaTune />,
					loader: personaTuneLoader,
				},
				{
					path: 'coach',
					element: <SalesCoach />,
					loader: salesCoachLoader,
					action: salesCoachAction,
					children: [
						{
							path: 'recalculate/:salescoachId',
							action: recalculateSalesCoachAction,
						},
					],
				},
			],
		},
	],
} satisfies RouteObject

export default calibratePersonasRouter
