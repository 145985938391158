import { z } from 'zod'
import { CriterionAnswerValueSchema, CriterionQuestionAPISchema } from '#src/routes/calibrate/verticals/criteria/schema'
import { RegionsSelectFieldSchema, zString } from '#src/utils/validation'
import { EcosystemAPISchema } from '../ecosystem/schema'

export const VerticalCriterionAPISchema = z.object({
	criterionId: z.number(),
	question: z.string().nullable(),
	value: z.string().or(z.number()).or(z.array(z.string())).or(RegionsSelectFieldSchema).nullable(),
	type: CriterionQuestionAPISchema.shape.type,
	unit: CriterionQuestionAPISchema.shape.unit,
	logic: CriterionQuestionAPISchema.shape.logic,
	isEnrichment: CriterionQuestionAPISchema.shape.isEnrichment,
})

export const VerticalCriteriaAPISchema = z.array(VerticalCriterionAPISchema)

export const VerticalAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	ecosystem: EcosystemAPISchema.pick({ id: true, name: true }).required(),
	answers: VerticalCriteriaAPISchema,
	status: z.string(),
	priority: z.string(),

	//TODO: Make not optional after migration
	domain: z.string().nullable().optional(),
	bizDescription: z.string().nullable().optional(),
	bizDescriptionOverride: z.string().nullable().optional(),
	bizModelLen: z.number().nullable(),
	prompt: z.object({
		status: z.string().nullable(),
	}),
	listBuildingNotes: z.string().nullable().optional(),
})

export const VerticalListAPISchema = z.array(VerticalAPISchema)

export const VerticalFormSchema = z.object({
	name: zString(),
	status: zString(),
	priority: zString(),
	tamEstimateMin: zString(z.string().optional()),
	tamEstimateMax: zString(z.string().optional()),
	bizModelLen: z.number().optional(),
	domain: z.string().optional(),
	bizDescriptionOverride: zString(z.string().nullable().optional()),
	exampleCompanies: zString(z.string().optional()),
	listBuildingNotes: zString(z.string().nullable().optional()),
	answers: z
		.array(
			z.object({
				criterionId: z.string(),
				value: CriterionAnswerValueSchema,
			}),
		)
		.optional(),
})

export const VerticalSavePayloadSchema = VerticalFormSchema.extend({ ecosystemId: z.string() })

export type CriterionFieldId = `${string}::${string}`
