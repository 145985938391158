import { type LoaderFunctionArgs } from 'react-router-dom'
import { companyPersonaQueries } from '#src/api/icp/company/persona/queries'
import { playsQueries } from '#src/api/icp/company/plays/queries'
import { client } from '#src/main'
import { personaQuery } from '#src/routes/calibrate/personas/queries'
import { verticalQuery } from '#src/routes/calibrate/verticals/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['verticalId', 'personaId'])

	const [vertical, persona, plays, intents] = await Promise.all([
		client.fetchQuery(verticalQuery(company.id, params.verticalId)),
		client.fetchQuery(personaQuery(company.id, params.personaId)),
		client.fetchQuery(playsQueries.list(company.id)),
		client.fetchQuery(companyPersonaQueries.signals(company.id, Number(params.personaId))),
	])

	return {
		persona,
		vertical,
		plays: plays.filter(
			item =>
				persona.expertise &&
				persona.type &&
				item.expertise.includes(persona.expertise) &&
				item.personaTypes.includes(persona.type),
		),
		intents,
	}
}
