import { type LoaderFunctionArgs } from 'react-router-dom'
import { type z } from 'zod'
import { statsQueries } from '#src/api/icp/company/stats/queries'
import { valuePropositionQueries } from '#src/api/icp/company/value-proposition/queries'
import { type ValuePropositionListQuerySchema } from '#src/api/icp/company/value-proposition/schemas'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(status?: z.infer<typeof ValuePropositionListQuerySchema.shape.status>) =>
	async ({ params }: LoaderFunctionArgs) => {
		const { company } = await getCompany(params)
		const [statistics, ecosystems, valueProposition] = await Promise.all([
			client.refetchQueries(statsQueries.dashboard(company.id)),
			client.fetchQuery(ecosystemsQuery(company.id)),
			client.fetchQuery(valuePropositionQueries.list(company.id, { status })),
		])

		return {
			data: {
				ecosystems,
				statistics,
				valueProposition,
			},
		}
	}
