import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu'
import { Icon } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'

type Props = {
	label: string
	value: undefined | string | string[]
	onChange: (value: string[]) => void
	options: {
		value: string
		label: string
	}[]
	multiple?: boolean
}

const FilterSelect = (props: Props) => {
	const { label, value, onChange, options, multiple = true } = props
	const valueFormatted = Array.isArray(value) ? value : value ? [value] : []

	return (
		<DropdownPrimitive.Root>
			<DropdownPrimitive.Trigger
				className={cn(
					'group flex items-center gap-1 rounded border bg-transparent py-2 pl-3 pr-2 text-body-md text-button-sm outline-none transition-colors radix-state-open:border-brand-1-bd',
					valueFormatted.length ? 'text-brand-1-fg' : 'text-neutral-1-fg',
					valueFormatted.length ? 'border-brand-1-bd' : 'border-neutral-1-bd',
				)}
			>
				{label}
				{!!valueFormatted.length && (
					<span className="inline-flex h-4 w-4 items-center justify-center rounded-full bg-brand-3-bg text-label-sm text-white animate-in fade-in">
						{valueFormatted.length}
					</span>
				)}
				<Icon name="carret-down" size="sm" className="group-radix-state-open:rotate-180" />
			</DropdownPrimitive.Trigger>

			<DropdownPrimitive.Content
				side="bottom"
				align="start"
				sideOffset={4}
				className={cn(
					'z-50 flex max-h-[20rem] min-w-[12rem] flex-col overflow-hidden overflow-y-auto rounded border border-neutral-1-bd bg-neutral-1-bg p-2 shadow',
				)}
			>
				<DropdownPrimitive.Item
					disabled={!valueFormatted.length}
					className={cn(
						'group flex items-center gap-2 rounded-sm px-2 py-1.5 text-button-sm font-normal outline-none transition-colors',
						'cursor-pointer text-link hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover radix-disabled:cursor-default radix-disabled:text-neutral-1-fg-disabled radix-disabled:hover:bg-transparent',
					)}
					onSelect={() => onChange([])}
				>
					Clear {multiple ? 'all' : 'filter'}
				</DropdownPrimitive.Item>
				{options.map(option => (
					<DropdownPrimitive.CheckboxItem
						key={option.value}
						className={cn(
							'group flex cursor-pointer items-center gap-2 rounded-sm px-2 py-1.5 text-body-md font-normal text-neutral-2-fg outline-none transition-colors hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover',
						)}
						onSelect={e => e.preventDefault()}
						checked={valueFormatted.includes(option.value) ?? false}
						onCheckedChange={checked => {
							if (multiple) {
								onChange(
									checked ? [...valueFormatted, option.value] : (valueFormatted.filter(i => i !== option.value) ?? []),
								)
							} else {
								onChange(checked ? [option.value] : [])
							}
						}}
					>
						<Icon
							name="checkbox-unchecked"
							size="sm"
							className="inline-flex animate-in fade-in group-radix-state-checked:hidden group-radix-state-checked:animate-out group-radix-state-checked:fade-out"
						/>
						<Icon
							name="checkbox-checked-filled"
							size="sm"
							className="hidden text-brand-1-fg animate-out fade-out group-radix-state-checked:inline-flex group-radix-state-checked:animate-in group-radix-state-checked:fade-in"
						/>
						{option.label}
					</DropdownPrimitive.CheckboxItem>
				))}
			</DropdownPrimitive.Content>
		</DropdownPrimitive.Root>
	)
}

export default FilterSelect
