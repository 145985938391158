import { type LoaderFunctionArgs } from 'react-router-dom'
import { companyChatRoleplayScenarioQueries } from '#src/api/icp/company/chat/roleplay/scenario/queries'
import { client } from '#src/main'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	const qPage = parseInt(new URL(request.url).searchParams.get('page') ?? '1')
	const page = Number.isNaN(qPage) ? 1 : qPage

	return {
		data: {
			page,
		},
		scenarios: await client.fetchQuery(companyChatRoleplayScenarioQueries.list(company.id, page)),
	}
}
