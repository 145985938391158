import { DATA_ENRICHMENT_WORKFLOW_STATUS } from '#src/api/organise/data-enrichment/workflows/schemas'

export const JobStatusName: Record<DATA_ENRICHMENT_WORKFLOW_STATUS, string> = {
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Pending]: 'In queue',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.InProgress]: 'In progress...',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Completed]: 'Completed',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Cancelled]: 'Stopped',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Error]: 'Error',
} as const

export enum FORM_STEPS {
	File = 'file',
	AdditionalInfo = 'additional_info',
}
