import { type ReactNode } from 'react'

type Props = {
	children: ReactNode
}

const LayoutContentHack = (props: Props) => {
	const { children } = props
	return <div className="-mx-[var(--layout-px)]">{children}</div>
}

export default LayoutContentHack
