import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { useEffect, useState } from 'react'
import { useActionData } from 'react-router-dom'
import { type CreateScenarioFormSchemaType } from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import { Wizard, type WizardStep } from '#src/components/wizard'
import {
	COMMUNICATION_STYLE_OPTIONS,
	DECISION_MAKING_PROCESS_OPTIONS,
	EMOTIONAL_STATE_OPTIONS,
	PERSONALITY_TRAIT_OPTIONS,
} from '#src/constants/options'
import useCompany from '#src/hooks/useCompany'
import { useScenarioTemplate } from '#src/hooks/useScenarioTemplate'
import { type ActionRes } from '#src/routes/enable/roleplay-scenarios/actions/new'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getActiveNewScenarioWizardSchema } from '../schema'
import {
	EvaluationForm,
	InstructionsForm,
	PersonalityForm,
	ScenarioForm,
	PersonaMappingForm,
} from './components/NewRoleplayScenarioWizard'

const stepsIntlVal: WizardStep<CreateScenarioFormSchemaType>[] = [
	{
		label: 'Scenario',
		value: 'scenario',
		isFinished: false,
		component: fields => (
			<div className="flex flex-col gap-4">
				<ScenarioForm fields={fields} />
			</div>
		),
	},
	{
		label: 'Persona Mapping',
		value: 'persona-mapping',
		isFinished: false,
		component: fields => (
			<div className="flex flex-col gap-4">
				<PersonaMappingForm fields={fields} />
			</div>
		),
	},
	{
		label: 'Personality',
		value: 'personality',
		isFinished: false,
		component: fields => (
			<div className="flex flex-col gap-4">
				<PersonalityForm fields={fields} />
			</div>
		),
	},
	{
		label: 'Evaluation',
		value: 'evaluation',
		isFinished: false,
		component: fields => (
			<div className="flex flex-col gap-4">
				<EvaluationForm fields={fields} />
			</div>
		),
	},
	{
		label: 'Instructions',
		value: 'instructions',
		isFinished: false,
		component: fields => (
			<div className="flex flex-col gap-4">
				<InstructionsForm fields={fields} />
			</div>
		),
	},
]

const View = () => {
	const { companyId } = useCompany()
	const { scenarioTemplates } = useScenarioTemplate()
	const actionData = useActionData() as ActionRes
	const isPending = useIsPending()

	const [intlVal, setIntlVal] = useState<CreateScenarioFormSchemaType>({
		name: '',
		templateId: scenarioTemplates[0].id,
		scenario: scenarioTemplates[0].scenario,
		ecosystemId: 0,
		verticalId: 0,
		personaId: 0,
		evaluation: scenarioTemplates[0].evaluation ?? '',
		instructions: '',
		linkedinUrl: '',
		contactId: '',
		personality: {
			communicationStyle: COMMUNICATION_STYLE_OPTIONS[0].value,
			decisionMakingProcess: DECISION_MAKING_PROCESS_OPTIONS[0].value,
			emotionalState: EMOTIONAL_STATE_OPTIONS[0].value,
			personalityTrait: PERSONALITY_TRAIT_OPTIONS[0].value,
			voice: '',
		},
	})
	const [activeStep, setActiveStep] = useState(stepsIntlVal[0].value)
	const activeSchema = getActiveNewScenarioWizardSchema(activeStep)
	const [steps, setSteps] = useState(stepsIntlVal)

	const handleNextStep = () => {
		setSteps(prevState => prevState.map(item => (item.value === activeStep ? { ...item, isFinished: true } : item)))

		const currentStepIndex = steps.findIndex(item => item.value === activeStep)

		if (currentStepIndex === -1) {
			setActiveStep(steps[0].value)

			return
		}

		if (currentStepIndex < steps.length - 1) {
			setActiveStep(steps[currentStepIndex + 1].value)
		}
	}

	useEffect(() => {
		if (actionData && 'result' in actionData && actionData.ok) {
			setIntlVal(prev => ({
				...prev,
				...actionData.result.initialValue,
				...(activeStep === 'persona-mapping' &&
					'contactId' in actionData.result && {
						contactId: actionData.result.contactId,
					}),
			}))

			handleNextStep()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actionData])

	const handleValidate = ({ formData }: { formData: FormData }) => parseWithZod(formData, { schema: activeSchema })

	const formOptions = {
		id: `${activeStep}-roleplay-scenario`,
		constraint: getZodConstraint(activeSchema),
		defaultValue: intlVal,
		onValidate: handleValidate,
		shouldValidate: 'onSubmit' as const,
		shouldRevalidate: 'onInput' as const,
		lastResult: actionData && 'result' in actionData ? actionData.result : null,
	}

	return (
		<Wizard
			steps={steps}
			title="Roleplay Scenario Setup"
			exitRoute={routes.enable.roleplay.scenario.index({ companyId })}
			exitTooltipText="Exit setup"
			setActiveStep={setActiveStep}
			activeStep={activeStep}
			formOptions={formOptions}
			disabled={isPending}
		/>
	)
}

export default View
