import { useLoaderData } from 'react-router-dom'
import { EmptyStateCard } from '#src/components'
import { PersonaData } from '#src/components/persona'
import { PageLayout } from '#src/components/ui/PageLayout'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { type LoaderRes } from '#src/routes/calibrate/personas/loaders/list'
import { routes } from '#src/utils/routes'

export const View = () => {
	const {
		vertical,
		personas,
		handle: { verticalId, ecosystemId },
	} = useLoaderData() as LoaderRes
	const { readOnlySession } = useAuth()
	const { companyId } = useCompany()
	return (
		<PageLayout
			title={vertical.name}
			{...(!readOnlySession && {
				actions: [
					{
						title: 'Add persona',
						icon: 'add',
						to: routes.calibrate.persona.edit({
							companyId,
							ecosystemId,
							verticalId,
							personaId: null,
						}),
					},
				],
			})}
		>
			{personas.length ? (
				<section className="grid grid-cols-3 gap-4">
					{personas.map(persona => (
						<PersonaData
							key={persona.id}
							id={persona.id}
							type={persona.type}
							status={persona.status}
							priority={persona.priority}
							expertise={persona.expertise ?? ''}
							ecosystemId={persona.ecosystemId}
							verticalId={String(persona.verticalId)}
						/>
					))}
				</section>
			) : (
				<EmptyStateCard
					icon="user-avatar"
					title={
						<>
							You don&apos;t have any personas in{' '}
							<span className="font-semibold">{vertical?.name ?? 'this vertical'}</span>
						</>
					}
					actions={
						!readOnlySession
							? [
									{
										title: 'Add persona',
										to: routes.calibrate.persona.edit({
											companyId,
											ecosystemId,
											verticalId,
											personaId: null,
										}),
									},
								]
							: []
					}
				/>
			)}
		</PageLayout>
	)
}
