import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { type z } from 'zod'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/verticals/criteria/constants'
import { updateAllVerticalsAnswers } from '#src/routes/calibrate/verticals/criteria/mutations'
import { criteriaKeys } from '#src/routes/calibrate/verticals/criteria/queries'
import { CriterionAllAssignedVerticalsAnswersPayload } from '#src/routes/calibrate/verticals/criteria/schema'
import {
	generateEditAnswersContext,
	transformAnswersSubmissionToPayload,
} from '#src/routes/calibrate/verticals/criteria/utils'
import { verticalKeys } from '#src/routes/calibrate/verticals/queries'
import { parseFormData } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { transformAndCleanToggleableFieldsSchema } from '#src/utils/validation'

export type ActionResType = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(type: z.infer<typeof CRITERION_TYPE_ENUM>) =>
	async ({ params, request }: ActionFunctionArgs) => {
		validateRouteParams(params, ['ecosystemId'])
		const { companyId, company } = await getCompany(params)
		const formData = await request.formData()
		const { schema } = await generateEditAnswersContext(company.id, params.ecosystemId, type)
		const submission = parseFormData(formData, schema.transform(transformAndCleanToggleableFieldsSchema))
		const payload = transformAnswersSubmissionToPayload(submission.value, type, params.ecosystemId)
		const parsed = CriterionAllAssignedVerticalsAnswersPayload.parse(payload)

		try {
			await updateAllVerticalsAnswers(company.id, params.ecosystemId, parsed)
			await Promise.all([
				client.invalidateQueries({ queryKey: criteriaKeys.all }),
				client.invalidateQueries({ queryKey: ecosystemKeys.all }),
				client.invalidateQueries({ queryKey: verticalKeys.all }),
			])

			showToast({
				message: 'Criteria successfully saved',
				type: 'success',
				duration: 2000,
			})
		} catch (err) {
			captureException(err)
			showToast({
				message: 'Unexpected error while updating criteria answers',
				type: 'error',
			})
		}

		return redirect(
			routes.calibrate.verticals.index({
				companyId,
				ecosystemId: params.ecosystemId,
			}),
		)
	}
