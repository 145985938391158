import { type LoaderFunctionArgs } from 'react-router-dom'
import { companyChatRoleplaySessionQueries } from '#src/api/icp/company/chat/roleplay/session/queries'
import { SessionsFiltersAPISchema, SessionsListItemAPISchema } from '#src/api/icp/company/chat/roleplay/session/schemas'
import { client } from '#src/main'
import { getValidSortKeys, paramsToObjectSafe } from '#src/utils/requests'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const page = parseInt(new URL(request.url).searchParams.get('page') ?? '1')
	const sort = getValidSortKeys(new URL(request.url).searchParams.getAll('sort'), SessionsListItemAPISchema)
	const filtersSelected = paramsToObjectSafe(new URL(request.url).searchParams, SessionsFiltersAPISchema)

	const values = {
		page: Number.isNaN(page) ? 1 : page,
		sort,
	}

	const [sessions, filters] = await Promise.all([
		client.fetchQuery(companyChatRoleplaySessionQueries.list(company.id, values.page, values.sort, filtersSelected)),
		client.fetchQuery(companyChatRoleplaySessionQueries.listFilters(company.id)),
	])

	return {
		values,
		filters: {
			values: filters,
			selected: filtersSelected,
		},
		sessions,
	}
}
