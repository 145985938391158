import { type LoaderFunctionArgs, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { Dialog } from '#src/components/ui/dialog'
import { client } from '#src/main'
import { ecosystemQuery, ecosystemsTemplatesQuery } from '#src/routes/calibrate/ecosystem/queries'
import { criteriaAssignedQuery } from '#src/routes/calibrate/verticals/criteria/queries'
import { getCompany } from '#src/utils/server/company'
import { EcosystemForm } from './save'

export type EcosystemLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	return {
		ecosystem: params.ecosystemId ? await client.fetchQuery(ecosystemQuery(company.id, params.ecosystemId)) : null,
		criteria: params.ecosystemId
			? await client.fetchQuery(criteriaAssignedQuery(company.id, params.ecosystemId))
			: null,
		ecosystemTemplates: await client.fetchQuery(ecosystemsTemplatesQuery(company.id)),
	}
}

export default function Ecosystem({ isKeyAccounts }: { isKeyAccounts?: boolean }) {
	const data = useRouteLoaderData('ecosystem') as EcosystemLoaderResponse
	const navigate = useNavigate()
	const dialogHeading = isKeyAccounts || data?.ecosystem?.verticalAsCompany ? 'Key Accounts' : 'Ecosystem'

	return (
		<Dialog
			dialogHeading={data.ecosystem ? `${dialogHeading} Details` : `Create ${dialogHeading}`}
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && navigate(-1)}
			footer={null}
		>
			<EcosystemForm
				ecosystem={data.ecosystem}
				ecosystemTemplates={data.ecosystemTemplates}
				isKeyAccounts={isKeyAccounts}
			/>
		</Dialog>
	)
}
