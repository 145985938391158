import { type LoaderFunctionArgs } from 'react-router-dom'
import { playsQueries } from '#src/api/icp/company/plays/queries'
import { client } from '#src/main'
import { personasExpertiseQuery } from '#src/routes/enable/personas/queries'
import { PERSONA_TYPES } from '#src/utils/enumerations'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	return {
		data: {
			personaTypes: Object.values(PERSONA_TYPES),
			expertises: await client.fetchQuery(personasExpertiseQuery(company.id, {})),
			tags: [...new Set((await client.fetchQuery(playsQueries.list(company.id))).map(play => play.tags).flat(1))],
		},
	}
}
