import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { criteriaKeys } from '#src/routes/calibrate/verticals/criteria/queries'
import { VerticalSavePayloadSchema } from '#src/routes/calibrate/verticals/schema'
import {
	generateSaveVerticalContext,
	transformVerticalSaveSubmissionToPayload,
} from '#src/routes/calibrate/verticals/utils'
import { parseFormData } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { transformAndCleanToggleableFieldsSchema } from '#src/utils/validation'
import { createVertical, updateVertical } from './mutations'
import { verticalKeys } from './queries'

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['ecosystemId'])
	const formData = await request.formData()
	const intent = formData.get('intent')
	try {
		const { schema } = await generateSaveVerticalContext(company.id, params.ecosystemId)
		const submission = parseFormData(formData, schema.transform(transformAndCleanToggleableFieldsSchema))
		const payload = transformVerticalSaveSubmissionToPayload(submission.value, params.ecosystemId)
		const parsed = VerticalSavePayloadSchema.parse(payload)

		if (intent === 'vertical') {
			if (!params.verticalId) {
				await createVertical(company.id, Number(params.ecosystemId), parsed)
			} else {
				await updateVertical(company.id, Number(params.verticalId), parsed)
			}

			await Promise.all([
				client.invalidateQueries({
					queryKey: ecosystemKeys.list(company.id),
				}),
				client.invalidateQueries({
					queryKey: verticalKeys.all,
				}),
				client.invalidateQueries({
					queryKey: criteriaKeys.all,
				}),
			])

			return redirect(
				routes.calibrate.verticals.index({
					companyId,
					ecosystemId: params.ecosystemId,
				}),
			)
		}
	} catch (e) {
		showToast({
			message: 'Failed to save vertical information.',
			type: 'error',
		})
		return { ok: false }
	}
}
