import { type LoaderFunctionArgs } from 'react-router-dom'
import { type z } from 'zod'
import { client } from '#src/main'
import { personaKeys } from '#src/routes/calibrate/personas/queries'
import { CopyValidatePersonaAPIResSchema } from '#src/routes/calibrate/personas/schema'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type CopyPersonasLoaderLoaderType = Awaited<ReturnType<typeof copyPersonasLoader>>

export const copyPersonasLoader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId'])
	const { company } = await getCompany(params)

	const res = client.getQueryData<{
		data: z.infer<typeof CopyValidatePersonaAPIResSchema>
		personaIdsArray: string[]
	}>(personaKeys.copyValidate(company.id))

	const emptyReturn = {
		ecosystemsWithValidatedPersonas: [],
		selectedPersonasIds: [],
		ecosystemId: params.ecosystemId,
	}

	try {
		if (!res?.data) {
			return emptyReturn
		}

		const { data, personaIdsArray } = res

		const parsedData = CopyValidatePersonaAPIResSchema.parse(data)

		return {
			ecosystemsWithValidatedPersonas: parsedData,
			selectedPersonasIds: personaIdsArray,
			ecosystemId: params.ecosystemId,
		}
	} catch (error) {
		return emptyReturn
	}
}
