import { type z } from 'zod'
import { PersonaData } from '#src/components/persona'
import { Checkbox } from '#src/components/ui/checkbox'
import { type EcosystemAPIPersonaSchema } from '#src/routes/calibrate/ecosystem/schema'
import { usePersonasCheckboxGroup } from '#src/routes/calibrate/verticals/context/PersonasCheckboxGroupContext'

type PersonaListProps = {
	personas: z.infer<typeof EcosystemAPIPersonaSchema>[]
	ecosystemId: string
	verticalId: string
}

export const PersonaList = ({ personas, ecosystemId, verticalId }: PersonaListProps) => {
	const { checkedItems, setCheckedItems } = usePersonasCheckboxGroup()

	const handleCheckedChange = (personaId: string, checked: boolean) => {
		setCheckedItems(prevState => ({
			...prevState,
			[verticalId]: {
				...prevState[verticalId],
				[personaId]: {
					...prevState[verticalId][personaId],
					checked,
				},
			},
		}))
	}

	return (
		<section className="flex w-full flex-col items-start justify-start gap-2">
			{personas.map(persona => {
				const personaIdStr = String(persona.id)
				const isChecked = checkedItems[verticalId]?.[personaIdStr]?.checked || false

				return (
					<div key={persona.id} className="flex items-center gap-2">
						<Checkbox
							checked={isChecked}
							onCheckedChange={(checked: boolean) => handleCheckedChange(personaIdStr, checked)}
						/>
						<PersonaData
							id={persona.id}
							type={persona.type}
							status={persona.status}
							priority={persona.priority}
							expertise={persona.expertise}
							ecosystemId={Number(ecosystemId)}
							verticalId={verticalId}
						/>
					</div>
				)
			})}
		</section>
	)
}
