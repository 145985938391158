import { z } from 'zod'
import { ConversationSchema, PersonalitySchema } from '#src/api/icp/company/chat/roleplay/session/schemas'

export const SessionDataSchema = z.object({
	conversation: ConversationSchema,
	participant: z
		.object({
			info: z.object({ title: z.string().optional().nullable() }),
			persona: z.object({ type: z.string().optional().nullable() }),
		})
		.nullable(),
	writable: z.boolean(),
})

export const CreateSessionAPIResSchema = z.object({
	conversationId: z.string(),
	company: z.string(),
})

export const CreateSessionFormSchema = z.object({
	title: z.string(),
	ecosystemId: z.number(),
	verticalId: z.number(),
	personaId: z.number(),
	linkedinUrl: z.string().url().optional(),
	personality: PersonalitySchema,
	evaluation: z.string().optional().nullable(),
	scenario: z.string().optional().nullable(),
	scenarioTitle: z.string(),
	templateId: z.number(),
})
export type CreateSessionFormSchemaType = z.infer<typeof CreateSessionFormSchema>

export const getActiveNewSessionWizardSchema = (activeStep: string) => {
	switch (activeStep) {
		case 'session':
			return CreateSessionFormSchema.pick({
				title: true,
				scenario: true,
				scenarioTitle: true,
			})
		case 'persona-mapping':
			return CreateSessionFormSchema.pick({
				ecosystemId: true,
				verticalId: true,
				personaId: true,
				linkedinUrl: true,
			})
		case 'personality':
			return CreateSessionFormSchema.pick({
				personality: true,
			})
		case 'evaluation':
			return CreateSessionFormSchema.pick({ evaluation: true })
		default:
			return CreateSessionFormSchema
	}
}

export const SessionFormPayloadSchema = CreateSessionFormSchema.omit({
	ecosystemId: true,
	verticalId: true,
}).merge(z.object({ scenario: z.string().optional().nullable() }))
