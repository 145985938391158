import { parseWithZod } from '@conform-to/zod'
import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { companyChatRoleplaySessionQueriesKeys } from '#src/api/icp/company/chat/roleplay/session/queries'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { validateLinkedinUrl } from '#src/routes/enable/chat/mutations'
import { createRoleplaySession } from '#src/routes/enable/roleplay-sessions/mutations'
import {
	type CreateSessionFormSchemaType,
	getActiveNewSessionWizardSchema,
} from '#src/routes/enable/roleplay-sessions/schema'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ request, params }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)

	const formData = await request.formData()
	const activeStep = formData.get('activeStep') as string
	const activeSchema = getActiveNewSessionWizardSchema(activeStep)

	const submission = parseWithZod(formData, { schema: activeSchema })

	if (submission.status !== 'success') {
		throw Error('Failed to parse form data')
	}

	try {
		if (activeStep === 'evaluation') {
			const fullFormData = JSON.parse(submission.payload.fullFormData as string) as CreateSessionFormSchemaType
			const payload: CreateSessionFormSchemaType = {
				...fullFormData,
				...submission.value,
			}

			const res = await createRoleplaySession(company.id, {
				title: payload.title,
				personaId: payload.personaId,
				linkedinUrl: payload.linkedinUrl,
				personality: payload.personality,
				scenario: payload.scenario,
				evaluation: payload.evaluation,
				scenarioTitle: payload.scenarioTitle,
				templateId: payload.templateId,
			})

			await client.invalidateQueries({
				queryKey: companyChatRoleplaySessionQueriesKeys.all,
			})

			return redirect(
				routes.enable.roleplay.session.index({
					companyId,
					conversationId: res.conversationId,
				}),
			)
		}
	} catch (err) {
		captureException(err)

		return {
			ok: false,
			result: submission.reply({
				fieldErrors: {
					instructions: ['Unable to finish the session setup'],
				},
			}),
			formData,
		}
	}

	try {
		if ('linkedinUrl' in submission.value && submission.value.linkedinUrl) {
			const payload = {
				linkedinUrl: submission.value.linkedinUrl,
				personaId: submission.value.personaId,
			}
			await validateLinkedinUrl(company.id, payload)

			showToast({
				message: 'LinkedIn URL verified',
				type: 'success',
				position: 'top',
			})

			return {
				ok: true,
				result: {
					...submission.reply(),
				},
			}
		}
	} catch (err) {
		console.error(err)
		return {
			ok: false,
			result: submission.reply({
				fieldErrors: {
					linkedinUrl: ['Unable to verify Linkedin profile'],
				},
			}),
		}
	}

	return {
		ok: true,
		result: submission.reply(),
	}
}
