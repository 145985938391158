import { type z } from 'zod'

export const getEarliestFailedStepFromResErrors = <T>(
	errors: Record<string, string[]> | undefined,
	steps: { step: T; schema: z.SomeZodObject }[],
): T | undefined => {
	let res: T | undefined = undefined
	if (!errors) {
		return res
	}
	steps.some(({ step, schema }) => {
		const shape = schema.shape
		if (res) {
			return true
		}
		Object.keys(shape).some(key => {
			if (key in errors) {
				res = step
				return true
			}
		})
	})
	return res
}
