import { type LoaderFunctionArgs, redirect } from 'react-router-dom'
import { orgDataEnrichmentWorkflowsQueries } from '#src/api/organise/data-enrichment/workflows/queries'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { companyId } = await getCompany(params)
	validateRouteParams(params, ['jobId'])

	try {
		return {
			values: {
				jobId: params.jobId,
			},
			data: {
				job: await client.fetchQuery(orgDataEnrichmentWorkflowsQueries.item(params.jobId)),
			},
		}
	} catch (e) {
		showToast({
			message: `${(e as Response).status}: ${(e as Response).statusText} | ${(e as Response).url}`,
			type: 'error',
		})
		throw redirect(routes.organize.contactWaterfalling.index({ companyId }))
	}
}
