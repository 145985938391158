import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { companyChatRoleplayScenarioMutations } from '#src/api/icp/company/chat/roleplay/scenario/mutations'
import { ScenarioInviteFormSchema } from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['scenarioId'])
	const formData = await request.formData()
	const submission = parseFormDataNew(formData, ScenarioInviteFormSchema)

	try {
		await companyChatRoleplayScenarioMutations.assignUsers(company.id, params.scenarioId, submission.value.members)

		showToast({
			message: 'Invitations were sent',
			type: 'success',
		})

		return redirect(
			routes.enable.roleplay.scenario.item({
				companyId,
				scenarioId: params.scenarioId,
			}),
		)
	} catch (error) {
		showToast({
			message: 'Failed to send invitations',
			type: 'error',
		})
	}

	return { ok: false }
}
