import { useLoaderData } from 'react-router-dom'
import { type CriteriaSaveLoaderType } from '#src/routes/calibrate/verticals/criteria/routes/save'
import { Form } from './components'

export const View = () => {
	const {
		values: { key },
	} = useLoaderData() as CriteriaSaveLoaderType

	return <Form key={key} />
}
