import { useFetcher } from 'react-router-dom'
import { EmptyStateCard } from '#src/components'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'

export const NoFilterResults = () => {
	const { companyId } = useCompany()
	const filtersFetcher = useFetcher()
	return (
		<div className="mx-20 w-full">
			<EmptyStateCard
				icon="search"
				title="No results match"
				actions={[
					{
						title: 'Clear all filters',
						noIcon: true,
						onClick: () =>
							filtersFetcher.submit(
								{ intent: 'clear-all' },
								{
									method: 'POST',
									action: routes.prioritize.filters({ companyId }),
								},
							),
					},
				]}
			/>
		</div>
	)
}
