import { type FieldMetadata, getSelectProps } from '@conform-to/react'
import { type z } from 'zod'
import {
	type CommunicationStyle,
	type DecisionMakingProcess,
	type EmotionalState,
	type PersonalityTrait,
} from '#src/api/icp/company/chat/roleplay/session/schemas'
import { Select } from '#src/components/forms'
import {
	COMMUNICATION_STYLE_OPTIONS,
	DECISION_MAKING_PROCESS_OPTIONS,
	EMOTIONAL_STATE_OPTIONS,
	PERSONALITY_TRAIT_OPTIONS,
} from '#src/constants/options'
import { useVoice } from '#src/hooks/useVoice'
import {
	COMMUNICATION_STYLES_DETAILS,
	DECISSION_MAKING_PROCESSES_DETAILS,
	EMOTIONAL_STATES_DETAILS,
	PERSONALITY_TRAITS_DETAILS,
} from '#src/routes/enable/roleplay-sessions/constants'
import { CreateSessionFormSchema } from '#src/routes/enable/roleplay-sessions/schema'

const PickedSchema = CreateSessionFormSchema.pick({
	personality: true,
})

type PickedSchemaType = z.infer<typeof PickedSchema>

type PersonalityFormProps = {
	fields: {
		[K in keyof PickedSchemaType]: FieldMetadata<PickedSchemaType[K]>
	}
}

export const PersonalityForm = ({ fields }: PersonalityFormProps) => {
	const personalityFields = fields.personality.getFieldset()
	const { voiceOptions } = useVoice()

	return (
		<>
			<div className="flex flex-col gap-1">
				<Select
					labelProps={{ children: 'Personality traits' }}
					inputProps={{
						...getSelectProps(personalityFields.personalityTrait),
						defaultValue: personalityFields.personalityTrait.initialValue,
						value: personalityFields.personalityTrait.value,
						placeholder: '-',
						className: 'bg-white',
					}}
					options={PERSONALITY_TRAIT_OPTIONS}
					errors={personalityFields.personalityTrait.errors}
				/>
				{!!personalityFields.personalityTrait.value && (
					<div className="text-body-sm text-neutral-3-fg">
						{Object.entries(
							PERSONALITY_TRAITS_DETAILS[personalityFields.personalityTrait.value as PersonalityTrait],
						).map(([option, value]) => (
							<p key={`personality-traits-${option}`}>
								<b>{option}:</b> {value}.
							</p>
						))}
					</div>
				)}
			</div>
			<div className="flex flex-col gap-1">
				<Select
					labelProps={{ children: 'Emotional states' }}
					inputProps={{
						...getSelectProps(personalityFields.emotionalState),
						defaultValue: personalityFields.emotionalState.initialValue,
						value: personalityFields.emotionalState.value,
						placeholder: '-',
						className: 'bg-white',
					}}
					options={EMOTIONAL_STATE_OPTIONS}
					errors={personalityFields.emotionalState.errors}
				/>
				{!!personalityFields.emotionalState.value && (
					<div className="text-body-sm text-neutral-3-fg">
						{Object.entries(EMOTIONAL_STATES_DETAILS[personalityFields.emotionalState.value as EmotionalState]).map(
							([option, value]) => (
								<p key={`emotional-state-${option}`}>
									<b>{option}:</b> {value}.
								</p>
							),
						)}
					</div>
				)}
			</div>
			<div className="flex flex-col gap-1">
				<Select
					labelProps={{ children: 'Communication styles' }}
					inputProps={{
						...getSelectProps(personalityFields.communicationStyle),
						defaultValue: personalityFields.communicationStyle.initialValue,
						value: personalityFields.communicationStyle.value,
						placeholder: '-',
						className: 'bg-white',
					}}
					options={COMMUNICATION_STYLE_OPTIONS}
					errors={personalityFields.communicationStyle.errors}
				/>
				{!!personalityFields.communicationStyle.value && (
					<div className="text-body-sm text-neutral-3-fg">
						{Object.entries(
							COMMUNICATION_STYLES_DETAILS[personalityFields.communicationStyle.value as CommunicationStyle],
						).map(([option, value]) => (
							<p key={`communication-style-${option}`}>
								<b>{option}:</b> {value}.
							</p>
						))}
					</div>
				)}
			</div>
			<div className="flex flex-col gap-1">
				<Select
					labelProps={{ children: 'Decision making process' }}
					inputProps={{
						...getSelectProps(personalityFields.decisionMakingProcess),
						defaultValue: personalityFields.decisionMakingProcess.initialValue,
						value: personalityFields.decisionMakingProcess.value,
						placeholder: '-',
						className: 'bg-white',
					}}
					options={DECISION_MAKING_PROCESS_OPTIONS}
					errors={personalityFields.decisionMakingProcess.errors}
				/>
				{!!personalityFields.decisionMakingProcess.value && (
					<div className="text-body-sm text-neutral-3-fg">
						{Object.entries(
							DECISSION_MAKING_PROCESSES_DETAILS[
								personalityFields.decisionMakingProcess.value as DecisionMakingProcess
							],
						).map(([option, value]) => (
							<p key={`decission-making-${option}`}>
								<b>{option}:</b> {value}.
							</p>
						))}
					</div>
				)}
			</div>
			<Select
				labelProps={{ children: 'Voice character' }}
				inputProps={{
					...getSelectProps(personalityFields.voice),
					defaultValue: personalityFields.voice.initialValue,
					value: personalityFields.voice.value,
					placeholder: '-',
					className: 'bg-white',
				}}
				options={voiceOptions}
				errors={personalityFields.voice.errors}
			/>
		</>
	)
}
