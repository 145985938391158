import { useLoaderData } from 'react-router-dom'
import { Surface } from '#src/components/ui/surface'
import usePersonaDetails from '#src/hooks/usePersonaDetails'
import useVerticalDetails from '#src/hooks/useVerticalDetails'
import { type PersonasDetailsLoaderResType } from '../routes/details'
import { PersonasDetailsBody, PersonasDetailsHeader } from './components/details'

export const Details = () => {
	const { persona, vertical, plays, intents } = useLoaderData() as PersonasDetailsLoaderResType

	const { data: personaDetails } = usePersonaDetails({
		personaId: persona.id,
		initialData: persona,
	})

	const { data: verticalDetails } = useVerticalDetails({
		verticalId: vertical.id,
		initialData: vertical,
	})

	return (
		<Surface className="flex w-full flex-col self-start p-0">
			<PersonasDetailsHeader persona={personaDetails} />
			<PersonasDetailsBody persona={personaDetails} plays={plays} intents={intents} vertical={verticalDetails} />
		</Surface>
	)
}
