import { z, type ZodType } from 'zod'
import { FormField } from '#src/components/forms/v2/FormField'
import { type MultiSelectOption } from '#src/components/ui/MultiSelect'
import { type RadioGroupOption } from '#src/components/ui/RadioGroup'
import { type SelectOption } from '#src/components/ui/select'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { criteriaAssignedQuery } from '#src/routes/calibrate/verticals/criteria/queries'
import {
	type CriteriaAPISchema,
	type CriterionAnswerFieldId,
	type CriterionQuestionAPISchema,
} from '#src/routes/calibrate/verticals/criteria/schema'
import { processDefaultCriterionFieldValue, splitCriteriaByType } from '#src/routes/calibrate/verticals/criteria/utils'
import {
	type CriterionFieldId,
	type VerticalAPISchema,
	VerticalFormSchema,
} from '#src/routes/calibrate/verticals/schema'
import { getFieldSchemaByType } from '#src/schemas/global'
import { API_TO_FIELD_TYPE_MAP } from '#src/utils/enumerations'
import { transformFieldOptionsFromApi } from '#src/utils/misc'
import { zString } from '#src/utils/validation'

export const extractCriterionFieldValues = (
	fieldId: CriterionFieldId,
): {
	criterionName: z.infer<typeof CriterionQuestionAPISchema>['name']
	criterionId: string
} | null => {
	const parts = fieldId.split('::')

	if (parts.length !== 2) {
		return null
	}

	const [criterionName, criterionId] = parts

	return {
		criterionName,
		criterionId,
	}
}

export const generateCriterionFieldId = (id: z.infer<typeof CriterionQuestionAPISchema>['id']): string =>
	`criterion::${id}`

export const transformVerticalSaveSubmissionToPayload = (
	submission: Record<CriterionFieldId, unknown>,
	ecosystemId: string,
) => ({
	...submission,
	ecosystemId,
	answers: Object.entries(submission).flatMap(([fieldId, value]) => {
		const parsedField = extractCriterionFieldValues(fieldId as CriterionAnswerFieldId)

		return parsedField
			? {
					criterionId: parsedField.criterionId,
					value,
				}
			: []
	}),
})

export const generateSchemaOnlyFromCriteriaQuestion = (
	criteria: z.infer<typeof CriteriaAPISchema>,
): z.ZodObject<Record<string, ZodType<unknown>>> => {
	const shape = criteria.reduce(
		(acc, { criterion }) => {
			const fieldType = criterion.type
			const fieldName = generateCriterionFieldId(criterion.id)

			if (API_TO_FIELD_TYPE_MAP[fieldType]) {
				const isEnrichment = criterion.isEnrichment

				// For display purposes range is shown inside select component
				if (fieldType === 'range') {
					acc[fieldName] = getFieldSchemaByType({
						fieldType: 'select',
						isOptional: isEnrichment,
						isNullable: isEnrichment,
					})

					return acc
				}

				acc[fieldName] = getFieldSchemaByType({ fieldType, isOptional: isEnrichment, isNullable: isEnrichment })
			}

			return acc
		},
		{} as Record<string, ZodType<unknown>>,
	)

	return z.object(shape)
}

export const getVerticalCriteriaDefaults = (
	criteria: z.infer<typeof CriteriaAPISchema>,
	vertical?: z.infer<typeof VerticalAPISchema>,
	options?: { fieldsDisabledByDefault?: boolean },
): Record<string, unknown> => {
	return criteria.reduce(
		(acc, item) => {
			const answer = vertical?.answers.find(answer => answer.criterionId === item.criterion.id)
			const fieldId = generateCriterionFieldId(item.criterion.id)

			if (answer) {
				Object.assign(acc, processDefaultCriterionFieldValue(item.criterion.type, fieldId, answer.value, options))
			} else {
				if (options?.fieldsDisabledByDefault) {
					acc[`${fieldId}Toggle`] = false
					Object.assign(acc, processDefaultCriterionFieldValue(item.criterion.type, fieldId, undefined, options))
				}
			}

			return acc
		},
		{} as Record<string, unknown>,
	)
}

export const generateSaveVerticalContext = async (
	companyId: string,
	ecosystemId: string,
	vertical?: z.infer<typeof VerticalAPISchema>,
) => {
	const [ecosystems, allCriteria] = await Promise.all([
		client.fetchQuery(ecosystemsQuery(companyId)),
		client.fetchQuery(criteriaAssignedQuery(companyId, ecosystemId)),
	])
	const ecosystem = ecosystems?.find(e => e.id === Number(ecosystemId))

	const { criteria, enrichmentCriteria } = splitCriteriaByType(allCriteria)
	const criteriaSchema = generateSchemaOnlyFromCriteriaQuestion(allCriteria)

	const VerticalExtendedSchema = ecosystem?.verticalAsCompany
		? VerticalFormSchema.extend({
				domain: zString(),
				bizModelLen: z.number().optional(),
			})
		: VerticalFormSchema.extend({
				bizModelLen: z.number().min(1),
			})

	const schema = VerticalExtendedSchema.merge(criteriaSchema)
	const defaultCriterionAnswersValues = getVerticalCriteriaDefaults(allCriteria, vertical, {
		fieldsDisabledByDefault: true,
	})

	let defaultValues = {
		status: 'Ongoing',
		priority: 'High',
		...(!ecosystem?.verticalAsCompany && { bizModelLen: 400 }),
		...defaultCriterionAnswersValues,
	}

	if (vertical) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { answers, domain, ...rest } = vertical

		defaultValues = {
			...defaultValues,
			...rest,
			...(domain && { domain }),
			...(rest.bizModelLen ? { bizModelLen: Number(rest.bizModelLen) } : { bizModelLen: undefined }),
		}
	}

	return { criteria, enrichmentCriteria, schema, defaultValues }
}

export const generateCriterionFieldsForVertical = (criteria: z.infer<typeof CriteriaAPISchema>) => {
	return criteria.map(({ criterion }) => {
		const baseFieldProps = {
			name: generateCriterionFieldId(criterion.id),
			extendedPlaceholder: criterion.name,
			label: criterion.name,
			enableToggle: true,
		}

		const fieldProps = {
			...baseFieldProps,
			...(criterion.type === 'boolean' ? { layout: 'horizontal' } : {}),
			...(criterion.type === 'select' || criterion.type === 'number' || criterion.type === 'range'
				? { iconRight: criterion.unit }
				: {}),
			...(criterion.type === 'text' ? { autoExpand: true } : {}),
			...(criterion.type === 'text' ? { rows: 1 } : {}),
		}

		switch (criterion.type) {
			case 'regions':
				return <FormField {...baseFieldProps} fieldType="regions" key={criterion.id} />

			case 'range':
				return (
					<FormField
						{...fieldProps}
						fieldType="select"
						options={transformFieldOptionsFromApi(criterion.type, criterion.options) as SelectOption[]}
						key={criterion.id}
					/>
				)

			case 'select':
				return (
					<FormField
						{...fieldProps}
						fieldType="select"
						options={transformFieldOptionsFromApi(criterion.type, criterion.options) as SelectOption[]}
						key={criterion.id}
					/>
				)

			case 'multiselect':
				return (
					<FormField
						{...fieldProps}
						fieldType="multiselect"
						options={transformFieldOptionsFromApi(criterion.type, criterion.options) as MultiSelectOption[]}
						chipSeparatorText={criterion.logic}
						key={criterion.id}
					/>
				)
			case 'boolean':
				return (
					<FormField
						{...baseFieldProps}
						fieldType="radio"
						layout="horizontal"
						options={transformFieldOptionsFromApi(criterion.type, criterion.options) as RadioGroupOption[]}
						key={criterion.id}
					/>
				)

			// For now backend only support text type
			case 'text':
				return <FormField {...fieldProps} fieldType="textarea" key={criterion.id} />
			default:
				return (
					<FormField
						{...fieldProps}
						// @ts-expect-error TypeScript may not recognize mapped valid criterion type values
						fieldType={API_TO_FIELD_TYPE_MAP[criterion.type]}
						key={criterion.id}
					/>
				)
		}
	})
}
