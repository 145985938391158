import { useQuery } from '@tanstack/react-query'
import { useParams, useRouteLoaderData } from 'react-router-dom'
import { companyQuery } from '#src/routes/company/queries'
import { type MainLoaderResponse } from '#src/routes/main/loaders/root'

const useCompany = () => {
	const { company: initialCompany } = useRouteLoaderData('main-loader') as MainLoaderResponse
	const { companyId } = useParams<{ companyId?: string }>()
	const { data: company } = useQuery({
		...companyQuery(companyId ?? initialCompany.id),
		initialData: initialCompany,
	})

	return {
		company,
		/** @note use companyId when you need to pass it to routes, and company.id when you realy need it (f.e. queries) */
		companyId: companyId ?? null,
	}
}

export default useCompany
