import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { organizeApi } from '#src/utils/endpoints'
import { type ContactWaterfallingItemAPISchema } from './schemas'

const base = () => `${organizeApi}/data-enrichment/workflows`

const endpoints = {
	list: (companyId: z.infer<typeof CompanySchema.shape.id>, page: number, sort: string[]) => {
		const query = new URLSearchParams({
			companyId,
			page: page.toString(),
		})
		sort.forEach(value => {
			query.append('sort[]', value)
		})

		return `${base()}/?${new URLSearchParams(query).toString()}`
	},
	item: (jobId: z.infer<typeof ContactWaterfallingItemAPISchema.shape.id>) => `${base()}/${jobId}`,
	itemStop: (jobId: z.infer<typeof ContactWaterfallingItemAPISchema.shape.id>) => `${base()}/${jobId}/cancel`,
	itemDownload: (jobId: z.infer<typeof ContactWaterfallingItemAPISchema.shape.id>, query: Record<string, string>) =>
		`${base()}/${jobId}/download?${new URLSearchParams(query).toString()}`,
	create: () => `${base()}/`,
	statistics: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base()}/statistic/company/${companyId}`,
}

export { endpoints as orgDataEnrichmentWorkflowsEndpoints }
