import { Link } from 'react-router-dom'
import { type z } from 'zod'
import { PersonaDetailsCard } from '#src/components/shared'
import {
	Dropdown,
	DropdownItem,
	DropdownLabel,
	DropdownSub,
	DropdownSubContent,
	DropdownSubTrigger,
} from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { type EcosystemListAPISchema } from '#src/routes/calibrate/ecosystem/schema'
import { DeletePersonaAsyncForm } from '#src/routes/calibrate/personas/delete'
import { type PersonaAPISchema } from '#src/routes/calibrate/personas/schema'
import { routes } from '#src/utils/routes'

type Props = {
	persona: z.infer<typeof PersonaAPISchema>
	ecosystems: z.infer<typeof EcosystemListAPISchema>
}

const PersonaHeader = (props: Props) => {
	const { persona, ecosystems } = props
	const { companyId } = useCompany()
	const { readOnlySession } = useAuth()

	return (
		<section className="flex items-center justify-between px-10 pb-8 pt-10">
			<PersonaDetailsCard persona={persona} avatarSize="lg" />
			{!readOnlySession && (
				<section className="flex flex-nowrap items-center gap-3">
					<Link
						to={routes.calibrate.persona.setup({
							companyId,
							ecosystemId: String(persona.ecosystem.id),
							verticalId: String(persona.vertical.id),
							personaId: String(persona.id),
						})}
						className="inline-flex items-center justify-center align-middle text-neutral-3-fg outline-none"
					>
						<Icon name="edit" size="sm" />
					</Link>
					<Dropdown
						trigger={
							<button type="button" className="flex items-center justify-center text-neutral-3-fg outline-none">
								<Icon name="copy-file" size="sm" />
							</button>
						}
					>
						{ecosystems.map(ecosystem => (
							<DropdownSub key={ecosystem.id}>
								<DropdownSubTrigger>{ecosystem.name}</DropdownSubTrigger>
								<DropdownSubContent>
									{ecosystem.verticals.length ? (
										ecosystem.verticals.map(vertical => (
											<DropdownItem key={vertical.id} asChild>
												<Link
													replace
													preventScrollReset
													to={routes.calibrate.persona.duplicate({
														companyId,
														ecosystemId: ecosystem.id.toString(),
														verticalId: vertical.id.toString(),
														personaId: vertical.personas?.[0]?.id?.toString() ?? null,
														duplicate: btoa(
															JSON.stringify({
																ecosystemId: persona.ecosystem.id,
																personaId: persona.id,
															}),
														),
													})}
													title={vertical.name}
												>
													{vertical.name}
												</Link>
											</DropdownItem>
										))
									) : (
										<DropdownLabel>No verticals</DropdownLabel>
									)}
								</DropdownSubContent>
							</DropdownSub>
						))}
					</Dropdown>

					<DeletePersonaAsyncForm
						ecosystemId={String(persona.ecosystem.id)}
						verticalId={String(persona.vertical.id)}
						personaId={String(persona.id)}
						className="flex items-center justify-center"
					>
						<Icon name="trash" size="sm" />
					</DeletePersonaAsyncForm>

					<Link
						to={routes.calibrate.persona.tune({
							companyId,
							ecosystemId: String(persona.ecosystem.id),
							verticalId: String(persona.vertical.id),
							personaId: String(persona.id),
						})}
						className="flex items-center justify-center text-neutral-3-fg outline-none"
					>
						<Icon name="magic-wand" size="sm" />
					</Link>
				</section>
			)}
		</section>
	)
}

export default PersonaHeader
