import { type FieldMetadata, getInputProps, getSelectProps, getTextareaProps, useInputControl } from '@conform-to/react'
import { type z } from 'zod'
import { Field, Select, TextareaField } from '#src/components/forms'
import ProductTip from '#src/components/product-tip'
import { useScenarioTemplate } from '#src/hooks/useScenarioTemplate'

import { CreateSessionFormSchema } from '#src/routes/enable/roleplay-sessions/schema'

const PickedSchema = CreateSessionFormSchema.pick({
	title: true,
	scenario: true,
	templateId: true,
	evaluation: true,
	scenarioTitle: true,
})

type PickedSchemaType = z.infer<typeof PickedSchema>

export type ScenarioFormProps = {
	fields: {
		[K in keyof PickedSchemaType]: FieldMetadata<PickedSchemaType[K]>
	}
}

export const SessionForm = ({ fields }: ScenarioFormProps) => {
	const { getAgendaOptions, getTemplateById } = useScenarioTemplate()
	const scenarioField = useInputControl(fields.scenario as FieldMetadata<string>)
	const evaluationField = useInputControl(fields.evaluation as FieldMetadata<string>)
	const scenarioTitleField = useInputControl(fields.scenarioTitle)

	const handleOnAgendaChange = (v: string) => {
		const template = getTemplateById(v)

		if (template) {
			scenarioField.change(template.scenario as string)
			evaluationField.change(template.evaluation)
			scenarioTitleField.change(template.name)
		}
	}

	return (
		<>
			<Field
				labelProps={{ children: 'Session name' }}
				inputProps={{
					...getInputProps(fields.title, { type: 'text' }),
					placeholder: 'Enter session name',
				}}
				color="white"
				errors={fields.title.errors}
			/>
			<Select
				labelProps={{
					children: 'Agenda',
				}}
				inputProps={{
					contentProps: {
						className: 'overflow-y-auto max-h-[20rem]',
					},
					className: 'h-12 bg-white',
					...getSelectProps(fields.templateId),
					defaultValue: fields.templateId.initialValue?.toString(),
					value: fields.templateId.value?.toString(),
					placeholder: '-',
					onValueChange: handleOnAgendaChange,
				}}
				options={getAgendaOptions()}
				errors={fields.templateId.errors}
			/>
			<TextareaField
				labelProps={{
					children: (
						<div className="flex items-center gap-1">
							Scenario tuning
							<ProductTip
								className="text-neutral-3-fg"
								content="If relevant, provide specific guidance for Roleplay behavior. You can include details such as desired tone, specific raised questions, extra knowledge about the company or product or any other special requirements. With more details the Roleplay can tailor is responses to meet your needs."
							/>
						</div>
					),
				}}
				textareaProps={{
					...getTextareaProps(fields.scenario as FieldMetadata<string>),
					placeholder: 'Enter details',
					rows: 12,
				}}
				color="white"
				errors={fields.scenario?.errors ?? []}
			/>
		</>
	)
}
