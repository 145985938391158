import { type ReactNode, useState } from 'react'
import Markdown from '#src/components/markdown'
import { Dialog, DialogTrigger } from '#src/components/ui/dialog'

type Props = {
	title: string
	description?: string | null
	children: ReactNode
}

export const PreviewBizDescriptionModal = (props: Props) => {
	const { title, description, children } = props

	const [open, setOpen] = useState(false)

	return (
		<Dialog
			open={open}
			onOpenChange={setOpen}
			trigger={<DialogTrigger asChild>{children}</DialogTrigger>}
			dialogHeading={title}
			dialogDescription="Business model description"
			footer={null}
			size="lg"
		>
			<Markdown>{description}</Markdown>
		</Dialog>
	)
}
