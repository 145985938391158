import { type LoaderFunctionArgs } from 'react-router-dom'
import { companyPersonaQueries } from '#src/api/icp/company/persona/queries'
import { playsQueries } from '#src/api/icp/company/plays/queries'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { personaQuery } from '#src/routes/calibrate/personas/queries'
import { verticalQuery } from '#src/routes/calibrate/verticals/queries'
import { personasQuery } from '#src/routes/enable/personas/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId', 'verticalId', 'personaId'])
	const { company, companyId } = await getCompany(params)
	const { readOnlySession } = await getUser()

	const [ecosystems, vertical, persona, personas, plays, intents] = await Promise.all([
		client.fetchQuery(ecosystemsQuery(company.id)),
		client.fetchQuery(verticalQuery(company.id, params.verticalId)),
		client.fetchQuery(personaQuery(company.id, params.personaId)),
		client.fetchQuery(
			personasQuery(company.id, {
				ecosystem_id: params.ecosystemId,
				vertical_id: params.verticalId,
			}),
		),
		client.fetchQuery(playsQueries.list(company.id)),
		client.fetchQuery(companyPersonaQueries.signals(company.id, Number(params.personaId))),
	])

	return {
		handle: {
			readOnlySession,
			companyId,
			ecosystemId: params.ecosystemId,
			verticalId: params.verticalId,
			personaId: params.personaId,
		},
		ecosystems,
		vertical,
		persona,
		personas: personas.data,
		plays: plays.filter(
			item =>
				persona.expertise &&
				persona.type &&
				item.expertise.includes(persona.expertise) &&
				item.personaTypes.includes(persona.type),
		),
		intents,
		reportsToPersona: personas.data.find(p => p.id === persona?.reportsTo?.id),
	}
}
