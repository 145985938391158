import { type z } from 'zod'
import { LRA_JOB_STATUS } from '#src/api/organise/lists/constants'
import { type LeadResearchAgentItemAPISchema } from '#src/api/organise/lists/schemas'

export const canStop = (status: z.infer<typeof LeadResearchAgentItemAPISchema.shape.status>) =>
	[LRA_JOB_STATUS.InProgress, LRA_JOB_STATUS.Launching].includes(status)

export const canDownload = (status: z.infer<typeof LeadResearchAgentItemAPISchema.shape.status>) =>
	[
		LRA_JOB_STATUS.Completed,
		LRA_JOB_STATUS.CompletedWithQuestions,
		LRA_JOB_STATUS.InProgress,
		LRA_JOB_STATUS.Cancelled,
		LRA_JOB_STATUS.Error,
	].includes(status)
