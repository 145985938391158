import { type FieldMetadata, getTextareaProps } from '@conform-to/react'
import { type z } from 'zod'
import { TextareaField } from '#src/components/forms'

import { CreateSessionFormSchema } from '#src/routes/enable/roleplay-sessions/schema'

const PickedSchema = CreateSessionFormSchema.pick({
	evaluation: true,
	templateId: true,
})

type PickedSchemaType = z.infer<typeof PickedSchema>

type EvaluationFormProps = {
	fields: {
		[K in keyof PickedSchemaType]: FieldMetadata<PickedSchemaType[K]>
	}
}

export const EvaluationForm = ({ fields }: EvaluationFormProps) => (
	<>
		<TextareaField
			labelProps={{ children: 'Evaluation criteria' }}
			textareaProps={{
				...getTextareaProps(fields.evaluation as FieldMetadata<string>),
				placeholder: 'Enter details',
				rows: 20,
			}}
			color="white"
			errors={fields.evaluation?.errors ?? []}
		/>
	</>
)
