import { z } from 'zod'
import { zNumber, zString } from '#src/utils/validation'

export const AdminCompanyFormSchema = z.object({
	name: zString(),
})

export const AdminCompanyDashboardFormSchema = z.object({
	dashboard: z
		.object({
			id: zNumber(z.number()),
			url: z.string().url().nullable().optional(),
		})
		.array(),
})
