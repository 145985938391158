import { z } from 'zod'
import { CompanySchema } from '#src/routes/company/schema'

export const ContactWaterfallingStatisticsAPISchema = z.object({
	inQueueCount: z.number(),
	inProgressCount: z.number(),
	completedCount: z.number(),
})

export enum DATA_ENRICHMENT_WORKFLOW_STATUS {
	Cancelled = 'cancelled',
	Completed = 'completed',
	Error = 'error',
	InProgress = 'in_progress',
	Pending = 'pending',
}

export const ContactWaterfallingListItemAPISchema = z.object({
	id: z.string(),
	title: z.string(),
	createdBy: z.string().nullable(),
	status: z.nativeEnum(DATA_ENRICHMENT_WORKFLOW_STATUS),
	comment: z.string().nullable(),
	companyId: z.string(),
	companyName: z.string(),
	originalEntryCount: z.number(),
	createdAt: z.string(),
	statistics: z
		.object({
			waterfalling: z.object({
				emails: z.array(
					z.object({
						provider: z.string(),
						count: z.number(),
					}),
				),
				phones: z.array(
					z.object({
						provider: z.string(),
						count: z.number(),
					}),
				),
				emailsCount: z.number(),
				phonesCount: z.number(),
				completedCount: z.number(),
				errorCount: z.number(),
				inProgressCount: z.number(),
				inQueueCount: z.number(),
			}),
		})
		.nullable(),
})

export const ContactWaterfallingItemAPISchema = ContactWaterfallingListItemAPISchema.merge(
	z.object({
		importFileUrl: z.string().nullable(),
	}),
)

export const MAX_UPLOAD_SIZE = 1024 * 1024 * 3 // 3MB
export const ACCEPTED_FILE_TYPES = ['text/csv'] as const

export const ContactWaterfallingJobFileFormSchema = z.object({
	file: z
		.instanceof(File, { message: 'Please select a file' })
		.refine(file => {
			return !file || file.size <= MAX_UPLOAD_SIZE
		}, 'File size must be less than 3MB')
		.refine(file => {
			return !file || ACCEPTED_FILE_TYPES.includes(file.type)
		}, 'File must be a CSV'),
})

export const ContactWaterfallingJobAdditionalInfoFormSchema = z.object({
	title: z.string(),
	comment: z.string().nullable(),
})

export enum CONTACT_WATERFALLING_ENRICHERS {
	Waterfalling = 'waterfalling',
}

export const ContactWaterfallingJobFormSchema = z
	.object({
		companyId: CompanySchema.shape.id,
		enrichers: z.array(z.nativeEnum(CONTACT_WATERFALLING_ENRICHERS)),
	})
	.merge(ContactWaterfallingJobFileFormSchema)
	.merge(ContactWaterfallingJobAdditionalInfoFormSchema)

export enum WORKFLOW_EXPORT_TYPE {
	All = 'all',
	OnlyFound = 'only-found',
}

export const ContactWaterfallingJobDownloadFormSchema = z.object({
	type: z.nativeEnum(WORKFLOW_EXPORT_TYPE, { message: 'Incorrect exporting type' }),
})
