import { type ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import PageLoader from '#src/components/loader'
import Panel, { PanelProvider } from '#src/components/panel'
import useLayoutProps from '#src/hooks/layouts/useLayoutProps'
import Sidebar from '#src/layouts/Sidebar'
import SupportInit from '#src/layouts/SupportInit'
import { cn } from '#src/utils/misc'
import Header from './header/Header'

type LayoutProps = {
	enableHeader?: boolean
	fullWidth?: boolean
	sidePanelDefaultExpanded?: boolean
	disableSidebar?: boolean
	sidePanelHeader?: ReactNode
	sidePanelContent?: ReactNode
	noPadding?: boolean
}

const Layout = (props: LayoutProps) => {
	const layout = useLayoutProps(props)
	return (
		<>
			<PageLoader />
			{!layout.disableSidebar && <Sidebar />}
			<div
				id="layout-container"
				className={cn(
					'relative flex min-h-screen w-full overflow-hidden bg-neutral-1-bg text-neutral-1-fg',
					!layout.disableSidebar && 'pl-14',
				)}
			>
				<PanelProvider>
					{layout.sidePanelContent && (
						<Panel header={layout.sidePanelHeader as ReactNode} defaultExpanded={layout.sidePanelDefaultExpanded}>
							{layout.sidePanelContent}
						</Panel>
					)}
					<div className="relative h-screen w-full overflow-y-auto" id="app-layout">
						{layout.enableHeader && (
							<Header panelToggle={!!layout.sidePanelContent} className="border-b border-neutral-1-bd" />
						)}

						<div
							className={cn(
								'mx-auto w-full',
								'flex flex-col',
								layout.enableHeader ? 'min-h-[calc(100%-var(--builder-header-height))]' : 'min-h-full',
								// !layout.fullWidth && 'max-w-screen-2xl',
								// !layout.noPadding && 'px-20 py-10',
								!layout.fullWidth && 'max-w-[1446px]',
								!layout.noPadding && 'px-[var(--layout-px)] py-4',
								!layout.enableHeader && !layout.noPadding && 'pt-14',
							)}
							id="main-content"
						>
							<Outlet />
						</div>
					</div>
				</PanelProvider>
			</div>
			<SupportInit />
		</>
	)
}

export default Layout
