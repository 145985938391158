import { useQuery } from '@tanstack/react-query'
import { type z } from 'zod'
import { PERSONA_DETAILS_REFRESH_INTERVAL } from '#src/constants/refetchIntervals'
import useCompany from '#src/hooks/useCompany'
import { personaQuery } from '#src/routes/calibrate/personas/queries'
import { type PersonaAPISchema } from '#src/routes/calibrate/personas/schema'

type Props = {
	personaId: number | string
	initialData: z.infer<typeof PersonaAPISchema>
}

const usePersonaDetails = (props: Props) => {
	const { personaId, initialData } = props
	const { company } = useCompany()
	const { data, isLoading } = useQuery({
		...personaQuery(company.id, String(personaId)),
		initialData,
		refetchInterval: ({ state: { data: currentData } }) =>
			currentData?.prompt?.status && ['Pending', 'In Progress', 'Queued'].includes(currentData.prompt.status)
				? PERSONA_DETAILS_REFRESH_INTERVAL
				: false,
		refetchOnWindowFocus: true,
	})

	return {
		data,
		isLoading,
	}
}

export default usePersonaDetails
