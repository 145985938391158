import { type RouteObject } from 'react-router-dom'
import { action as deleteTermAction } from '#src/routes/enable/glossary/delete'
import TermEdit, { loader as editTermLoader } from '#src/routes/enable/glossary/edit'
import Glossary, { type GlossaryLoaderResponse, loader as glossaryLoader } from '#src/routes/enable/glossary/index'
import { action as saveTermAction } from '#src/routes/enable/glossary/save'
import RouteBoundary from '#src/routes/route-boundary'
import { routes } from '#src/utils/routes'

export default {
	path: 'glossary',
	element: <Glossary />,
	errorElement: <RouteBoundary />,
	loader: glossaryLoader,
	handle: {
		layout: {
			enableHeader: true,
		},
		crumb: () => [
			{
				label: 'Industry-jargons',
				icon: 'notebook-reference',
			},
		],
		action: (data: GlossaryLoaderResponse) =>
			data.handle.canCreate
				? [
						{
							to: `${routes.enable.glossary.edit({ companyId: data.handle.companyId, termId: null })}?source=calibrate`,
							label: 'Add new term',
							icon: 'add',
						},
					]
				: [],
	},
	children: [
		{
			path: 'save/:termId?',
			action: saveTermAction,
		},
		{
			path: 'delete/:termId',
			action: deleteTermAction,
		},
		{
			path: 'edit/:termId?',
			element: <TermEdit />,
			loader: editTermLoader,
			handle: {
				layout: {
					enableHeader: true,
				},
			},
		},
	],
} satisfies RouteObject
