import { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { type z } from 'zod'
import { PersonaAvatar, PersonaData } from '#src/components/persona'
import Priority from '#src/components/priority'
import Status from '#src/components/status'
import { Dropdown, DropdownItem } from '#src/components/ui/dropdown'
import useCompany from '#src/hooks/useCompany'
import { type PersonasSchema } from '#src/routes/enable/personas/schema'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

type Props = {
	active: z.infer<typeof PersonasSchema.shape.id>
	personas: z.infer<typeof PersonasSchema>[]
}

const PersonaNav = (props: Props) => {
	const { personas, active } = props
	const { companyId } = useCompany()
	const totalLength = personas?.length ?? 0
	const ref = useRef<HTMLDivElement>(null)
	const [visibleNav, setNav] = useState(totalLength)

	useEffect(() => {
		const node = ref.current
		const resizeObserver = new ResizeObserver(entries => {
			for (const entry of entries) {
				const gapWidth = (totalLength - 1) * 16
				const buttonWidth = 128
				const navWidth = 256
				const containerWidth = entry.contentBoxSize[0].inlineSize

				const fitLength = Math.floor((containerWidth - gapWidth - buttonWidth) / navWidth)

				if (fitLength >= totalLength) {
					setNav(totalLength)
				} else {
					setNav(fitLength)
				}
			}
		})

		if (ref) {
			resizeObserver.observe(node!)
		}

		return () => {
			if (node) {
				resizeObserver.unobserve(node)
			}
		}
	}, [totalLength, setNav])

	if (!personas?.length) return null

	return (
		<nav className="w-full" ref={ref}>
			<div className="grid w-full grid-cols-1 grid-rows-1 items-center justify-between gap-4 overflow-hidden">
				<ul className="flex w-full flex-nowrap items-center border-b-2 border-neutral-1-bd">
					{personas.slice(0, visibleNav).map(persona => (
						<li key={persona.id} className="w-64">
							<NavLink
								className={({ isActive }) =>
									cn(
										'group relative grid w-full grid-cols-[2.5rem,1fr] gap-1.5 bg-transparent px-2 py-3',
										isActive ? 'active' : '',
									)
								}
								to={routes.calibrate.persona.index({
									companyId,
									ecosystemId: String(persona.ecosystemId),
									verticalId: String(persona.verticalId),
									personaId: String(persona.id),
								})}
							>
								<PersonaAvatar type={persona.type} />

								<div className="flex flex-col gap-0.5 overflow-hidden">
									<p className="truncate text-label-sm text-neutral-2-fg">{persona.expertise ?? ''}</p>
									<div className="flex items-center gap-2">
										<Status status={persona.status} />
										<Priority priority={persona.priority} />
									</div>
								</div>

								<div className="absolute bottom-[-2px] h-2 w-full border-b-2 border-transparent transition-colors hover:border-brand-1-bd-hover group-hover:border-brand-1-bd-hover group-[.active]:border-brand-1-bd" />
							</NavLink>
						</li>
					))}
					{visibleNav < personas.length && (
						<li className="ml-auto w-32">
							<Dropdown
								trigger={
									<button
										type="button"
										// NOTE: the width and fit adapter in the side-effect
										className={cn(
											'group relative flex w-full flex-nowrap items-center justify-start gap-2.5 rounded bg-neutral-1-bg px-4 py-2 text-body-md font-normal text-neutral-2-fg outline-none transition-colors hover:bg-neutral-2-bg-hover radix-state-open:bg-neutral-2-bg-selected',
											personas.slice(visibleNav).find(p => p.id === active)
												? 'bg-neutral-2-bg'
												: 'group-hover:bg-neutral-2-bg-hover',
										)}
									>
										<span
											className={cn(
												'flex h-10 w-10 items-center justify-center rounded-full bg-neutral-2-bg text-body-sm font-medium text-neutral-1-fg transition-colors group-radix-state-open:bg-neutral-1-bg',
												personas.slice(visibleNav).find(p => p.id === active)
													? 'bg-neutral-1-bg'
													: 'group-hover:bg-neutral-1-bg-hover',
											)}
										>
											{personas.length - visibleNav}
										</span>
										more...
										<span
											className={cn(
												'absolute bottom-[-12px] left-0 h-2 w-full border-b-2 border-transparent transition-colors group-radix-state-open:border-neutral-2-bg',
												personas.slice(visibleNav).find(p => p.id === active)
													? 'border-neutral-2-bg'
													: 'group-hover:border-neutral-2-bg-hover',
											)}
										/>
									</button>
								}
								contentProps={{
									className: 'max-w-96 px-0 gap-0',
									align: 'end',
									collisionPadding: 20,
								}}
							>
								{personas.slice(visibleNav).map(persona => (
									<DropdownItem key={persona.id} asChild>
										<PersonaData
											id={persona.id}
											type={persona.type}
											status={persona.status}
											priority={persona.priority}
											expertise={persona.expertise ?? ''}
											ecosystemId={persona.ecosystemId}
											verticalId={String(persona.verticalId)}
											className="justify-start rounded-none"
										/>
									</DropdownItem>
								))}
							</Dropdown>
						</li>
					)}
				</ul>
			</div>
		</nav>
	)
}

export default PersonaNav
