import { type ReactNode } from 'react'
import { useFetcher } from 'react-router-dom'
import { type z } from 'zod'
import { type ScenarioAPISchema } from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import DeleteConfirm from '#src/components/delete-confirm'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'
import { type LoaderRes } from '../../loaders/delete'

type Props = {
	scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>
	children: ReactNode
}

export const DeleteScenarioModal = (props: Props) => {
	const { scenarioId, children } = props
	const loaderFetcher = useFetcher<LoaderRes>()
	const { companyId } = useCompany()

	const onOpenChange = (data: boolean) => {
		if (data) {
			if (loaderFetcher.state === 'idle' && !loaderFetcher.data) {
				loaderFetcher.load(routes.enable.roleplay.scenario.delete({ scenarioId, companyId }))
			}
		}
	}

	const scenarioName = loaderFetcher.data?.scenario.name ?? loaderFetcher.data?.scenario.title ?? '...'

	return (
		<DeleteConfirm
			trigger={children}
			action={routes.enable.roleplay.scenario.delete({ companyId, scenarioId })}
			actionTitle="Remove"
			title={`Remove "${scenarioName}"?`}
			description={
				<>
					Are you sure you want to remove the <b>{scenarioName}</b> scenario?
				</>
			}
			onOpenChange={onOpenChange}
		/>
	)
}
