import { type RouteObject } from 'react-router-dom'
import { loader as calibrateModuleLoader } from '#src/routes/calibrate/_layout'
import CalibratePanel from '#src/routes/calibrate/_layout/panel'
import { indexLoader, IndexView } from '#src/routes/calibrate/dashboard/routes'
import Ecosystem, { loader as ecosystemLoader } from '#src/routes/calibrate/ecosystem'
import { action as deleteEcosystemAction } from '#src/routes/calibrate/ecosystem/delete'
import { action as saveEcosystemAction } from '#src/routes/calibrate/ecosystem/save'
import { action as reorderEcosystemsAction } from '#src/routes/calibrate/ecosystem/sort'
import { deletePersonasFormAction } from '#src/routes/calibrate/personas/batch-delete'
import calibratePersonasRouter from '#src/routes/calibrate/personas/router'
import valuePropRouter from '#src/routes/calibrate/value-proposition/router'
import Vertical, { loader as verticalsLoader, type VerticalLoaderResponse } from '#src/routes/calibrate/verticals'
import { copyValidatePersonasAction } from '#src/routes/calibrate/verticals/actions/copyValidatePersonasAction'
import {
	copyPersonasAction,
	copyPersonasLoader,
	CopyPersonasModal,
} from '#src/routes/calibrate/verticals/copy-personas'
import criteriaRouter from '#src/routes/calibrate/verticals/criteria/router'
import { action as deleteVerticalAction } from '#src/routes/calibrate/verticals/delete'
import VerticalEdit, { loader as verticalsEditLoader } from '#src/routes/calibrate/verticals/edit'
import { action as saveVerticalAction } from '#src/routes/calibrate/verticals/save'
import { routes } from '#src/utils/routes'

export default {
	path: 'calibrate',
	handle: {
		crumb: () => [
			{
				label: 'Centralized Intelligence',
				icon: 'machine-learning-model',
			},
		],
	},
	children: [
		{
			path: '',
			children: [
				{
					path: '',
					element: <IndexView />,
					loader: indexLoader,
					children: [
						{
							path: 'ecosystem',
							id: 'ecosystem',
							loader: ecosystemLoader,
							children: [
								{
									path: 'key-accounts',
									element: <Ecosystem isKeyAccounts />,
								},
								{
									path: ':ecosystemId?',
									element: <Ecosystem />,
									children: [
										{
											path: 'save',
											action: saveEcosystemAction,
										},
										{
											path: 'delete',
											action: deleteEcosystemAction,
										},
										{
											path: 'sort',
											action: reorderEcosystemsAction,
										},
									],
								},
							],
						},
					],
				},
				valuePropRouter,
			],
		},
		{
			id: 'calibrate-module',
			path: '',
			loader: calibrateModuleLoader,
			handle: {
				layout: {
					enableHeader: true,
					sidePanelContent: <CalibratePanel />,
					noPadding: true,
					fullWidth: true,
				},
			},
			children: [
				{
					path: 'verticals/:ecosystemId',
					element: <Vertical />,
					loader: verticalsLoader,
					handle: {
						crumb: (data: VerticalLoaderResponse) => [
							{
								label: data.handle.ecosystemName || 'Ecosystem',
								icon: 'ibm-cloud-pak',
							},
						],
						action: (data: VerticalLoaderResponse) =>
							data.handle.readOnlySession
								? []
								: [
										{
											to: routes.calibrate.verticals.create({
												companyId: data.handle.companyId,
												ecosystemId: data.handle.ecosystemId,
											}),
											label: `Add ${data.ecosystem?.verticalAsCompany ? 'Key Account' : 'Vertical'}`,
											icon: 'category-new',
										},
									],
					},
					children: [
						{
							path: 'save/:verticalId?',
							action: saveVerticalAction,
						},
						{
							path: 'delete/:verticalId',
							action: deleteVerticalAction,
						},
						{
							path: 'edit/:verticalId?',
							element: <VerticalEdit />,
							loader: verticalsEditLoader,
						},
						{
							path: 'persona',
							children: [
								{
									path: 'batch-delete',
									action: deletePersonasFormAction,
								},
								{
									path: 'copy-validate',
									action: copyValidatePersonasAction,
								},
								{
									element: <CopyPersonasModal />,
									path: 'copy',
									action: copyPersonasAction,
									loader: copyPersonasLoader,
								},
							],
						},
						criteriaRouter,
					],
				},
				calibratePersonasRouter,
			],
		},
	],
} satisfies RouteObject
