import { type z } from 'zod'
import { PersonaSignalsAPISchema, PersonaExpertiseAndTypeAPISchema } from '#src/api/icp/company/persona/schemas'
import { type PersonaAPISchema } from '#src/routes/calibrate/personas/schema'
import { type VerticalAPISchema } from '#src/routes/calibrate/verticals/schema'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { companyPersonaEndpoints } from './endpoints'

const keys = {
	all: ['icp', 'company', 'persona'] as const,
	signals: (companyId: z.infer<typeof CompanySchema.shape.id>, personaId: z.infer<typeof PersonaAPISchema.shape.id>) =>
		[...keys.all, 'signals', companyId, personaId] as const,
	expertiseAndType: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		verticalIds: z.infer<typeof VerticalAPISchema.shape.id>[],
	) => [...keys.all, 'expertiseAndType', companyId, ...verticalIds.sort((a, b) => (a < b ? 1 : -1))] as const,
}

const signalsQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	personaId: z.infer<typeof PersonaAPISchema.shape.id>,
) => ({
	queryKey: keys.signals(companyId, personaId),
	queryFn: async () =>
		await safeFetch(PersonaSignalsAPISchema, companyPersonaEndpoints.signals(companyId, personaId), {
			method: 'GET',
		}),
})

const expertiseAndTypeQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	verticalIds: z.infer<typeof VerticalAPISchema.shape.id>[],
) => ({
	queryKey: keys.expertiseAndType(companyId, verticalIds),
	queryFn: async () =>
		await safeFetch(
			PersonaExpertiseAndTypeAPISchema,
			companyPersonaEndpoints.expertiseAndType(companyId, verticalIds),
			{
				method: 'GET',
			},
		),
})

const queries = {
	signals: signalsQuery,
	expertiseAndType: expertiseAndTypeQuery,
}

export { queries as companyPersonaQueries, keys as companyPersonaQueriesKeys }
