import { type LoaderFunctionArgs } from 'react-router-dom'
import { type z } from 'zod'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/verticals/criteria/constants'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(type: z.infer<typeof CRITERION_TYPE_ENUM>) =>
	async ({ request, params }: LoaderFunctionArgs) => {
		validateRouteParams(params, ['ecosystemId'])
		const { companyId } = await getCompany(params)
		const url = new URL(request.url)
		const redirectTo = url.searchParams.get('to')

		return {
			values: {
				companyId,
				ecosystemId: params.ecosystemId,
				type,
				redirectTo,
				key: new Date().getTime(),
			},
		}
	}
