import { cva, type VariantProps } from 'class-variance-authority'
import { Chip, type chipVariants } from '#src/components/chip'
import { Icon, type IconName } from '#src/components/ui/icon'
import { type APIFieldType } from '#src/schemas/global'
import { type API_TO_FIELD_TYPE_MAP } from '#src/utils/enumerations'

const chipTypeMap: Record<
	APIFieldType,
	{ text: string; icon: IconName; color: VariantProps<typeof chipVariants>['variant'] }
> = {
	select: {
		text: 'Select',
		icon: 'checkmark-filled',
		color: 'orange',
	},
	multiselect: {
		text: 'Multi-Select',
		icon: 'list-checked',
		color: 'indigo',
	},
	regions: {
		text: 'Regions',
		icon: 'location',
		color: 'red',
	},
	text: {
		text: 'Text',
		icon: 'text-creation',
		color: 'gray',
	},
	number: {
		text: 'Number',
		icon: 'character-whole-number',
		color: 'blue',
	},
	range: {
		text: 'Range',
		icon: 'pan-horizontal',
		color: 'green',
	},
	boolean: {
		text: 'Yes/No',
		icon: 'boolean',
		color: 'purple',
	},

	// No API yet
	radio: {
		text: 'Radio',
		icon: 'boolean',
		color: 'purple',
	},
}

const iconSizeVariants = cva('', {
	variants: {
		size: {
			sm: 'h-3.5 w-3.5',
			md: 'h-4.5 w-4.5',
			lg: 'h-5 w-5',
		},
	},
	defaultVariants: {
		size: 'md',
	},
})

export type FormFieldTypeChip = keyof typeof API_TO_FIELD_TYPE_MAP

type FormFieldTypeChipProps = {
	type: APIFieldType
	size?: VariantProps<typeof iconSizeVariants>['size']
}

export const FormFieldTypeChip = ({ type, size = 'md' }: FormFieldTypeChipProps) => {
	if (!chipTypeMap[type]) return

	const { text, icon, color } = chipTypeMap[type]

	return (
		<Chip variant={color} className="gap-1.5" size={size}>
			<Icon name={icon} className={iconSizeVariants({ size })} />
			{text}
		</Chip>
	)
}
