import { type ReactNode } from 'react'

type Props = {
	title: ReactNode
	list: { label: ReactNode; value: ReactNode }[]
}

const Aside = ({ title, list }: Props) => {
	return (
		<section className="sticky top-[calc(var(--builder-header-height)+1rem)] flex flex-col gap-6 rounded-lg bg-neutral-2-bg p-6">
			<h3 className="w-full text-title-sm text-neutral-1-fg">{title}</h3>
			{list.map(({ label, value }, index) => (
				<section key={index} className="flex flex-col gap-[1px]">
					<h4 className="text-label-sm text-neutral-3-fg">{label}</h4>
					<p className="break-words text-body-md text-neutral-1-fg">{value}</p>
				</section>
			))}
		</section>
	)
}

export default Aside
