import { captureException } from '@sentry/react'
import { forwardRef, useEffect } from 'react'
import { type ActionFunctionArgs, useFetcher } from 'react-router-dom'
import { z } from 'zod'
import { Button, type ButtonProps } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Toast, ToastDescription, ToastViewport } from '#src/components/ui/toast'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { reorderEcosystems } from '#src/routes/company/mutations'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { ecosystemKeys, ecosystemsQuery } from './queries'
import { EcosystemsOrderFormSchema } from './schema'

export type SortActionResponse = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)

	const data = await request.json()
	const submission = await EcosystemsOrderFormSchema.superRefine(async (data, ctx) => {
		const ecosystems = await client.fetchQuery(ecosystemsQuery(company.id))
		const ecosystemIds = ecosystems.map(({ id }) => id)

		if (!data.ecosystems.every(id => ecosystemIds.includes(id))) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'should have all ecosystem ids',
			})
		}
	}).safeParseAsync(data)

	if (!submission.success) {
		// NOTE: This is to ensure state can be reset appropriately
		await client.invalidateQueries({
			queryKey: ecosystemKeys.all,
		})

		return {
			ok: false,
			result: 'Failed to reorder ecosystems',
		}
	}

	try {
		await reorderEcosystems(company.id, submission.data)

		await client.invalidateQueries({
			queryKey: ecosystemKeys.all,
		})

		return {
			ok: true,
		}
	} catch (error) {
		captureException(error)
		return {
			ok: false,
			result: 'Failed to reorder ecosystems',
		}
	}
}

interface SortEcosystemsAsyncFormProps extends ButtonProps {
	enabled: boolean
	toggleEnabled: () => void
	order: number[]
	onError: () => void
	onCancel: () => void
}

export const SORT_FETCHER_KEY = 'sort-ecosystems'

export const SortEcosystemsAsyncForm = forwardRef<HTMLButtonElement, SortEcosystemsAsyncFormProps>(
	({ enabled, toggleEnabled, order, onError, onCancel, ...props }, ref) => {
		const { companyId } = useCompany()
		const fetcher = useFetcher<SortActionResponse>({ key: SORT_FETCHER_KEY })
		const action = routes.calibrate.ecosystem.sort({
			companyId,
		})

		useEffect(() => {
			if (fetcher.state === 'loading' && !fetcher.data?.ok) {
				onError()
			}
		}, [fetcher.data, fetcher.state, onError])

		return (
			<>
				<Button
					{...props}
					ref={ref}
					type="button"
					disabled={fetcher.state !== 'idle'}
					className={cn(fetcher.state !== 'idle' ? 'animate-pulse' : '', 'flex flex-nowrap items-center gap-1')}
					variant="outline"
					size="sm"
					onClick={() => {
						if (enabled) {
							fetcher.submit({ ecosystems: order }, { method: 'POST', action, encType: 'application/json' })
						}

						toggleEnabled()
					}}
				>
					{['submitting', 'loading'].includes(fetcher.state) ? (
						<Icon name="update" className="animate-spin" size="sm" />
					) : enabled ? (
						<Icon name="checkmark-outline" size="sm" />
					) : (
						<Icon name="drag-vertical" size="sm" />
					)}
					{enabled ? 'Done' : 'Reorder'}
				</Button>
				{enabled && !['submitting', 'loading'].includes(fetcher.state) ? (
					<Button
						type="button"
						className="flex flex-nowrap items-center gap-1"
						variant="ghost"
						size="sm"
						onClick={() => {
							onCancel()
							toggleEnabled()
						}}
					>
						<Icon name="cross-1" size="sm" />
						Cancel
					</Button>
				) : null}
				<Toast shouldOpen={fetcher.state === 'loading' && !fetcher.data?.ok} duration={3000}>
					<ToastDescription className="flex items-center gap-3">
						<Icon name="error-filled" size="md" className="text-red-60" />
						{fetcher.data?.result ?? 'Error while sorting ecosystems. Try again.'}
					</ToastDescription>
				</Toast>
				<Toast shouldOpen={fetcher.state === 'loading' && !!fetcher.data?.ok} duration={3000}>
					<ToastDescription className="flex items-center gap-3">
						<Icon name="checkmark-filled" size="md" className="text-green-70" />
						Ecosystems sorted successfully
					</ToastDescription>
				</Toast>
				<div className="absolute">
					<ToastViewport />
				</div>
			</>
		)
	},
)
SortEcosystemsAsyncForm.displayName = 'SortEcosystemsAsyncForm'
