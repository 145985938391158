import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { orgDataEnrichmentWorkflowsQueries } from '#src/api/organise/data-enrichment/workflows/queries'
import { DATA_ENRICHMENT_WORKFLOW_STATUS } from '#src/api/organise/data-enrichment/workflows/schemas'
import { Button } from '#src/components/ui/button'
import { Icon, type IconName } from '#src/components/ui/icon'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import { PageLayout, type PageLayoutProps } from '#src/components/ui/PageLayout'
import { Surface } from '#src/components/ui/surface'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { type ClassName } from '#src/types/styles'
import { formatDateToReadableString } from '#src/utils/date'
import { JobStatusName } from '../constants'
import { type LoaderRes } from '../loaders/item'
import { canDownload, canStop } from '../utils'
import {
	BarLineStatistics,
	ItemStatisticsCard,
	type ItemStatisticsCardProps,
	JobDownloadModal,
	JobStopModal,
} from './components'

const FIRST_CARD_ICON: Record<DATA_ENRICHMENT_WORKFLOW_STATUS, IconName> = {
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Pending]: 'loading-sm',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Completed]: 'checkmark-filled',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Cancelled]: 'stop-sign-filled',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Error]: 'stop-sign-filled',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.InProgress]: 'loading-sm',
} as const

const FIRST_CARD_ICON_VARIANT: Record<DATA_ENRICHMENT_WORKFLOW_STATUS, ItemStatisticsCardProps['iconVariant']> = {
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Pending]: undefined,
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Completed]: 'success',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Cancelled]: 'danger',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Error]: 'danger',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.InProgress]: undefined,
} as const

const STATISTICS_COLORS: { color: ClassName; iconColor: ClassName }[] = [
	{
		color: 'bg-indigo-70',
		iconColor: 'text-indigo-70',
	},
	{
		color: 'bg-green-70',
		iconColor: 'text-green-70',
	},
	{
		color: 'bg-purple-70',
		iconColor: 'text-purple-70',
	},
	{
		color: 'bg-orange-70',
		iconColor: 'text-orange-70',
	},
	{
		color: 'bg-blue-70',
		iconColor: 'text-blue-70',
	},
] as const

export const View = () => {
	const {
		values: { jobId },
		data,
	} = useLoaderData() as LoaderRes

	const { data: job } = useQuery({
		...orgDataEnrichmentWorkflowsQueries.item(jobId),
		initialData: data.job,
		refetchInterval: ({ state }) => {
			if (!state.data) {
				return 0
			}
			if (canStop(state.data.status)) {
				return 5000
			}
			return 0
		},
	})

	const [copyIdTooltipShow, setCopyIdTooltipShow] = useState(false)
	const onClickCopyId = () => {
		navigator.clipboard
			.writeText(job.id)
			.then(() => {
				setCopyIdTooltipShow(true)
				setTimeout(() => setCopyIdTooltipShow(false), 1500)
			})
			.catch(() => {})
	}

	return (
		<PageLayout
			title={job.title}
			actions={[
				...((canStop(job.status)
					? [
							<JobStopModal jobId={jobId} key={`action-stop`}>
								<Button variant="danger-outline" className="flex flex-nowrap items-center gap-2">
									<Icon name="stop-filled-alt" size="font" className="text-status-danger-fg" />
									Stop
								</Button>
							</JobStopModal>,
						]
					: []) satisfies PageLayoutProps['actions']),
				...((canDownload(job.status)
					? [
							<JobDownloadModal key={`action-download`} jobId={jobId}>
								<Button className="flex flex-nowrap items-center gap-2">
									<Icon name="download" /> Download
								</Button>
							</JobDownloadModal>,
						]
					: []) satisfies PageLayoutProps['actions']),
			]}
		>
			<div className="grid w-full max-w-screen-2xl grid-cols-1 grid-rows-1 gap-6 lg:grid-cols-2">
				<ItemStatisticsCard
					icon={FIRST_CARD_ICON[job.status]}
					iconVariant={FIRST_CARD_ICON_VARIANT[job.status]}
					name={JobStatusName[job.status]}
				>
					<BarLineStatistics
						name="Contacts"
						items={[
							{
								id: 'in_queue',
								icon: 'circle-dash',
								name: 'In queue',
								color: 'bg-brand-2-fg',
								iconColor: 'text-neutral-3-fg',
								value: job.statistics?.waterfalling.inProgressCount ?? 0,
							},
							{
								id: 'in_progress',
								icon: 'incomplete',
								name: 'In progress',
								color: 'bg-brand-1-fg',
								iconColor: 'text-brand-1-fg',
								value: job.statistics?.waterfalling.inProgressCount ?? 0,
							},
							{
								id: 'completed',
								icon: 'checkmark-filled',
								name: 'Completed',
								color: 'bg-status-success-bg',
								iconColor: 'text-status-success-bg',
								value: job.statistics?.waterfalling.completedCount ?? 0,
							},
							{
								id: 'with_errors',
								icon: 'checkmark-filled',
								name: 'With errors',
								color: 'bg-status-danger-bg',
								iconColor: 'text-status-danger-bg',
								value: job.statistics?.waterfalling.errorCount ?? 0,
							},
						]}
					/>
				</ItemStatisticsCard>
				<ItemStatisticsCard
					icon="identification"
					name="Contacts found"
					tip="This view shows the number of phone numbers and emails found, with their distribution by provider."
				>
					<div className="grid grid-cols-2 gap-6">
						<BarLineStatistics
							name="Phone"
							items={
								job.statistics?.waterfalling.phones.map((item, index) => ({
									id: item.provider,
									icon: 'dot',
									name: item.provider,
									color: STATISTICS_COLORS[index]?.color ?? 'bg-brand-2-fg',
									iconColor: STATISTICS_COLORS[index]?.iconColor ?? 'text-brand-2-fg',
									value: item.count,
								})) ?? []
							}
						/>
						<BarLineStatistics
							name="Email"
							items={
								job.statistics?.waterfalling.emails.map((item, index) => ({
									id: item.provider,
									icon: 'dot',
									name: item.provider,
									color: STATISTICS_COLORS[index]?.color ?? 'bg-brand-2-fg',
									iconColor: STATISTICS_COLORS[index]?.iconColor ?? 'text-brand-2-fg',
									value: item.count,
								})) ?? []
							}
						/>
					</div>
				</ItemStatisticsCard>
			</div>
			<Surface className="flex h-full w-full flex-col gap-8 p-10">
				<h2 className="text-title-lg">Details</h2>
				<div className="flex w-full flex-row gap-8">
					<div className="flex flex-1 flex-col gap-6">
						<LabeledValue label="Name">{job.title}</LabeledValue>
						{!!job.comment && (
							<LabeledValue label="Comment">
								<div className="whitespace-pre-wrap">{job.comment}</div>
							</LabeledValue>
						)}
					</div>
					<div className="flex w-full max-w-[330px] flex-col gap-6 rounded-lg bg-neutral-2-bg p-6">
						<LabeledValue label="Id">
							<span>{job.id}</span>
							<CustomTooltip
								rootProps={{
									open: copyIdTooltipShow,
								}}
								label="Copied"
							>
								<Icon
									name="copy"
									className="ml-1 size-4 cursor-pointer text-neutral-3-fg hover:text-neutral-1-fg"
									onClick={onClickCopyId}
								/>
							</CustomTooltip>
						</LabeledValue>
						<LabeledValue label="Date created">{formatDateToReadableString(job.createdAt)}</LabeledValue>
						{!!job.importFileUrl && (
							<LabeledValue label="List source file">
								<a
									href={job.importFileUrl}
									target="_blank"
									rel="noreferrer"
									className="flex w-full flex-row items-center gap-1"
								>
									<Icon name="document" size="md" className="block text-brand-1-fg" />
									<div className="flex flex-1 flex-col truncate">
										<div className="max-w-full truncate text-body-md text-brand-1-fg">Uploaded file</div>
										{/*<div className="text-body-sm text-neutral-2-fg">500 KB</div>*/}
									</div>
								</a>
							</LabeledValue>
						)}
						<LabeledValue label="Contacts uploaded">{job.originalEntryCount}</LabeledValue>
						<LabeledValue label="Phone data providers">
							{job.statistics?.waterfalling.phones.map((item, index) => (
								<p key={item.provider}>
									{index + 1}. {item.provider}
								</p>
							)) ?? []}
						</LabeledValue>
						<LabeledValue label="Email providers">
							{job.statistics?.waterfalling.emails.map((item, index) => (
								<p key={item.provider}>
									{index + 1}. {item.provider}
								</p>
							)) ?? []}
						</LabeledValue>
					</div>
				</div>
			</Surface>
			{/*<Surface className="flex h-full w-full flex-col gap-8 p-10">*/}
			{/*	<h2 className="text-title-lg">Report</h2>*/}
			{/*	<iframe*/}
			{/*		className="aspect-video w-full"*/}
			{/*		src={`https://evergrowth.metabaseapp.com/public/dashboard/58453dcb-dae4-4d47-97db-91a842678709?criteria=-&icp=&job=${job.id}`}*/}
			{/*	/>*/}
			{/*</Surface>*/}
		</PageLayout>
	)
}
