import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useFetcher, useLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { EmptyStateCard } from '#src/components'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { AccordionItem, AccordionRoot } from '#src/components/ui/accordion'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Toast, ToastDescription } from '#src/components/ui/toast'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { IntentSource } from '#src/routes/prioritize/constants'
import { type PrioritizeItemAssignActionRes } from '#src/routes/prioritize/routes/assign'
import { type PrioritizeItemLoaderRes } from '#src/routes/prioritize/routes/item'
import { AssignedSignalsFormSchema } from '#src/routes/prioritize/schema'
import { EcosystemAccordionTrigger } from '#src/routes/prioritize/views/components/item/EcosystemAccordionTrigger'
import { PersonasTable } from '#src/routes/prioritize/views/components/item/PersonasTable'
import { SignalDetailsCard } from '#src/routes/prioritize/views/components/item/SignalDetailsCard'
import { UnmapAllPersonasModal } from '#src/routes/prioritize/views/components/item/UnmapAllPersonasModal'
import { routes } from '#src/utils/routes'

const FORM_ID = 'assign-personas-form'

export const View = () => {
	const {
		handle: { signalId },
		data: { signalTemplate, assignedSignals, ecosystems },
	} = useLoaderData() as PrioritizeItemLoaderRes
	const { readOnlySession } = useAuth()
	const { companyId, company } = useCompany()

	const fetcher = useFetcher<PrioritizeItemAssignActionRes>()

	const personas = useMemo(
		() => ecosystems.map(e => e.verticals.map(v => v.personas.map(persona => persona.id))).flat(3),
		[ecosystems],
	)

	const personasFields: z.infer<typeof AssignedSignalsFormSchema>['personas'] = useMemo(
		() =>
			personas
				.map(personaId => [
					personaId,
					{
						manualInput: assignedSignals.find(s => s.personaId === personaId)?.manualInput ?? '',
						selected: 'off',
					},
				])
				.reduce(
					(acc, [personaId, inputs]) => ({
						...acc,
						[personaId as number]: inputs,
					}),
					{},
				),
		[assignedSignals, personas],
	)

	const form = useForm<z.infer<typeof AssignedSignalsFormSchema>>({
		resolver: zodResolver(AssignedSignalsFormSchema),
		defaultValues: {
			personas: personasFields,
		},
		mode: 'onSubmit',
	})

	const selectedPersonasLength = Object.values(form.watch('personas')).filter(
		({ selected }) => selected === 'on',
	).length

	const initialRef = useRef(true)
	useEffect(() => {
		if (initialRef.current) {
			initialRef.current = false
			return
		}
		form.reset({ personas: personasFields })
		// eslint-disable-next-line
	}, [personasFields])

	const emptyManualInputsLength = Object.values(form.watch('personas')).filter(
		value => value.selected === 'on' && value.manualInput === '',
	).length

	const errorDefaultManualInput = signalTemplate.source === IntentSource.ACCOUNT && !signalTemplate.config.manualInput
	const errorRequiredManualInput =
		signalTemplate.source !== IntentSource.ACCOUNT && signalTemplate.manualRequired && !!emptyManualInputsLength

	return (
		<>
			<div className="flex flex-col gap-20 px-20 py-10">
				<SignalDetailsCard signalTemplate={signalTemplate} />
				<FormWrapper
					key={signalId}
					formId={FORM_ID}
					formProps={form}
					onSubmit={data => {
						fetcher.submit(data, {
							method: 'PATCH',
							action: routes.prioritize.signal.assign({
								companyId,
								signalId,
							}),
						})
					}}
					className="flex flex-col gap-4"
				>
					<section className="flex items-center justify-between">
						<h2 className="text-label-lg text-neutral-3-fg">Persona mapping</h2>
						{!readOnlySession && (
							<UnmapAllPersonasModal signalId={signalId}>
								<Button size="sm" variant="danger-outline" className="min-w-24 items-center gap-1">
									<Icon name="subtract-alt" size="sm" />
									Unmap all
								</Button>
							</UnmapAllPersonasModal>
						)}
					</section>

					<AccordionRoot type="multiple" className="flex flex-col gap-4">
						{ecosystems.map(ecosystem => (
							<AccordionItem
								key={ecosystem.id}
								value={`item-${ecosystem.id}`}
								trigger={<EcosystemAccordionTrigger ecosystem={ecosystem} assignedSignals={assignedSignals} />}
								contentProps={{
									className:
										'overflow-hidden px-4 radix-state-closed:animate-[acc-slide-up_150ms_ease-in-out] radix-state-open:animate-[acc-slide-down_150ms_ease-in-out]',
								}}
							>
								{ecosystem.verticals.length ? (
									<div className="flex flex-col gap-8">
										{ecosystem.verticals.map(vertical => (
											<section key={vertical.id} className="flex flex-col gap-4">
												<h3 className="flex items-center gap-1 text-label-lg text-neutral-2-fg">
													<Icon name="category" size="sm" /> {vertical.name}
												</h3>
												{vertical.personas?.length ? (
													<>
														<PersonasTable
															signalId={signalId}
															ecosystemId={ecosystem.id}
															verticalId={vertical.id}
															personas={vertical.personas}
															assignedSignals={assignedSignals}
															manualInputRequired={signalTemplate.manualRequired}
															defaultManualInput={signalTemplate.source === IntentSource.ACCOUNT}
															templateManualInput={signalTemplate.config.manualInput}
														/>
													</>
												) : (
													<EmptyStateCard
														icon="user-avatar"
														title={
															<>
																You don&apos;t have any personas in{' '}
																<span className="font-semibold">{vertical.name ?? 'this vertical'}</span>
															</>
														}
														{...(!readOnlySession && {
															actions: [
																{
																	title: 'Add persona',
																	to: routes.calibrate.persona.edit({
																		//todo: change to companyId when calibrate migrated to optional
																		companyId: company.id,
																		ecosystemId: ecosystem.id.toString(),
																		verticalId: vertical.id.toString(),
																		personaId: null,
																	}),
																},
															],
														})}
													/>
												)}
											</section>
										))}
									</div>
								) : (
									<EmptyStateCard
										icon="category"
										title="You don't have any verticals and personas for this ecosystem yet"
										{...(!readOnlySession && {
											actions: [
												{
													title: 'Add vertical',
													to: routes.calibrate.verticals.create({
														//todo: change to companyId when calibrate migrated to optional
														companyId: company.id,
														ecosystemId: ecosystem.id.toString(),
													}),
												},
											],
										})}
									/>
								)}
							</AccordionItem>
						))}
					</AccordionRoot>
				</FormWrapper>
			</div>
			<Toast shouldOpen={fetcher.state === 'loading' && !fetcher.data?.ok} duration={3000}>
				<ToastDescription className="flex items-center gap-3 text-body-md">
					<Icon name="error-filled" size="md" className="text-status-danger-fg" />
					{fetcher.data?.action === 'unmap-all'
						? 'Error while unmapping all personas. Try again.'
						: fetcher.data?.action === 'map'
							? 'Error while mapping all personas. Try again.'
							: 'Error while unmapping personas. Try again.'}
				</ToastDescription>
			</Toast>

			<Toast
				shouldOpen={fetcher.state === 'loading' && !!fetcher.data?.ok && ['map'].includes(fetcher.data?.action)}
				duration={3000}
			>
				<ToastDescription className="flex items-center gap-3 text-body-md">
					<Icon name="checkmark-filled" size="md" className="text-status-success-fg" />
					Personas successfully mapped
				</ToastDescription>
			</Toast>
			<Toast
				shouldOpen={fetcher.state === 'loading' && !!fetcher.data?.ok && ['unmap-all'].includes(fetcher.data?.action)}
				duration={3000}
			>
				<ToastDescription className="flex items-center gap-3 text-body-md">
					<Icon name="checkmark-filled" size="md" className="text-status-success-fg" />
					All personas successfully unmapped
				</ToastDescription>
			</Toast>
			<Toast
				shouldOpen={fetcher.state === 'loading' && !!fetcher.data?.ok && ['unmap'].includes(fetcher.data?.action)}
				duration={3000}
			>
				<ToastDescription className="flex items-center gap-3 text-body-md">
					<Icon name="checkmark-filled" size="md" className="text-status-success-fg" />
					Selected personas successfully unmapped
				</ToastDescription>
			</Toast>

			{!!selectedPersonasLength && (
				<div className="w-100 sticky bottom-0 left-0 grid h-14 grid-cols-[max-content,1fr] grid-rows-1 items-center bg-neutral-inverse-bg px-20 py-3 text-neutral-inverse-fg">
					<div className="text-body-md text-neutral-inverse-fg">
						{errorDefaultManualInput ? (
							<>
								<button
									onClick={() => document.getElementById('manualInput')?.focus()}
									type="button"
									className="inline-flex text-left text-button-sm text-link hover:text-link-hover active:text-link-pressed"
								>
									Enter default manual input
								</button>{' '}
								to be able to map personas
							</>
						) : errorRequiredManualInput ? (
							<>
								<Icon name="warning-alt" size="sm" className="mr-2 text-status-warning-fg" />
								{emptyManualInputsLength} selected persona{emptyManualInputsLength > 1 ? 's' : ''} requires manual input
							</>
						) : (
							`${selectedPersonasLength} persona${selectedPersonasLength > 1 ? 's' : ''} selected`
						)}
					</div>
					<div className="flex items-center gap-2 justify-self-end">
						<div className="text-body-md text-brand-inverse-fg">Actions:</div>

						<Button
							form={FORM_ID}
							type="submit"
							name="intent"
							value="map"
							disabled={
								errorDefaultManualInput ||
								errorRequiredManualInput ||
								(fetcher.state !== 'idle' && ['map', 'unmap'].includes(fetcher.formData?.get('intent') as string))
							}
							variant="secondary"
							size="sm"
							className="min-w-24 items-center gap-1"
						>
							<Icon name="add-filled" size="sm" />
							{fetcher.state === 'idle' || fetcher.formData?.get('intent') !== 'map' ? 'Map' : 'Mapping...'}
						</Button>

						<Button
							form={FORM_ID}
							type="submit"
							name="intent"
							value="unmap"
							disabled={
								fetcher.state !== 'idle' && ['map', 'unmap'].includes(fetcher.formData?.get('intent') as string)
							}
							variant="secondary"
							size="sm"
							className="min-w-24 items-center gap-1"
						>
							<Icon name="subtract-alt" size="sm" />
							{fetcher.state === 'idle' || fetcher.formData?.get('intent') !== 'unmap' ? 'Unmap' : 'Unmapping...'}
						</Button>
						<Button
							form={FORM_ID}
							type="submit"
							variant="outline"
							size="sm"
							className="min-w-24 items-center gap-1 bg-transparent text-white"
							onClick={() => form.reset()}
						>
							Cancel
						</Button>
					</div>
				</div>
			)}
		</>
	)
}
