import { Outlet, ScrollRestoration } from 'react-router-dom'

const Root = () => {
	return (
		<>
			<Outlet />
			<ScrollRestoration getKey={({ pathname }) => pathname} />
		</>
	)
}

export default Root
