import {
	getFormProps,
	getInputProps,
	getSelectProps,
	getTextareaProps,
	useForm,
	type FieldMetadata,
	useInputControl,
} from '@conform-to/react'
import { parseWithZod, getZodConstraint } from '@conform-to/zod'
import { type ReactNode, useState } from 'react'
import { Form, useActionData } from 'react-router-dom'
import { type z } from 'zod'
import { type ScenarioAPISchema, type VoiceAPISchema } from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import { Field, Select, TextareaField } from '#src/components/forms'
import Markdown from '#src/components/markdown'
import { PersonaAvatar } from '#src/components/persona'
import { TrainingAgenda } from '#src/components/training-agenda'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { StatusButton } from '#src/components/ui/status-button'
import { TabContent, Tabs, TabsList, TabTrigger } from '#src/components/ui/tabs'
import {
	COMMUNICATION_STYLE_OPTIONS,
	DECISION_MAKING_PROCESS_OPTIONS,
	EMOTIONAL_STATE_OPTIONS,
	PERSONALITY_TRAIT_OPTIONS,
} from '#src/constants/options'
import { usePersona } from '#src/hooks/usePersona'
import { useScenarioTemplate } from '#src/hooks/useScenarioTemplate'
import { useVoice } from '#src/hooks/useVoice'
import { type ActionRes } from '#src/routes/enable/roleplay-scenarios/actions/item'
import { getActiveScenarioTabSchema } from '#src/routes/enable/roleplay-scenarios/schema'
import { cn, getVoiceNameById, toSentenceCase, useIsPending } from '#src/utils/misc'

const ScenarioProperty = ({
	label,
	children,
	className,
	isEditable,
}: {
	label: string
	value?: string
	children?: ReactNode
	className?: string
	isEditable: boolean
}) => {
	return (
		<section className={cn('flex flex-row flex-nowrap py-3.5', className)}>
			<h3 className={cn('w-1/6 shrink-0 pe-2 text-label-md text-neutral-3-fg', isEditable && 'mt-0.5')}>{label}</h3>
			<div className="w-full text-body-md">{children}</div>
		</section>
	)
}

type ScenarioTabsProps = {
	scenario: z.infer<typeof ScenarioAPISchema>
	voices: z.infer<typeof VoiceAPISchema>[]
}

export const ScenarioTabs = ({ scenario, voices }: ScenarioTabsProps) => {
	const actionData = useActionData() as ActionRes
	const { getPersonasOptions } = usePersona()
	const { voiceOptions } = useVoice()
	const { getAgendaOptions, getTemplateById } = useScenarioTemplate()

	const [activeTab, setActiveTab] = useState('scenario')
	const activeSchema = getActiveScenarioTabSchema(activeTab)
	const isPending = useIsPending({ formMethod: 'PATCH' })

	const formId = `${activeTab}-edit-roleplay-scenario`
	const method = 'PATCH'

	const initialVal = structuredClone({
		name: scenario.name,
		templateId: scenario.templateId,
		contactId: scenario.contact,
		personaId: scenario.personaId,
		personality: scenario.personality,
		instructions: scenario.instructions,
		scenario: scenario.scenario,
		evaluation: scenario.evaluation,
		linkedinUrl: scenario.linkedinUrl,
	})

	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(activeSchema),
		defaultValue: initialVal,
		onValidate: ({ formData }) => parseWithZod(formData, { schema: activeSchema }),
		shouldValidate: 'onSubmit',
		shouldRevalidate: 'onInput',
		lastResult: actionData && 'result' in actionData ? actionData.result : null,
	})

	const personalityFields = fields.personality.getFieldset()
	const isEditable = !scenario.sessions.length

	const scenarioField = useInputControl(fields.scenario as FieldMetadata<string>)
	const evaluationField = useInputControl(fields.evaluation as FieldMetadata<string>)

	const handleOnAgendaChange = () => {
		const template = getTemplateById(fields.templateId.value as string)

		if (template) {
			scenarioField.change(template.scenario as string)
			evaluationField.change(template.evaluation)
		}
	}

	return (
		<Form {...getFormProps(form)} method={method}>
			<input type="hidden" name="activeTab" value={activeTab} />
			<input type="hidden" name="fullData" value={JSON.stringify(initialVal)} />
			<Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
				<TabsList>
					<TabTrigger value="scenario">Scenario</TabTrigger>
					<TabTrigger value="persona-mapping">Persona Mapping</TabTrigger>
					<TabTrigger value="personality">Personality</TabTrigger>
					<TabTrigger value="evaluation">Evaluation</TabTrigger>
					<TabTrigger value="instructions">Instructions</TabTrigger>
				</TabsList>
				<TabContent value="scenario" className="p-0">
					<section className="flex flex-col pb-0 pt-6">
						<ScenarioProperty label="Name" isEditable={isEditable}>
							{isEditable ? (
								<Field
									inputProps={{
										...getInputProps(fields.name, { type: 'text' }),
										placeholder: 'Enter scenario name',
									}}
									errors={fields.name.errors}
								/>
							) : (
								scenario.name
							)}
						</ScenarioProperty>
						<ScenarioProperty label="Agenda" isEditable={isEditable}>
							{isEditable ? (
								<Select
									inputProps={{
										contentProps: {
											className: 'overflow-y-auto max-h-[20rem]',
										},
										className: 'h-12',
										...getSelectProps(fields.templateId),
										defaultValue: fields.templateId.initialValue?.toString(),
										value: fields.templateId.value?.toString(),
										placeholder: '-',
										onValueChange: handleOnAgendaChange,
									}}
									options={getAgendaOptions()}
									errors={fields.templateId.errors}
								/>
							) : (
								<TrainingAgenda title={scenario.title ?? ''} titleClassName="font-normal" icon="user-service-desk" />
							)}
						</ScenarioProperty>
						<ScenarioProperty label="Scenario tuning" isEditable={isEditable}>
							{isEditable ? (
								<TextareaField
									textareaProps={{
										...getTextareaProps(fields.scenario),
										placeholder: 'Enter details',
										rows: 2,
									}}
									errors={fields.scenario.errors}
								/>
							) : (
								<Markdown>{scenario.scenario}</Markdown>
							)}
						</ScenarioProperty>
					</section>
				</TabContent>
				<TabContent value="persona-mapping" className="p-0">
					<section className="flex flex-col pb-0 pt-6">
						{scenario.personaMapping ? (
							<>
								<ScenarioProperty label="Ecosystem" isEditable={isEditable}>
									{scenario.personaMapping.ecosystem}
								</ScenarioProperty>
								<ScenarioProperty label="Vertical" isEditable={isEditable}>
									{scenario.personaMapping.vertical}
								</ScenarioProperty>
								<ScenarioProperty label="Assigned persona" isEditable={isEditable}>
									{isEditable ? (
										<Select
											inputProps={{
												contentProps: {
													position: 'popper',
													className: 'overflow-y-auto max-h-[20rem]',
												},
												className: 'h-12',
												...getSelectProps(fields.personaId),
												defaultValue: fields.personaId.initialValue,
												value: fields.personaId.value,
												placeholder: '-',
											}}
											options={getPersonasOptions(scenario.personaMapping.ecosystem, scenario.personaMapping.vertical)}
											errors={fields.personaId.errors}
										/>
									) : (
										<div className="grid grid-cols-[max-content,1fr] items-center gap-x-2">
											<PersonaAvatar
												type={scenario.personaMapping.personaType ?? ''}
												size="sm"
												className="row-span-2"
											/>
											<div>
												<p className="w-full truncate text-label-sm text-neutral-3-fg">
													{scenario.personaMapping.expertise}
												</p>
												<p className="w-full truncate text-body-sm font-semibold text-neutral-1-fg">
													{scenario.personaMapping.personaType}
												</p>
											</div>
										</div>
									)}
								</ScenarioProperty>
								<ScenarioProperty label="Linkedin URL" isEditable={isEditable}>
									{isEditable ? (
										<Field
											inputProps={{
												...getInputProps(fields.linkedinUrl, { type: 'text' }),
												placeholder: 'Enter LinkedIn URL',
												readOnly: isPending,
											}}
											errors={fields.linkedinUrl.errors}
											isLoading={isPending}
										/>
									) : (
										scenario.linkedinUrl && (
											<Button asChild size="sm" variant="ghost" className="cursor-pointer gap-1 p-0">
												<a href={scenario.linkedinUrl} target="__blank">
													{scenario.linkedinUrl}
												</a>
											</Button>
										)
									)}
								</ScenarioProperty>
							</>
						) : (
							<p className="text-body-sm text-neutral-3-fg">Persona has been deleted</p>
						)}
					</section>
				</TabContent>
				<TabContent value="personality" className="p-0">
					<section className="flex flex-col pb-0 pt-6">
						<ScenarioProperty label="Personality traits" isEditable={isEditable}>
							{isEditable ? (
								<Select
									inputProps={{
										...getSelectProps(personalityFields.personalityTrait),
										defaultValue: personalityFields.personalityTrait.initialValue,
										value: personalityFields.personalityTrait.value,
										placeholder: '-',
									}}
									options={PERSONALITY_TRAIT_OPTIONS}
									errors={personalityFields.personalityTrait.errors}
								/>
							) : (
								toSentenceCase(scenario.personality.personalityTrait)
							)}
						</ScenarioProperty>
						<ScenarioProperty label="Emotional states" isEditable={isEditable}>
							{isEditable ? (
								<Select
									inputProps={{
										...getSelectProps(personalityFields.emotionalState),
										defaultValue: personalityFields.emotionalState.initialValue,
										value: personalityFields.emotionalState.value,
										placeholder: '-',
									}}
									options={EMOTIONAL_STATE_OPTIONS}
									errors={personalityFields.emotionalState.errors}
								/>
							) : (
								toSentenceCase(scenario.personality.personalityTrait)
							)}
						</ScenarioProperty>
						<ScenarioProperty label="Communication styles" isEditable={isEditable}>
							{isEditable ? (
								<Select
									inputProps={{
										...getSelectProps(personalityFields.communicationStyle),
										defaultValue: personalityFields.communicationStyle.initialValue,
										value: personalityFields.communicationStyle.value,
										placeholder: '-',
									}}
									options={COMMUNICATION_STYLE_OPTIONS}
									errors={personalityFields.communicationStyle.errors}
								/>
							) : (
								toSentenceCase(scenario.personality.communicationStyle)
							)}
						</ScenarioProperty>
						<ScenarioProperty label="Decision making process" isEditable={isEditable}>
							{isEditable ? (
								<Select
									inputProps={{
										...getSelectProps(personalityFields.decisionMakingProcess),
										defaultValue: personalityFields.decisionMakingProcess.initialValue,
										value: personalityFields.decisionMakingProcess.value,
										placeholder: '-',
									}}
									options={DECISION_MAKING_PROCESS_OPTIONS}
									errors={personalityFields.decisionMakingProcess.errors}
								/>
							) : (
								toSentenceCase(scenario.personality.decisionMakingProcess)
							)}
						</ScenarioProperty>
						<ScenarioProperty label="Voice" isEditable={isEditable}>
							{isEditable ? (
								<Select
									inputProps={{
										...getSelectProps(personalityFields.voice),
										defaultValue: personalityFields.voice.initialValue,
										value: personalityFields.voice.value,
										placeholder: '-',
									}}
									options={voiceOptions}
									errors={personalityFields.voice.errors}
								/>
							) : (
								getVoiceNameById(voices, scenario.personality.voice)
							)}
						</ScenarioProperty>
					</section>
				</TabContent>
				<TabContent value="evaluation" className="p-0">
					<section className="flex flex-col pb-0 pt-6">
						<ScenarioProperty label="Evaluation criteria" isEditable={isEditable}>
							{isEditable ? (
								<TextareaField
									textareaProps={{
										...getTextareaProps(fields.evaluation),
										placeholder: 'Enter evaluation criteria',
										rows: 6,
									}}
									errors={fields.evaluation.errors}
								/>
							) : (
								<Markdown>{scenario.evaluation}</Markdown>
							)}
						</ScenarioProperty>
					</section>
				</TabContent>
				<TabContent value="instructions" className="p-0">
					<section className="flex flex-col pb-0 pt-6">
						<ScenarioProperty label="Instructions" isEditable={isEditable}>
							{isEditable ? (
								<TextareaField
									textareaProps={{
										...getTextareaProps(fields.instructions),
										placeholder: 'Enter instructions',
										rows: 6,
									}}
									errors={fields.instructions.errors}
								/>
							) : (
								<Markdown>{scenario.instructions}</Markdown>
							)}
						</ScenarioProperty>
					</section>
				</TabContent>
			</Tabs>
			{isEditable && (
				<section className="flex space-y-3">
					<div className="w-1/6 shrink-0" />
					<StatusButton status="idle" type="submit" size="md" disabled={isPending}>
						Save Changes
					</StatusButton>
				</section>
			)}

			{isEditable && (
				<div className="mt-6 flex w-full items-center gap-2 rounded bg-neutral-2-bg p-2 text-body-sm text-neutral-2-fg">
					<Icon name="information" size="sm" />
					Editing options are available as long as no participants have been invited
				</div>
			)}
		</Form>
	)
}
