import { Form, useLocation } from 'react-router-dom'
import { Dialog } from '#src/components/ui/dialog'
import useCompany from '#src/hooks/useCompany'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const PublishWarnModal = () => {
	const { companyId } = useCompany()
	const { state } = useLocation() as { state: { redirectPath: string } }

	const formId = 'publishWarnValuePropositionForm'
	const method = 'POST'
	const action = routes.calibrate.valueProposition.publish({ companyId })
	const isPending = useIsPending({ formAction: action, formMethod: method })
	const redirectPath = state?.redirectPath
	const baseUrl = window.location.origin
	const closeTo = `${baseUrl}${state?.redirectPath}`

	return (
		<Dialog
			open={true}
			dialogHeading="Publish before leaving?"
			closeTo={closeTo ?? routes.calibrate.valueProposition.index({ companyId })}
			actions={[
				{
					label: 'Leave',
					type: 'cancel',
					to: redirectPath,
				},
				{
					label: 'Yes, Publish',
					type: 'submit',
					formId,
				},
			]}
			isSubmitting={isPending}
		>
			<Form id={formId} action={action} method={method}>
				The value proposition will not be active until it’s published
			</Form>
		</Dialog>
	)
}
