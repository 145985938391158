import { queryOptions } from '@tanstack/react-query'
import { type z } from 'zod'
import { type SessionAPISchema } from '#src/api/icp/company/chat/roleplay/session/schemas'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { companyChatArchiveEndpoints } from './endpoints'
import { RoleplayArchiveListAPISchema, RoleplayArchiveItemAPISchema } from './schemas'

const keys = {
	all: ['icp', 'company', 'chat', 'archive'] as const,
	list: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		conversationId: z.infer<typeof SessionAPISchema.shape.conversation.shape.id>,
	) => [...keys.all, 'list', companyId, conversationId] as const,
	item: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		conversationId: z.infer<typeof SessionAPISchema.shape.conversation.shape.id>,
		archiveId: z.infer<typeof RoleplayArchiveItemAPISchema.shape.id>,
	) => [...keys.all, 'item', companyId, conversationId, archiveId] as const,
}

const listQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	conversationId: z.infer<typeof SessionAPISchema.shape.conversation.shape.id>,
) =>
	queryOptions({
		queryKey: keys.list(companyId, conversationId),
		queryFn: async () =>
			await safeFetch(RoleplayArchiveListAPISchema, companyChatArchiveEndpoints.list(companyId, conversationId), {
				method: 'GET',
			}),
		staleTime: 5000,
	})

const itemQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	conversationId: z.infer<typeof SessionAPISchema.shape.conversation.shape.id>,
	archiveId: z.infer<typeof RoleplayArchiveItemAPISchema.shape.id>,
) =>
	queryOptions({
		queryKey: keys.item(companyId, conversationId, archiveId),
		queryFn: async () =>
			await safeFetch(
				RoleplayArchiveItemAPISchema,
				companyChatArchiveEndpoints.item(companyId, conversationId, archiveId),
				{
					method: 'GET',
				},
			),
		staleTime: ({ state: { data } }) => (data?.evaluation?.status === 'Completed' ? Infinity : 0),
	})

const queries = {
	list: listQuery,
	item: itemQuery,
}

export { queries as companyChatArchiveQueries, keys as companyChatArchiveQueriesKeys }
