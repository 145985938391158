import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { type TooltipProps } from '@radix-ui/react-tooltip'
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from 'react'

import { cn } from '#src/utils/misc'

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = forwardRef<
	ElementRef<typeof TooltipPrimitive.Content>,
	ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, children, ...props }, ref) => (
	<TooltipPrimitive.Portal>
		<TooltipPrimitive.Content
			ref={ref}
			sideOffset={sideOffset}
			className={cn(
				'z-50 overflow-hidden rounded-sm bg-neutral-2-fg px-3 py-2 text-body-sm text-neutral-inverse-fg zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
				className,
			)}
			{...props}
		>
			{children}
			<TooltipPrimitive.Arrow className="fill-neutral-2-fg" />
		</TooltipPrimitive.Content>
	</TooltipPrimitive.Portal>
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

const CustomTooltip = ({
	children,
	label,
	delayDuration = 0,
	side = 'top',
	align = 'center',
	rootProps,
	noChildrenWrapper,
	sideOffset,
}: {
	rootProps?: TooltipProps
	children: ReactNode
	label: ReactNode
	delayDuration?: number
	side?: 'top' | 'right' | 'bottom' | 'left'
	align?: 'start' | 'center' | 'end'
	noChildrenWrapper?: boolean
	sideOffset?: number
}) => (
	<TooltipProvider delayDuration={delayDuration}>
		<Tooltip {...rootProps}>
			<TooltipTrigger asChild>{noChildrenWrapper ? children : <span>{children}</span>}</TooltipTrigger>
			<TooltipContent side={side} align={align} sideOffset={sideOffset}>
				{label}
			</TooltipContent>
		</Tooltip>
	</TooltipProvider>
)

export { CustomTooltip, Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }
