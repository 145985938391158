import { type z } from 'zod'
import { type SessionAPISchema } from '#src/api/icp/company/chat/roleplay/session/schemas'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'
import { type RoleplayArchiveItemAPISchema } from './schemas'

const base = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	conversationId: z.infer<typeof SessionAPISchema.shape.conversation.shape.id>,
) => `${baseApi}/company/${companyId}/chat/archive/${conversationId}`

const endpoints = {
	list: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		conversationId: z.infer<typeof SessionAPISchema.shape.conversation.shape.id>,
	) => `${base(companyId, conversationId)}/list`,
	item: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		conversationId: z.infer<typeof SessionAPISchema.shape.conversation.shape.id>,
		archiveId: z.infer<typeof RoleplayArchiveItemAPISchema.shape.id>,
	) => `${base(companyId, conversationId)}/${archiveId}`,
}

export { endpoints as companyChatArchiveEndpoints }
