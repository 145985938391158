import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import { type ActionFunctionArgs, useFetcher } from 'react-router-dom'
import { Button } from '#src/components/ui/button'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { cn, validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { recalculateSalesCoach } from './mutations'
import { salesCoachKeys } from './queries'

export const action = async ({ params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId', 'personaId', 'salescoachId'])
	const { company } = await getCompany(params)

	await recalculateSalesCoach(company.id, params.personaId, params.salescoachId)

	await client.invalidateQueries({
		queryKey: salesCoachKeys.list(company.id, params.personaId),
	})

	return null
}

export interface RecalculateVerticalsAsyncFormProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystemId: string
	verticalId: string
	personaId: string
	generalSalescoachId: string | undefined
	timingSalescoachId: string | undefined
	children: ReactNode
}

export const RecalculateSalesCoachAsyncForm = forwardRef<HTMLButtonElement, RecalculateVerticalsAsyncFormProps>(
	(
		{ ecosystemId, verticalId, personaId, generalSalescoachId, timingSalescoachId, children, className, ...props },
		ref,
	) => {
		const { companyId } = useCompany()
		const fetcher = useFetcher()
		const action = (salescoachId: string) =>
			routes.calibrate.persona.coach.recalculate({
				companyId,
				ecosystemId: ecosystemId,
				verticalId: verticalId,
				personaId: personaId,
				salesCoachId: salescoachId,
			})

		return (
			<Button
				variant="outline"
				{...props}
				ref={ref}
				type="button"
				disabled={fetcher.state !== 'idle'}
				className={cn('w-full', fetcher.state !== 'idle' ? 'animate-pulse' : '', className)}
				onClick={() => {
					if (generalSalescoachId)
						fetcher.submit(null, {
							method: 'PUT',
							action: action(generalSalescoachId),
						})
					if (timingSalescoachId)
						fetcher.submit(null, {
							method: 'PUT',
							action: action(timingSalescoachId),
						})
				}}
			>
				{children}
			</Button>
		)
	},
)
RecalculateSalesCoachAsyncForm.displayName = 'RecalculateSalesCoachAsyncForm'
