import { type LoaderFunctionArgs } from 'react-router-dom'
import { orgDataEnrichmentWorkflowsQueries } from '#src/api/organise/data-enrichment/workflows/queries'
import { orgListsQueries } from '#src/api/organise/lists/queries'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	let lraStats = {
		launched: 0,
		completed: 0,
	}

	let cwStats = {
		launched: 0,
		completed: 0,
	}

	const [lraResponse, cwResponse] = await Promise.allSettled([
		client.fetchQuery(orgListsQueries.statistics(company.id)),
		client.fetchQuery(orgDataEnrichmentWorkflowsQueries.statistics(company.id)),
	])
	if (lraResponse.status === 'fulfilled')
		lraStats = {
			launched: lraResponse.value.inProgressCount + lraResponse.value.inQueueCount,
			completed: lraResponse.value.completedCount,
		}
	if (cwResponse.status === 'fulfilled')
		cwStats = {
			launched: cwResponse.value.inProgressCount + cwResponse.value.inQueueCount,
			completed: cwResponse.value.completedCount,
		}

	if (lraResponse.status === 'rejected' || cwResponse.status === 'rejected')
		showToast({
			message: `Failed to load statistics`,
			type: 'error',
		})

	return {
		data: {
			lra: lraStats,
			cw: cwStats,
		},
	}
}
