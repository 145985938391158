import { type z } from 'zod'
import {
	CreateSessionAPIResSchema,
	SessionDataSchema,
	type SessionFormPayloadSchema,
} from '#src/routes/enable/roleplay-sessions/schema'
import { api } from '#src/utils/endpoints'
import { fetch, safeFetch } from '#src/utils/safeFetch'

export const createRoleplaySession = async (companyId: string, data: z.infer<typeof SessionFormPayloadSchema>) => {
	return await safeFetch(CreateSessionAPIResSchema, api.roleplay.session.new(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}

export const endRoleplaySession = async (companyId: string, conversationId: string) => {
	return await fetch(api.roleplay.session.end(companyId, conversationId), {
		method: 'PUT',
	})
}

export const restartRoleplaySession = async (companyId: string, conversationId: string) => {
	return await safeFetch(SessionDataSchema, api.roleplay.session.restart(companyId, conversationId), {
		method: 'PUT',
	})
}

export const deleteRoleplaySession = async (companyId: string, conversationId: string) => {
	return await fetch(api.roleplay.session.index(companyId, conversationId), {
		method: 'DELETE',
	})
}
