import { Link } from 'react-router-dom'
import { type z } from 'zod'
import ProductTip from '#src/components/product-tip'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { type PersonaAPISchema } from '#src/routes/calibrate/personas/schema'
import Aside from '#src/routes/calibrate/personas/views/components/Aside'
import { type PersonasSchema } from '#src/routes/enable/personas/schema'
import { routes } from '#src/utils/routes'

type Props = {
	persona: z.infer<typeof PersonaAPISchema>
	reportsToPersona: z.infer<typeof PersonasSchema> | undefined
}

const PersonaSetupCard = (props: Props) => {
	const { persona, reportsToPersona } = props
	const { companyId } = useCompany()
	const { readOnlySession } = useAuth()

	return (
		<Aside
			title={
				<div className="flex items-center justify-between">
					Setup info
					{!readOnlySession && (
						<Link
							to={routes.calibrate.persona.setup({
								companyId,
								ecosystemId: String(persona.ecosystem.id),
								verticalId: String(persona.vertical.id),
								personaId: String(persona.id),
							})}
							className="text-button-sm text-link hover:text-link-hover active:text-link-pressed"
						>
							Edit
						</Link>
					)}
				</div>
			}
			list={[
				{
					label: (
						<div className="flex items-center gap-1">
							Expertise
							<ProductTip content="Expertise: Define the primary area of expertise relevant to the persona (e.g., Revenue)." />
						</div>
					),
					value: persona.expertise,
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Job Experience
							<ProductTip content="Job Experience: Indicate the required years of experience (e.g., +7 years)." />
						</div>
					),
					value: persona.jobExperience,
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Job Titles
							<ProductTip content="Job Title: Provide examples of relevant job titles (e.g., CRO, Chief, President, SVP, VP, Director of Revenue or Sales)." />
						</div>
					),
					value: persona.jobTitles,
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Job Seniority
							<ProductTip content="Job Seniority: Specify the seniority level (e.g., Chief, President, SVP, VP, Director)." />
						</div>
					),
					value: persona.jobSeniority,
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Reports To
							<ProductTip content="Reports To: Specify the reporting structure (e.g., CEO)." />
						</div>
					),
					value: reportsToPersona ? String(reportsToPersona.jobTitles ?? persona.reportsTo?.name ?? '-') : '-',
				},
				{
					label: 'Reports To (manual)',
					value: persona.reportsOverride ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Types of Tasks
							<ProductTip content="Types of Tasks: Describe key responsibilities and tasks undertaken by the persona (e.g., Implementing account-based methodologies)." />
						</div>
					),
					value: persona.typeOfTask,
				},
				{
					label: 'Pain (manual)',
					value: persona.painOverride ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Pain Length (# of words)
							<ProductTip content="Pain Length (# of words): Set the word count for describing pain points." />
						</div>
					),
					value: persona.painLength,
				},
			]}
		/>
	)
}

export default PersonaSetupCard
