import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { type z } from 'zod'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/verticals/criteria/constants'
import { assignCriterion } from '#src/routes/calibrate/verticals/criteria/mutations'
import { criteriaKeys } from '#src/routes/calibrate/verticals/criteria/queries'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type ActionResType = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(type: z.infer<typeof CRITERION_TYPE_ENUM>) =>
	async ({ params }: ActionFunctionArgs) => {
		validateRouteParams(params, ['ecosystemId', 'criterionId'])
		const { company, companyId } = await getCompany(params)

		try {
			await assignCriterion(company.id, {
				criterionId: params.criterionId,
				ecosystemId: params.ecosystemId,
			})

			showToast({
				message: 'Criterion successfully created and assigned',
				type: 'success',
				bottom: '60px',
			})

			await client.invalidateQueries({ queryKey: criteriaKeys.all })
		} catch (err) {
			captureException(err)
			showToast({
				message: 'Unexpected error while assigning criterion',
				type: 'error',
			})
		}

		return redirect(
			routes.calibrate.criteria.editAnswers({
				companyId,
				ecosystemId: params.ecosystemId,
				criterionType: type,
			}),
		)
	}
