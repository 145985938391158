import { type z } from 'zod'
import { type CompanyFormSchema } from '#src/api/icp/company/base/schema'
import { client } from '#src/main'
import { companyKeys } from '#src/routes/company/queries'
import { type CompanySchema } from '#src/routes/company/schema'
import { fetch } from '#src/utils/safeFetch'
import { companyEndpoints } from './endpoints'

const mutations = {
	update: async (companyId: z.infer<typeof CompanySchema>['id'], data: z.infer<typeof CompanyFormSchema>) => {
		await fetch(companyEndpoints.update(companyId), {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.refetchQueries({
			queryKey: companyKeys.all,
		})
	},
}

export { mutations as companyMutations }
