import { useFetcher, useNavigate } from 'react-router-dom'
import { DeleteConfirmContent, DeleteConfirmFooter } from '#src/components/delete-confirm'
import { Dialog } from '#src/components/ui/dialog'
import useCompany from '#src/hooks/useCompany'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

const View = () => {
	const params = useParsedRouteParams(['conversationId'])
	const { companyId } = useCompany()
	const navigate = useNavigate()
	const fetcher = useFetcher()

	const action = routes.enable.roleplay.session.delete({
		companyId,
		conversationId: params.conversationId,
	})
	const isPending = useIsPending({
		formAction: action,
		formMethod: 'DELETE',
	})

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => {
				if (!isOpen && !isPending) {
					navigate(-1)
				}
			}}
			isSubmitting={isPending}
			footer={<DeleteConfirmFooter action={action} fetcher={fetcher} />}
		>
			<DeleteConfirmContent
				title="Delete chat?"
				description="This action cannot be undone. This will permanently delete the chat and remove any associated data such as messages."
			/>
		</Dialog>
	)
}

export default View
