import { useQuery } from '@tanstack/react-query'
import { useLoaderData } from 'react-router-dom'
import { companyChatArchiveQueries } from '#src/api/icp/company/chat/archive/queries'
import { EmptyStateCard } from '#src/components'
import Markdown from '#src/components/markdown.js'
import { PageLayout } from '#src/components/ui/PageLayout'
import { Tabs, TabsList, TabTrigger, TabContent } from '#src/components/ui/tabs'
import useCompany from '#src/hooks/useCompany'
import { type LoaderRes } from '#src/routes/enable/roleplay-sessions/loaders/review'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { ChatLoader } from './components/ChatLoader'

export default function RoleplayReview() {
	const { session, attempt } = useLoaderData() as LoaderRes
	const { companyId } = useCompany()

	return (
		<PageLayout title={`Session review: ${session.conversation.title ?? 'Untitled'}`}>
			{attempt.messageCount ? (
				<Tabs defaultValue="evaluation">
					<TabsList>
						<TabTrigger value="evaluation">Evaluation</TabTrigger>
						<TabTrigger value="transcript">Transcript</TabTrigger>
					</TabsList>
					<TabContent value="evaluation" className="mx-auto max-w-screen-md">
						<RoleplayEvaluation />
					</TabContent>
					<TabContent value="transcript" className="mx-auto max-w-screen-md">
						<RoleplayTranscript />
					</TabContent>
				</Tabs>
			) : (
				<EmptyStateCard
					icon="user-service-desk"
					title="This roleplay session does not have any messages"
					actions={[
						{
							noIcon: true,
							title: 'Back to home',
							to: routes.enable.roleplay.index({
								companyId,
							}),
						},
					]}
				/>
			)}
		</PageLayout>
	)
}

const RoleplayEvaluation = () => {
	const { attempt: attemptInitial, session } = useLoaderData() as LoaderRes
	const { company } = useCompany()

	const { data: attempt } = useQuery({
		...companyChatArchiveQueries.item(company.id, session.conversation.id, attemptInitial.id),
		initialData: attemptInitial,
		refetchInterval: ({ state: { data } }) => (data?.evaluation?.status === 'Completed' ? 0 : 3000),
	})

	switch (attempt.evaluation?.status) {
		case 'Crashed':
			return <Markdown className="text-body-md text-status-danger-fg">Something went wrong with evaluation.</Markdown>
		case 'Completed':
			return <Markdown className="text-body-md">{attempt.evaluation?.value}</Markdown>
		default:
			return (
				<ChatLoader
					animate={true}
					message="Generating results..."
					subMessage="It might take up to 3 minutes or more"
					className="h-full min-h-[600px] w-full gap-4 overflow-hidden"
				/>
			)
	}
}

const RoleplayTranscript = () => {
	const {
		attempt: { messages },
	} = useLoaderData() as LoaderRes

	let lastEnumeration = 1
	const enumeratedMessages = messages.map(message => ({
		...message,
		enumeration: message.isAi ? null : lastEnumeration++,
	}))

	return (
		<div className="flex flex-col items-start gap-6">
			{enumeratedMessages.map(({ id, message, isAi, enumeration }) => (
				<div
					key={id}
					className={cn(
						'w-full text-body-lg',
						isAi ? 'rounded-lg bg-brand-1-bg p-4 text-brand-1-fg' : 'text-neutral-2-fg',
					)}
				>
					{!isAi ? `${enumeration}. ${message}` : message}
				</div>
			))}
		</div>
	)
}
