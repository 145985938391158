import { type ReactNode } from 'react'
import ProductTip from '#src/components/product-tip'
import { Icon, type IconName } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'
import { cn } from '#src/utils/misc'

type IconVariant = 'success' | 'warning' | 'danger'

export type ItemStatisticsCardProps = {
	icon: IconName
	iconVariant?: IconVariant
	name: string
	tip?: string
	children: ReactNode
}

const ICON_COLOR_VARIANTS: Record<IconVariant, string> = {
	success: 'text-status-success-fg',
	warning: 'text-status-warning-fg',
	danger: 'text-status-danger-fg',
} as const

export const ItemStatisticsCard = (props: ItemStatisticsCardProps) => {
	const { icon, iconVariant, name, tip, children } = props
	return (
		<Surface className="flex h-full w-full flex-col gap-4 p-6">
			<div
				className={cn(
					'flex flex-row items-center gap-1.5 text-neutral-2-fg',
					iconVariant && ICON_COLOR_VARIANTS[iconVariant],
				)}
			>
				<Icon
					name={icon}
					size="md"
					className={cn(
						'self-start text-neutral-3-fg',
						iconVariant && ICON_COLOR_VARIANTS[iconVariant],
						icon.includes('loading') && 'animate-spin',
					)}
				/>
				<div className="flex flex-row gap-1">
					<h2 className="text-label-lg">{name}</h2>
					{!!tip && <ProductTip className="text-neutral-3-fg" content={tip} />}
				</div>
			</div>
			{children}
		</Surface>
	)
}
