import { useLoaderData } from 'react-router-dom'
import { Surface } from '#src/components/ui/surface'
import { EditCompanyDetailsForm } from '#src/routes/admin/views/components/EditCompanyDetailsForm'
import { EditCompanyForm } from '#src/routes/admin/views/components/EditCompanyForm'
import { type LoaderRes } from '../loaders/edit'

export default function EditCompanyDashboard() {
	const { company } = useLoaderData() as LoaderRes

	return (
		<Surface className="mx-auto flex w-full max-w-screen-md flex-col items-center gap-12 px-20 py-10">
			<EditCompanyForm company={company} />
			<hr className="w-full border-t border-neutral-1-bd" />
			<EditCompanyDetailsForm company={company} />
		</Surface>
	)
}
