export enum EXPORT_TYPE {
	Full = 'full',
	ICPIBPFull = 'icp_ibp_full',
	ICPIBPFullWithDetails = 'icp_ibp_full_with_details',
	Contacts = 'contacts',
	Accounts = 'accounts',
	ICPAccounts = 'icp_accounts',
	IBPContacts = 'ibp_accounts',
	IBPContactsWithDetails = 'ibp_accounts_with_details',
}
