import { getFormProps, getInputProps, getTextareaProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { Form, Link, type LoaderFunctionArgs, useLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { CheckboxField, ErrorList, TextareaField } from '#src/components/forms'
import { Button } from '#src/components/ui/button'
import { Sidebar, SidebarFooter, SidebarHeader } from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import { client } from '#src/main'
import { useIsPending, validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { personaQuery } from './queries'
import { type PersonaAPISchema, PersonaTuneFormSchema } from './schema'

export type PersonaSetupLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId', 'verticalId', 'personaId'])
	const { company, companyId } = await getCompany(params)

	const persona = await client.fetchQuery(personaQuery(company.id, params.personaId))

	if (!persona)
		throw new Response('Missing parameters', {
			status: 400,
			statusText: 'Bad Request',
		})

	return {
		companyId,
		ecosystemId: params.ecosystemId,
		verticalId: params.verticalId,
		personaId: params.personaId,
		persona,
	}
}

export default function PersonaSetup() {
	const { companyId, ecosystemId, verticalId, personaId, persona } = useLoaderData() as PersonaSetupLoaderResponse

	const action = routes.calibrate.persona.save({
		companyId: companyId,
		ecosystemId: ecosystemId,
		verticalId: verticalId,
		personaId: persona.id.toString(),
	})
	const method = 'PATCH'
	const formId = 'persona-setup-form-' + persona.id

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	return (
		<Sidebar
			header={
				<SidebarHeader
					heading="Fine tune AI"
					closeTo={routes.calibrate.persona.index({
						companyId: companyId,
						ecosystemId: ecosystemId,
						verticalId: verticalId,
						personaId: personaId,
					})}
				/>
			}
			main={<PersonaFineTuneForm formId={formId} action={action} persona={persona} />}
			footer={
				<SidebarFooter>
					<Button asChild variant="outline" size="sm">
						<Link
							to={routes.calibrate.persona.index({
								companyId: companyId,
								ecosystemId: ecosystemId,
								verticalId: verticalId,
								personaId: personaId,
							})}
						>
							Cancel
						</Link>
					</Button>
					<StatusButton
						status={isPending ? 'pending' : 'idle'}
						size="sm"
						type="submit"
						name="intent"
						value="persona"
						form={formId}
						disabled={isPending}
					>
						Save
					</StatusButton>
				</SidebarFooter>
			}
		/>
	)
}

function PersonaFineTuneForm({
	formId,
	action,
	persona,
}: {
	formId: string
	action: string
	persona: z.infer<typeof PersonaAPISchema>
}) {
	const method = 'PATCH'

	const defaultValue = {
		id: persona.id,
		aiFineTuning: {
			answerFormat:
				'Strictly answer with a list of 5 numbered bullet points ranked by importance. Avoid any form of nested lists or sub-bullet points to maintain clarity. Each bullet point should stand on its own and provide value independently, without relying on additional context from other points. Strictly answer with 45 to 55 words for each numbered bullet point. Strictly do not write the number of words or words counts in your answer.',
			directReport: null,
			specificComment: null,
			comments: null,
			...(persona?.aiFineTuning ?? {}),
		},
	}

	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(PersonaTuneFormSchema),
		defaultValue,
		onValidate({ formData }) {
			return parseWithZod(formData, {
				schema: PersonaTuneFormSchema,
			})
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})
	const aiFineTuning = fields.aiFineTuning.getFieldset()

	return (
		<Form method={method} {...getFormProps(form)} action={action} className="flex flex-col gap-4">
			<input {...getInputProps(fields.id, { type: 'hidden' })} defaultValue={fields.id.value} />

			<TextareaField
				labelProps={{
					children: 'AI Answer Format',
				}}
				textareaProps={{
					...getTextareaProps(aiFineTuning.answerFormat),
					rows: 8,
				}}
				errors={aiFineTuning.answerFormat.errors}
			/>
			<CheckboxField
				labelProps={{ children: 'Include Direct Report in the prompt?' }}
				buttonProps={{
					...getInputProps(aiFineTuning.directReport, {
						type: 'checkbox',
					}),
				}}
				errors={aiFineTuning.directReport.errors}
			/>
			<TextareaField
				labelProps={{
					children: 'Missing something? Add one more specific comment to the prompt',
				}}
				textareaProps={{
					...getTextareaProps(aiFineTuning.specificComment),
				}}
				errors={aiFineTuning.specificComment.errors}
			/>
			<TextareaField
				labelProps={{ children: 'Comments' }}
				textareaProps={{
					...getTextareaProps(aiFineTuning.comments),
				}}
				errors={aiFineTuning.comments.errors}
			/>

			<ErrorList errors={form.errors} id={form.errorId} />
		</Form>
	)
}
