import { type z } from 'zod'
import { type PersonaAPISchema } from '#src/routes/calibrate/personas/schema'
import { type VerticalAPISchema } from '#src/routes/calibrate/verticals/schema'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'

const base = (companyId: z.infer<typeof CompanySchema.shape.id>) => `${baseApi}/company/${companyId}/persona`

const endpoints = {
	signals: (companyId: z.infer<typeof CompanySchema.shape.id>, personaId: z.infer<typeof PersonaAPISchema.shape.id>) =>
		`${base(companyId)}/${personaId}/signals`,
	expertiseAndType: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		verticalIds: z.infer<typeof VerticalAPISchema.shape.id>[],
	) => {
		const query = new URLSearchParams({})
		verticalIds.forEach(value => {
			query.append('verticals[]', String(value))
		})
		return `${base(companyId)}/expertisetype?${new URLSearchParams(query).toString()}`
	},
}

export { endpoints as companyPersonaEndpoints }
