import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu'
import { TabsContent, TabsTrigger } from '@radix-ui/react-tabs'
import { type RefObject, useEffect, useState } from 'react'
import { Link, useLoaderData } from 'react-router-dom'
import { type ChatInputForwardProps } from '#src/components/chat/input'
import { Button } from '#src/components/ui/button'
import { Dropdown } from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import { Tabs, TabsList } from '#src/components/ui/tabs'
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from '#src/components/ui/tooltip'
import useCompany from '#src/hooks/useCompany'
import { type ChatLoaderResponse } from '#src/routes/enable/chat'
import { routes } from '#src/utils/routes'

type Props = {
	disabled: boolean
	inputRef: RefObject<ChatInputForwardProps>
}

export const ChatInputPresets = (props: Props) => {
	const { disabled, inputRef } = props
	const { plays, defaultCommands } = useLoaderData() as ChatLoaderResponse
	const { companyId } = useCompany()

	const [open, setOpen] = useState<boolean>(false)
	const [tooltip, setTooltip] = useState<boolean>(!sessionStorage.getItem('chat-presets-seen'))

	const onChange = (val: string) => {
		if (inputRef && inputRef.current) {
			inputRef.current.setValue?.(val)
			setOpen(false)
		}
	}

	useEffect(() => {
		if (tooltip) {
			sessionStorage.setItem('chat-presets-seen', 'true')
			setTimeout(() => {
				setTooltip(false)
			}, 5000)
		}
	}, [tooltip])

	return (
		<TooltipProvider>
			<Tooltip delayDuration={0} open={tooltip}>
				<TooltipTrigger asChild>
					<Dropdown
						open={open}
						trigger={
							<Button size="icon" variant="icon-interactive" type="button" disabled={disabled}>
								<Icon name="add-alt" size="sm" />
							</Button>
						}
						contentProps={{
							className: 'p-4 overflow-hidden w-[min(70vw,894px)]',
							align: 'start',
						}}
						onOpenChange={setOpen}
					>
						<DropdownPrimitive.Arrow className="mt-[-1px] fill-white" />
						<Tabs defaultValue="default-commands" className="w-full overflow-hidden">
							<TabsList className="mb-1">
								<TabsTrigger
									value="default-commands"
									className="mb-[-2px] border-b-2 border-solid border-transparent px-4 py-3 text-body-sm data-[state=active]:border-brand-1-bd data-[state=active]:font-bold"
								>
									Default commands
								</TabsTrigger>
								<TabsTrigger
									value="plays"
									className="mb-[-2px] border-b-2 border-solid border-transparent px-4 py-3 text-body-sm data-[state=active]:border-brand-1-bd data-[state=active]:font-bold"
								>
									Plays
								</TabsTrigger>
							</TabsList>
							<TabsContent
								value="default-commands"
								className="flex h-full w-full flex-col gap-1 overflow-y-auto text-body-md"
							>
								{(defaultCommands ?? []).map(item => (
									<button
										key={item.id}
										className="rounded px-2 py-1 text-left hover:bg-neutral-1-bg-hover"
										onClick={() => onChange(item.value)}
									>
										{item.title}
									</button>
								))}
							</TabsContent>
							<TabsContent value="plays" className="flex h-full w-full flex-col gap-1 overflow-y-auto text-body-md">
								{plays && plays.length ? (
									plays?.map(item => (
										<button
											key={item.id}
											className="rounded px-2 py-1 text-left hover:bg-neutral-1-bg-hover"
											onClick={() =>
												onChange(
													`Help me adjust the below template by replacing the part in brackets as per the instruction in between the brackets. Strictly only answer with the adjusted copy only.\n\n${item.instructions}`,
												)
											}
										>
											{item.name}
										</button>
									))
								) : (
									<p className="p-4">
										You don&apos;t have any plays yet. You can create them by clicking{' '}
										<Link to={routes.enable.plays.create({ companyId })} className="text-link">
											here
										</Link>
									</p>
								)}
							</TabsContent>
						</Tabs>
					</Dropdown>
				</TooltipTrigger>
				<TooltipContent side="top" align="center" className="max-w-[280px]">
					You can manage your command presets here
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}
