import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { companyChatRoleplayScenarioMutations } from '#src/api/icp/company/chat/roleplay/scenario/mutations'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['scenarioId'])

	if (request.method !== 'DELETE') {
		return { ok: false }
	}

	try {
		await companyChatRoleplayScenarioMutations.delete(company.id, params.scenarioId)

		showToast({
			message: 'Scenario deleted',
			type: 'success',
		})

		return redirect(
			routes.enable.roleplay.scenario.index({
				companyId,
			}),
		)
	} catch (error) {
		showToast({
			message: 'Failed to delete scenario',
			type: 'error',
		})
	}

	return { ok: false }
}
