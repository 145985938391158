import { z } from 'zod'
import {
	EcosystemAPIPersonaSchema,
	EcosystemAPISchema,
	EcosystemAPIVerticalSchema,
} from '#src/routes/calibrate/ecosystem/schema'

export const PersonaAIFineTuning = z.object({
	answerFormat: z.string(),
	directReport: z.boolean().optional(),
	specificComment: z.string().nullable().optional(),
})

export const PersonaMapping = z.object({
	responsibilities: z.string().nullable().optional(),
	kpis: z.string().nullable().optional(),
	frustrations: z.string().nullable().optional(),
	valuePropositions: z.string().nullable().optional(),
	objectionsConcerns: z.string().nullable().optional(),
	objectionAddressing: z.string().nullable().optional(),
	buyerJourneyInfo: z.string().nullable().optional(),
	buyerJourneyUseCases: z.string().nullable().optional(),
	gainInformationFrom: z.string().nullable().optional(),
	boolLinkedin: z.string().nullable().optional(),
})

export const PersonaAPISchema = z.object({
	id: z.number(),
	ecosystem: z.object({ id: z.number() }),
	vertical: z.object({ id: z.number(), name: z.string() }),
	reportsTo: z
		.object({
			id: z.number().nullable(),
			name: z.string().nullable(),
		})
		.nullable(),
	reportsOverride: z.string().nullable(),
	type: z.string(),
	status: z.string(),
	priority: z.string(),
	pain: z.string().nullable().optional(),
	painLength: z.number(),
	painOverride: z.string().nullable().optional(),
	expertise: z.string().nullable(),
	jobTitles: z.string().nullable(),
	jobSeniority: z.string().nullable(),
	jobExperience: z.string().nullable(),
	typeOfTask: z.string().nullable(),
	aiFineTuning: PersonaAIFineTuning.nullable(),
	coldEmail: z.string().nullable().optional(),
	personaMapping: PersonaMapping.nullable(),
	summary: z.string().nullable(),
	prompt: z.object({
		status: z.string().nullable(),
	}),
})

export const PersonaListAPISchema = z.array(PersonaAPISchema)

export const PersonaFormSchema = PersonaAPISchema.pick({
	id: true,
	type: true,
	status: true,
	priority: true,
	expertise: true,
	jobSeniority: true,
	jobExperience: true,
	typeOfTask: true,
	pain: true,
	painLength: true,
	painOverride: true,
	aiFineTuning: true,
}).merge(
	z.object({
		verticals: z.array(z.string()).refine(selection => selection.length > 0, {
			message: 'At least one vertical is required',
		}),
		reportsTo: z
			.number()
			.or(z.string())
			.nullable()
			.optional()
			.transform(v => {
				if (v === 'other' || v === '-') return null
				return v
			}),
		reportsOverride: z.string().nullable().optional(),
		jobTitles: z
			.string({
				required_error: 'Please fill Expertise and Job Seniority',
			})
			.nullable(),
	}),
)

export const PersonaEditFormSchema = PersonaFormSchema.omit({ id: true })

export const PersonaUpdateFormSchema = PersonaFormSchema.omit({
	verticals: true,
}).partial()

export const CopyValidatePersonaFormSchema = z.object({
	personas: z.string(),
})

export const DeletePersonasFormSchema = z.object({
	personas: z.string().transform(val => JSON.parse(val)),
})

export const CopyPersonaFormSchema = z.object({
	personas: z.string().transform(val => JSON.parse(val)),
	targetVerticals: z.string().transform(val => JSON.parse(val)),
})

export const CopyValidatePersonaPayloadSchema = CopyValidatePersonaFormSchema.extend({
	personas: z.array(z.string()),
})

export const CopyValidatePersonaAPIResSchema = z.array(
	EcosystemAPISchema.pick({
		id: true,
		name: true,
		verticals: true,
	}).extend({
		verticals: z.array(
			EcosystemAPIVerticalSchema.pick({
				id: true,
				name: true,
			}).extend({
				personas: z.array(
					EcosystemAPIPersonaSchema.pick({
						id: true,
						type: true,
						expertise: true,
					}),
				),
			}),
		),
	}),
)

export const PersonaSetupFormSchema = PersonaUpdateFormSchema.pick({
	id: true,
	reportsTo: true,
	reportsOverride: true,
	status: true,
	priority: true,
	type: true,
	expertise: true,
	jobTitles: true,
	jobSeniority: true,
	jobExperience: true,
	typeOfTask: true,
	pain: true,
	painLength: true,
	painOverride: true,
})

export const PersonaTuneFormSchema = PersonaUpdateFormSchema.pick({
	id: true,
	aiFineTuning: true,
})
