import { type ActionFunctionArgs } from 'react-router-dom'
import { adminCompanyMutations } from '#src/api/icp/admin/company/mutations'
import { AdminCompanyDashboardFormSchema } from '#src/api/icp/admin/company/schemas'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['companyId'])

	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, AdminCompanyDashboardFormSchema)
		await adminCompanyMutations.updateDashboard(params.companyId, submission.value)

		showToast({
			message: 'Company details successfully saved',
			type: 'success',
			duration: 2000,
		})

		return {
			ok: true,
		}
	} catch (error) {
		showToast({
			message: 'Unexpected error while saving company details',
			type: 'error',
		})

		return {
			ok: false,
		}
	}
}
