import { type z } from 'zod'

export const getValidSortKeys = (data: string[], schema: z.SomeZodObject): string[] => {
	const keys = Object.keys(schema.shape)
	const ascDescKeys: string[] = []
	keys.forEach(key => {
		if ('shape' in schema.shape[key]) {
			/**
			 * this case handles when we want to sort by inner object values f.e.: conversation.createdAt
			 * it's not recursive, but feel free to change when you will get into this case
			 * */
			const keys2 = Object.keys(schema.shape[key].shape as z.SomeZodObject)
			keys2.forEach(key2 => {
				ascDescKeys.push(`${key}.${key2}`)
				ascDescKeys.push(`-${key}.${key2}`)
			})
		} else {
			ascDescKeys.push(key)
			ascDescKeys.push(`-${key}`)
		}
	})

	return data.filter(item => ascDescKeys.includes(item))
}

export const paramsToObject = (params: URLSearchParams) => {
	const result: Record<string, string | string[]> = {}
	for (const [key, value] of params.entries()) {
		if (key in result) {
			if (Array.isArray(result[key])) {
				result[key] = [...result[key], value]
			} else {
				result[key] = [result[key], value]
			}
		} else {
			result[key] = value
		}
	}
	return result
}

export const paramsToObjectSafe = (params: URLSearchParams, schema: z.SomeZodObject) => {
	const keys = Object.keys(schema.shape)
	const result: Record<string, string | string[]> = {}
	for (const [key, value] of params.entries()) {
		if (keys.includes(key)) {
			if (key in result) {
				if (Array.isArray(result[key])) {
					result[key] = [...result[key], value]
				} else {
					result[key] = [result[key], value]
				}
			} else {
				result[key] = value
			}
		}
	}
	return result
}
