import * as ScrollArea from '@radix-ui/react-scroll-area'
import { type ChangeEvent, useMemo, useState } from 'react'
import { useNavigate, useLoaderData, Form } from 'react-router-dom'
import { type z } from 'zod'
import CheckboxTreeInput from '#src/components/forms/CheckboxTreeInput'
import { Dialog, type DialogButtonProps } from '#src/components/ui/dialog'
import { Icon } from '#src/components/ui/icon'
import { Input } from '#src/components/ui/input'
import { Surface } from '#src/components/ui/surface'
import { CustomTooltip } from '#src/components/ui/tooltip'
import useCompany from '#src/hooks/useCompany'
import { type CopyValidatePersonaAPIResSchema } from '#src/routes/calibrate/personas/schema'
import { type CopyPersonasLoaderLoaderType } from '#src/routes/calibrate/verticals/loaders/copyPersonasLoader'
import { cn, useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const CopyPersonasModal = () => {
	const { ecosystemsWithValidatedPersonas, selectedPersonasIds, ecosystemId } =
		useLoaderData() as CopyPersonasLoaderLoaderType
	const navigate = useNavigate()
	const [search, setSearch] = useState<string>('')
	const [data, setData] = useState(ecosystemsWithValidatedPersonas)
	const [selectedItems, setSelectedItems] = useState<string[]>([])
	const { companyId } = useCompany()

	const method = 'POST'
	const formId = 'copy-personas'

	const action = routes.calibrate.verticals.copyPersonas({
		companyId,
		ecosystemId,
	})

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	const formatTreeData = (items: z.infer<typeof CopyValidatePersonaAPIResSchema>) => {
		return items
			.filter(ecosystem => ecosystem.verticals?.length)
			.map(ecosystem => {
				return {
					id: String(ecosystem.id),
					label: ecosystem.name,
					children: ecosystem.verticals.map(vertical => ({
						id: String(vertical.id),
						label: vertical.name,
						info:
							!!vertical.personas.length &&
							(() => {
								const duplicates = vertical.personas
									.map(item => item.type)
									.filter((item1, i, arr) => arr.findIndex(item2 => item2 === item1) === i)

								return (
									<CustomTooltip label={`Vertical has duplicated personas (${duplicates.join(', ')})`}>
										<Icon name="warning-alt" className="text-status-warning-fg" />
									</CustomTooltip>
								)
							})(),
					})),
				}
			})
	}

	const duplicatingVerticalsCount = useMemo(() => {
		if (!selectedItems.length) return 0

		const tree = formatTreeData(ecosystemsWithValidatedPersonas)

		return tree.reduce(
			(count, ecosystem) =>
				count + ecosystem.children.filter(vertical => vertical.info && selectedItems.includes(vertical.id)).length,
			0,
		)
	}, [selectedItems, ecosystemsWithValidatedPersonas])

	const handleOnSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const searchVal = e.target.value.toLowerCase()
		setSearch(searchVal)

		const filteredData = ecosystemsWithValidatedPersonas
			.map(ecosystem => {
				const filteredVerticals = ecosystem.verticals.filter(vertical =>
					vertical.name.toLowerCase().includes(searchVal),
				)

				return {
					...ecosystem,
					verticals: filteredVerticals,
				}
			})
			.filter(ecosystem => ecosystem.verticals.length)

		setData(filteredData)
	}

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && navigate(routes.calibrate.verticals.index({ companyId, ecosystemId }))}
			dialogHeading={`Copy ${selectedPersonasIds.length} Personas To...`}
			isSubmitting={isPending}
			actions={[
				{ type: 'cancel', label: 'Cancel' },
				...(selectedPersonasIds.length ? [{ type: 'submit', formId, label: 'Copy' } as DialogButtonProps] : []),
			]}
			footerInfo={
				!!duplicatingVerticalsCount && (
					<span className="flex items-center gap-1.5 text-body-sm text-neutral-3-fg">
						<Icon name="warning-alt" size="sm" className="text-status-warning-fg" />
						<span>
							<span className="font-semibold">
								{`${duplicatingVerticalsCount} ${duplicatingVerticalsCount === 1 ? `vertical ` : `verticals `}`}
							</span>
							with duplicating personas selected
							<CustomTooltip label="Duplicate personas will not be copied to the selected verticals" side="bottom">
								<Icon name="information" size="sm" className="ml-1" />
							</CustomTooltip>
						</span>
					</span>
				)
			}
		>
			<Form id={formId} action={action} method={method}>
				{ecosystemsWithValidatedPersonas.length ? (
					<div className="flex min-h-[300px] flex-col gap-4">
						<Input
							value={search}
							onChange={handleOnSearchChange}
							clearable
							placeholder="Find verticals"
							iconRight="search"
						/>
						<ScrollArea.Root>
							<ScrollArea.Viewport
								className={cn(
									'max-h-[400px] min-h-[300px] overflow-hidden pe-3',
									!data.length && 'flex items-center justify-center',
								)}
							>
								<CheckboxTreeInput value={selectedItems} onChange={setSelectedItems} options={formatTreeData(data)} />
								{!data.length && (
									<section className="m-auto h-full gap-1 text-center">
										<Icon name="search-dialogue" className="mb-2 h-14 w-14 text-brand-1-fg" />
										<h3 className="text-center text-body-lg font-semibold text-neutral-2-fg">No search results</h3>
										<div className="mx-auto max-w-[300px]">
											<p className="truncate text-body-md">{`No results for “${search}”`}</p>
										</div>
									</section>
								)}
							</ScrollArea.Viewport>
							<ScrollArea.Scrollbar orientation="vertical" className="w-1.5">
								<ScrollArea.Thumb className="rounded-full bg-neutral-2-bd" />
							</ScrollArea.Scrollbar>
							<ScrollArea.Corner />
						</ScrollArea.Root>
					</div>
				) : (
					<Surface className="flex min-h-[160px] flex-col items-center justify-center border-none bg-neutral-2-bg">
						<h1 className="text-center text-body-lg text-neutral-2-fg">No personas selected</h1>
					</Surface>
				)}
				<input type="hidden" name="personas" value={JSON.stringify(selectedPersonasIds)} />
				<input type="hidden" name="targetVerticals" value={JSON.stringify(selectedItems)} />
			</Form>
		</Dialog>
	)
}
