import * as DialogPrimitive from '@radix-ui/react-dialog'
import { cva, type VariantProps } from 'class-variance-authority'
import {
	type ComponentPropsWithoutRef,
	type ElementRef,
	forwardRef,
	type ReactNode,
	type MouseEvent,
	isValidElement,
	Fragment,
	type CSSProperties,
} from 'react'
import { Link, type To } from 'react-router-dom'
import { ScrollArea, type ScrollAreaProps } from '#src/components/ScrollArea'
import { Button, type ButtonProps } from '#src/components/ui/button'
import { StatusButton } from '#src/components/ui/status-button'
import { cn } from '#src/utils/misc'
import { Icon } from './icon'

export const DialogRoot = DialogPrimitive.Root

const dialogContentVariants = cva(
	'm-6 flex w-full flex-col rounded-lg bg-neutral-1-bg shadow-lg animate-in fade-in zoom-in-90 overflow-hidden',
	{
		variants: {
			size: {
				sm: 'max-w-[450px]',
				md: 'max-w-[552px]',
				lg: 'max-w-[744px]',
				xl: 'max-w-[836px]',
			},
		},
		defaultVariants: {
			size: 'md',
		},
	},
)

export type DialogButtonProps = Omit<ButtonProps, 'type'> & {
	label?: string
	to?: To
	onClick?: (e: MouseEvent<HTMLButtonElement>) => void
	formId?: string
	type?: 'cancel' | 'submit'
	name?: string
	value?: string
}

type DialogTriggerProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Trigger>

export const DialogTrigger = forwardRef<ElementRef<typeof DialogPrimitive.Trigger>, DialogTriggerProps>(
	({ children, ...props }, forwardedRef) => (
		<DialogPrimitive.Trigger {...props} ref={forwardedRef}>
			{children}
		</DialogPrimitive.Trigger>
	),
)

DialogTrigger.displayName = 'DialogTrigger'

type DialogHeaderProps = ComponentPropsWithoutRef<'section'> & {
	dialogHeading?: ReactNode
	dialogDescription?: ReactNode
	closeClassName?: string
	onClose?: () => void
	closeTo?: To
}

const DialogHeader = ({
	dialogHeading,
	dialogDescription,
	closeClassName,
	className,
	onClose,
	closeTo,
	...rest
}: DialogHeaderProps) => (
	<section className={cn('flex justify-between border-b border-neutral-1-bd px-8 py-3', className)} {...rest}>
		<div>
			{dialogHeading && (
				<DialogPrimitive.Title className="text-title-sm text-neutral-1-fg">{dialogHeading}</DialogPrimitive.Title>
			)}
			{dialogDescription && (
				<DialogPrimitive.Description className="text-body-sm text-neutral-3-fg">
					{dialogDescription}
				</DialogPrimitive.Description>
			)}
		</div>
		<DialogPrimitive.Close asChild className={closeClassName}>
			{closeTo ? (
				<Link to={closeTo}>
					<button className="outline-none">
						<Icon name="cross-1" size="sm" />
					</button>
				</Link>
			) : (
				<button className="outline-none" onClick={onClose}>
					<Icon name="cross-1" size="sm" />
				</button>
			)}
		</DialogPrimitive.Close>
	</section>
)

type DialogFooterProps = ComponentPropsWithoutRef<'footer'> & {
	footerInfo?: ReactNode
	isSubmitting?: boolean
	actions?: (DialogButtonProps | ReactNode)[]
	onClose?: () => void
	closeTo?: To
}

const defaultActions: DialogButtonProps[] = [
	{
		label: 'Cancel',
		type: 'cancel',
		onClick: () => {},
	},
	{ label: 'Save', type: 'submit' },
]

const DialogFooter = ({
	footerInfo,
	isSubmitting = false,
	actions = defaultActions,
	className,
	onClose,
	closeTo,
	...rest
}: DialogFooterProps) => {
	const renderedActions = (actions || []).map((action, index) => {
		if (!action) return null

		if (isValidElement(action)) {
			return <Fragment key={index}>{action}</Fragment>
		}

		const buttonAction = action as DialogButtonProps

		if (buttonAction.type === 'cancel') {
			const to = buttonAction.to || closeTo
			const onClick = buttonAction.onClick || onClose

			return (
				<DialogPrimitive.Close asChild key={index}>
					<Button variant="outline" size="sm" asChild={!!to} onClick={!to ? onClick : undefined}>
						{to ? <Link to={to}>{buttonAction.label || 'Cancel'}</Link> : <span>{buttonAction.label || 'Cancel'}</span>}
					</Button>
				</DialogPrimitive.Close>
			)
		}

		if (buttonAction.type === 'submit') {
			return (
				<StatusButton
					key={index}
					status={isSubmitting ? 'pending' : 'idle'}
					size="sm"
					type={buttonAction.formId ? 'submit' : 'button'}
					form={buttonAction.formId}
					onClick={buttonAction.onClick}
					disabled={isSubmitting}
					asChild={!!buttonAction.to}
					name={buttonAction.name}
					value={buttonAction.value}
					variant={buttonAction.variant}
				>
					{buttonAction.to ? (
						<Link to={buttonAction.to}>{buttonAction.label || 'Save'}</Link>
					) : (
						<span>{buttonAction.label || 'Save'}</span>
					)}
				</StatusButton>
			)
		}

		return null
	})

	return (
		<footer
			className={cn('flex items-center justify-between border-t border-neutral-1-bd px-8 py-4', className)}
			{...rest}
		>
			<div className="text-body-sm">{footerInfo}</div>
			<div className="flex justify-end gap-2">{renderedActions}</div>
		</footer>
	)
}

type ContentProps = ComponentPropsWithoutRef<'section'> &
	VariantProps<typeof dialogContentVariants> & {
		disableScrollbar?: boolean

		// Use vh units, e.g., '70vh'
		maxHeight?: CSSProperties['maxHeight']
	}

type DialogContentProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Content> &
	VariantProps<typeof dialogContentVariants> & {
		header?: ReactNode
		headerProps?: DialogHeaderProps
		footer?: ReactNode
		footerProps?: DialogFooterProps
		contentProps?: ContentProps
		scrollAreaProps?: Omit<ScrollAreaProps, 'children'>
		aside?: ReactNode
	}

export const DialogContent = forwardRef<ElementRef<typeof DialogPrimitive.Content>, DialogContentProps>(
	(
		{
			children,
			header,
			headerProps = {},
			footer,
			footerProps = {},
			contentProps = {},
			className,
			scrollAreaProps,
			aside,
			...props
		},
		ref,
	) => {
		const renderChildren = <section className={cn('px-8 pb-6 pt-2', contentProps.className)}>{children}</section>

		return (
			<DialogPrimitive.Portal>
				<DialogPrimitive.Overlay className="fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-overlay p-4 animate-in fade-in">
					<DialogPrimitive.Content
						className={cn(
							dialogContentVariants({ size: contentProps.size }),
							contentProps?.className,
							className,
							aside ? 'flex-row' : null,
						)}
						{...props}
						ref={ref}
						style={{ maxHeight: contentProps.maxHeight }}
					>
						{aside}
						<div className="flex flex-1 flex-col">
							{header ?? <DialogHeader {...headerProps} />}
							<div className="flex-1">
								{!contentProps.disableScrollbar ? (
									<ScrollArea
										maxHeight={contentProps.maxHeight ? `calc(${contentProps.maxHeight} - 120px)` : '75vh'}
										viewPortClassName={contentProps.className}
										scrollbarClassName="mb-5"
										{...scrollAreaProps}
									>
										{renderChildren}
									</ScrollArea>
								) : (
									renderChildren
								)}
							</div>
							{footer === undefined && <DialogFooter {...footerProps} />}
							{footer && <div className="px-8 py-4">{footer}</div>}
						</div>
					</DialogPrimitive.Content>
				</DialogPrimitive.Overlay>
			</DialogPrimitive.Portal>
		)
	},
)

DialogContent.displayName = 'DialogContent'

export type DialogProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Root> &
	VariantProps<typeof dialogContentVariants> & {
		trigger?: ReactNode
		children: ReactNode
		header?: ReactNode
		headerProps?: DialogHeaderProps
		footer?: ReactNode
		footerProps?: DialogFooterProps
		contentProps?: ContentProps
		dialogHeading?: ReactNode
		dialogDescription?: ReactNode
		footerInfo?: ReactNode
		isSubmitting?: boolean
		actions?: (DialogButtonProps | ReactNode)[]
		onClose?: () => void
		closeTo?: To
		aside?: ReactNode
	}

export const Dialog = forwardRef<ElementRef<typeof DialogPrimitive.Content>, DialogProps>(
	(
		{
			trigger,
			children,
			header,
			headerProps = {},
			footer,
			footerProps = {},
			contentProps = {},
			dialogHeading,
			dialogDescription,
			footerInfo,
			isSubmitting,
			actions,
			onClose,
			closeTo,
			size,
			aside,
			...props
		},
		ref,
	) => {
		const mergedHeaderProps = { ...headerProps, dialogHeading, dialogDescription, onClose, closeTo }
		const mergedFooterProps = { ...footerProps, footerInfo, isSubmitting, actions, onClose, closeTo }
		const mergedContentProps = { ...contentProps, size }

		return (
			<DialogPrimitive.Root {...props}>
				{trigger && <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>}
				<DialogContent
					ref={ref}
					header={header}
					headerProps={mergedHeaderProps}
					footer={footer}
					footerProps={mergedFooterProps}
					contentProps={mergedContentProps}
					aside={aside}
				>
					{children}
				</DialogContent>
			</DialogPrimitive.Root>
		)
	},
)

Dialog.displayName = 'Dialog'
