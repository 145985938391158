import { type LoaderFunctionArgs, redirect } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { companyId, company } = await getCompany(params)

	try {
		return {
			data: {
				ecosystems: await client.fetchQuery(ecosystemsQuery(company.id, { personas: false, exclude: true })),
			},
		}
	} catch (e) {
		showToast({
			message: `${(e as Response).status}: ${(e as Response).statusText} | ${(e as Response).url}`,
			type: 'error',
		})
		throw redirect(routes.leadResearchAgent.index({ companyId }))
	}
}
