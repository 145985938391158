import { type LoaderFunctionArgs } from 'react-router-dom'
import { type z } from 'zod'
import { client } from '#src/main'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/verticals/criteria/constants'
import { criterionQuery } from '#src/routes/calibrate/verticals/criteria/queries'
import { generateSaveCriterionContext } from '#src/routes/calibrate/verticals/criteria/utils'
import { verticalsQuery } from '#src/routes/calibrate/verticals/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(type: z.infer<typeof CRITERION_TYPE_ENUM>) =>
	async ({ request, params }: LoaderFunctionArgs) => {
		validateRouteParams(params, ['ecosystemId'])
		const { company, companyId } = await getCompany(params)
		const url = new URL(request.url)
		const redirectTo = url.searchParams.get('to')
		let criterion
		let defaultValues = {}

		const verticals = await client.fetchQuery(verticalsQuery(company.id, Number(params.ecosystemId)))

		if (params.criterionId) {
			criterion = await client.fetchQuery(criterionQuery(company.id, params.criterionId))

			if (criterion) {
				const { defaultValues: verticalsDefaults } = generateSaveCriterionContext(
					verticals,
					criterion.type ?? 'text',
					type,
					criterion,
				)
				defaultValues = verticalsDefaults
			}
		}

		return {
			values: {
				companyId,
				ecosystemId: params.ecosystemId,
				type,
				defaultValues,
				redirectTo,
				key: new Date().getTime().toString(),
			},
			data: {
				verticals,
				criterion,
			},
		}
	}
