export const MAX_UPLOAD_SIZE = 1024 * 1024 * 3 // 3MB
export const ACCEPTED_FILE_TYPES = ['text/csv'] as const

export enum FILE_TYPES {
	Accounts = 'account',
	AccountsAndContacts = 'account_contact',
}

export enum LRA_JOB_STATUS {
	Launching = 'launching',
	InProgress = 'in_progress',
	Completed = 'completed',
	CompletedWithQuestions = 'completed_with_questions',
	Cancelled = 'cancelled',
	Error = 'error',
}
