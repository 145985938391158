import { type z } from 'zod'
import { type FILE_TYPES } from '#src/api/organise/lists/constants'
import { type LeadResearchAgentItemAPISchema } from '#src/api/organise/lists/schemas'
import { organizeApi } from '#src/utils/endpoints'

const base = () => `${organizeApi}/lists-csv`

const endpoints = {
	downloadJob: (jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>, query: Record<string, string>) =>
		`${base()}/${jobId}?${new URLSearchParams(query).toString()}`,
	downloadTemplate: (type: FILE_TYPES) => `${base()}/template/${type}`,
}

export { endpoints as orgListsCsvEndpoints }
