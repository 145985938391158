import { type LoaderFunctionArgs } from 'react-router-dom'
import { companyChatArchiveQueries } from '#src/api/icp/company/chat/archive/queries'
import { companyChatRoleplaySessionQueries } from '#src/api/icp/company/chat/roleplay/session/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['conversationId'])

	return {
		handle: {
			conversationId: params.conversationId,
		},
		session: await client.fetchQuery(companyChatRoleplaySessionQueries.item(company.id, params.conversationId)),
		attempts: await client.fetchQuery(companyChatArchiveQueries.list(company.id, params.conversationId)),
	}
}
