import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'
import { type ScenarioAPISchema } from './schemas'

const base = (companyId: z.infer<typeof CompanySchema.shape.id>) =>
	`${baseApi}/company/${companyId}/chat/roleplay/scenario`

const endpoints = {
	list: (companyId: z.infer<typeof CompanySchema.shape.id>, page: number | null) =>
		`${base(companyId)}${page !== null ? `?${new URLSearchParams({ page: String(page) }).toString()}` : ''}`,
	item: (companyId: z.infer<typeof CompanySchema.shape.id>, scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>) =>
		`${base(companyId)}/${scenarioId}`,
	delete: (companyId: z.infer<typeof CompanySchema.shape.id>, scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>) =>
		`${base(companyId)}/${scenarioId}`,
	voices: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/voices`,
	templates: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/templates`,
	assigned: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
	) => `${base(companyId)}/${scenarioId}/assigned`,
	itemManager: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
		sessionId: string,
	) => `${base(companyId)}/${scenarioId}/view/${sessionId}`,
	createTestSession: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
	) => `${base(companyId)}/${scenarioId}/self`,
	assign: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
		userId: number,
	) => `${base(companyId)}/${scenarioId}/assign/${userId}`,
	update: (companyId: z.infer<typeof CompanySchema.shape.id>, scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>) =>
		`${base(companyId)}/${scenarioId}`,
	create: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}`,
}

export { endpoints as companyChatRoleplayScenarioEndpoints }
