import { type LoaderFunctionArgs } from 'react-router-dom'
import { playsQueries } from '#src/api/icp/company/plays/queries'
import { client } from '#src/main'
import { paramsToObject } from '#src/utils/requests'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	const filtersSelected = paramsToObject(new URL(request.url).searchParams)
	let plays = await client.fetchQuery(playsQueries.list(company.id))
	const personaType = [...new Set(plays.map(play => play.personaTypes).flat(1))]
	const expertise = [...new Set(plays.map(play => play.expertise).flat(1))]
	const tags = [...new Set(plays.map(play => play.tags).flat(1))]

	if ('personaType' in filtersSelected) {
		plays = plays.filter(play => {
			const personaTypes = Array.isArray(filtersSelected.personaType)
				? filtersSelected.personaType
				: [filtersSelected.personaType]
			return play.personaTypes.filter(item => personaTypes.includes(item)).length > 0
		})
	}
	if ('expertise' in filtersSelected) {
		plays = plays.filter(play => {
			const expertises = Array.isArray(filtersSelected.expertise)
				? filtersSelected.expertise
				: [filtersSelected.expertise]
			return play.expertise.filter(item => expertises.includes(item)).length > 0
		})
	}
	if ('tags' in filtersSelected) {
		plays = plays.filter(play => {
			const tags = Array.isArray(filtersSelected.tags) ? filtersSelected.tags : [filtersSelected.tags]
			return play.tags.filter(item => tags.includes(item)).length > 0
		})
	}
	if ('inCrm' in filtersSelected) {
		plays = plays.filter(play => {
			const flag = filtersSelected.inCrm === 'true'
			return play.inCrm === flag
		})
	}

	return {
		filters: {
			values: {
				personaType,
				expertise,
				tags,
				inCrm: [
					{
						value: 'true',
						label: 'Yes',
					},
					{
						value: 'false',
						label: 'No',
					},
				],
			},
			selected: filtersSelected,
		},
		data: {
			plays,
		},
	}
}
