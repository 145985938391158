import { useNavigate, useLoaderData, createSearchParams } from 'react-router-dom'
import { EmptyStateCard } from '#src/components'
import ContactAvatar from '#src/components/chat/avatar'
import { PersonaAvatar } from '#src/components/persona'
import ProgressBar from '#src/components/ProgressBar'
import { TrainingAgenda } from '#src/components/training-agenda'
import { PageLayout } from '#src/components/ui/PageLayout'
import { PaginatedTable } from '#src/components/ui/PaginatedTable'
import useCompany from '#src/hooks/useCompany'
import { type LoaderRes } from '#src/routes/enable/roleplay-scenarios/loaders/list'
import { formatDateToReadableString } from '#src/utils/date'
import { calculatePct, formatAvatarAbbr } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

const View = () => {
	const { companyId } = useCompany()
	const { scenarios } = useLoaderData() as LoaderRes
	const navigate = useNavigate()

	return (
		<PageLayout
			title="Roleplay scenarios"
			actions={[
				{
					variant: 'default',
					to: routes.enable.roleplay.scenario.create({ companyId }),
					icon: 'add',
					title: 'New scenario',
				},
			]}
		>
			{scenarios.data.length ? (
				<PaginatedTable
					data={scenarios.data}
					currentPage={scenarios.meta.current_page}
					totalPages={scenarios.meta.last_page}
					onPageChange={page =>
						navigate({
							search: createSearchParams({
								page: (page + 1).toString(),
							}).toString(),
						})
					}
					onItemClick={scenario =>
						navigate(
							routes.enable.roleplay.scenario.item({
								companyId: companyId,
								scenarioId: scenario.id,
							}),
						)
					}
					columns={[
						{
							name: 'name',
							heading: 'Name',
							render: scenario => scenario.name,
						},
						{
							name: 'agenda',
							heading: 'Agenda',
							render: scenario => <TrainingAgenda title={scenario.title ?? ''} icon="user-service-desk" />,
						},
						{
							name: 'persona_used',
							heading: 'Persona used',
							render: scenario =>
								scenario.personaMapping ? (
									<div className="flex max-w-[200px] flex-row items-center gap-2">
										<PersonaAvatar type={scenario.personaMapping.personaType} size="sm" className="row-span-2" />
										<div className="flex-1 overflow-hidden">
											<p className="w-full truncate text-label-sm text-neutral-3-fg">
												{scenario.personaMapping.expertise}
											</p>
											<p className="w-full truncate text-body-sm font-semibold text-neutral-1-fg">
												{scenario.personaMapping.personaType}
											</p>
										</div>
									</div>
								) : (
									<p className="text-body-sm text-neutral-3-fg">Persona has been deleted</p>
								),
						},
						{
							name: 'participation_rate',
							heading: 'Participation rate',
							render: scenario => (
								<div className="flex w-[150px] items-center gap-1.5">
									<ProgressBar percent={calculatePct(scenario.completed, scenario.total)} variant="status" />
									<span className="text-body-sm text-neutral-3-fg">{`${scenario.completed}/${scenario.total}`}</span>
								</div>
							),
						},
						{
							name: 'created_at',
							heading: 'Date created',
							render: scenario => formatDateToReadableString(scenario.createdAt),
						},
						{
							name: 'created_by',
							heading: 'Created by',
							render: scenario => (
								<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
									<ContactAvatar
										className="inline-flex shrink-0 items-center justify-center rounded-full"
										variant="gray"
										size="sm"
										initial={formatAvatarAbbr(scenario.createdBy)}
									/>
									{scenario.createdBy}
								</div>
							),
						},
					]}
				/>
			) : (
				<EmptyStateCard icon="chat" title="You don't have any scenarios yet." />
			)}
		</PageLayout>
	)
}

export default View
