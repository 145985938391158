import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { type z } from 'zod'
import { type FilterAPISchema } from '#src/api/icp/company/chat/roleplay/session/schemas'
import FilterSelect from '#src/components/FilterSelect'
import { Icon } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'

type Props = {
	selected: Record<string, string | string[]>
	values: Record<string, z.infer<typeof FilterAPISchema>[]>
	filters: {
		name: string
		label: string
		multiple?: boolean
	}[]
}

const FiltersSection = (props: Props) => {
	const { selected, values, filters } = props
	const hasFilters = !!Object.values(selected).filter(i => i !== null).length

	const navigate = useNavigate()

	const onFilterChange = (name: string, value: string[]) => {
		const payload = Object.fromEntries(
			Object.entries({
				...selected,
				[name]: value,
			}).filter(([, val]) => val.length > 0),
		)
		navigate(`?${createSearchParams(payload as Record<string, string[]>).toString()}`, { replace: true })
	}
	const { pathname } = useLocation()

	return (
		<div className="flex items-center gap-4">
			<p className="flex items-center gap-1 text-label-lg text-neutral-2-fg">
				<Icon name="filter" size="sm" /> Filter by:
			</p>
			<div className="flex items-center gap-2">
				{filters.map(filter => (
					<FilterSelect
						key={`filter-${filter.name}`}
						value={selected[filter.name]}
						onChange={e => onFilterChange(filter.name, e)}
						label={filter.label}
						options={values[filter.name].map(item =>
							typeof item === 'string'
								? {
										value: item,
										label: item,
									}
								: item,
						)}
						multiple={filter.multiple}
					/>
				))}
				<button
					type="submit"
					name="intent"
					value="clear-all"
					disabled={!hasFilters}
					className={cn(
						'text-button-sm',
						hasFilters ? 'text-link hover:text-link-hover active:text-link-pressed' : 'text-neutral-1-fg-disabled',
					)}
					onClick={e => {
						e.preventDefault()
						navigate(pathname)
					}}
				>
					Clear all
				</button>
			</div>
		</div>
	)
}

export default FiltersSection
