import { z } from 'zod'

export const RoleplayArchiveItemAPISchema = z.object({
	id: z.number(),
	messageCount: z.number(),
	messages: z.array(
		z.object({
			id: z.number(),
			isAi: z.boolean(),
			message: z.string(),
		}),
	),
	evaluation: z
		.object({
			status: z.string(),
			value: z.string(),
		})
		.nullable(),
	createdAt: z.string(),
})

export const RoleplayArchiveListAPISchema = RoleplayArchiveItemAPISchema.array()
