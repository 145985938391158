import { type LoaderFunctionArgs } from 'react-router-dom'
import { companyChatRoleplayScenarioQueries } from '#src/api/icp/company/chat/roleplay/scenario/queries'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	const scenarioTemplates = await client.fetchQuery(companyChatRoleplayScenarioQueries.templates(company.id))

	if (!scenarioTemplates.length)
		throw new Response('No scenario templates provided', {
			status: 400,
			statusText: 'Bad Request',
		})

	return {
		scenarioTemplates,
		ecosystems: await client.fetchQuery(ecosystemsQuery(company.id)),
		voices: await client.fetchQuery(companyChatRoleplayScenarioQueries.voices(company.id)),
	}
}
