import { z } from 'zod'
import { FILE_TYPES } from '#src/api/organise/lists/constants'
import { EXPORT_TYPE } from './constants'

export const LeadResearchAgentJobDownloadFormSchema = z.object({
	type: z.nativeEnum(EXPORT_TYPE, { message: 'Incorrect exporting type' }),
})
export const LeadResearchAgentTemplateDownloadFormSchema = z.object({
	type: z.nativeEnum(FILE_TYPES, { message: 'Incorrect exporting type' }),
})
