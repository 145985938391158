import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch, safeFetchMeta } from '#src/utils/safeFetch'
import { companyChatRoleplayScenarioEndpoints } from './endpoints'
import {
	AssignedUsersAPISchema,
	ScenarioAPISchema,
	ScenariosAPISchema,
	ScenarioTemplatesAPISchema,
	VoicesAPISchema,
} from './schemas'

const keys = {
	all: ['icp', 'company', 'chat', 'roleplay', 'scenario'] as const,
	list: (companyId: z.infer<typeof CompanySchema.shape.id>, page: number | null) =>
		[...keys.all, 'list', companyId, page] as const,
	item: (companyId: z.infer<typeof CompanySchema.shape.id>, scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>) =>
		[...keys.all, 'item', companyId, scenarioId] as const,
	voices: (companyId: z.infer<typeof CompanySchema.shape.id>) => [...keys.all, 'item', companyId, 'voices'] as const,
	templates: (companyId: z.infer<typeof CompanySchema.shape.id>) =>
		[...keys.all, 'item', companyId, 'templates'] as const,
	itemAssigned: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
	) => [...keys.all, 'item', companyId, scenarioId, 'assigned'] as const,
	itemManager: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
		sessionId: string,
	) => [...keys.all, 'item', companyId, scenarioId, 'manager', sessionId] as const,
}

const listQuery = (companyId: z.infer<typeof CompanySchema.shape.id>, page: number | null) => ({
	queryKey: keys.list(companyId, page),
	queryFn: async () =>
		await safeFetchMeta(ScenariosAPISchema, companyChatRoleplayScenarioEndpoints.list(companyId, page), {
			method: 'GET',
		}),
})

const itemQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
) => ({
	queryKey: keys.item(companyId, scenarioId),
	queryFn: async () =>
		await safeFetch(ScenarioAPISchema, companyChatRoleplayScenarioEndpoints.item(companyId, scenarioId), {
			method: 'GET',
		}),
})

const voicesQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) => ({
	queryKey: keys.voices(companyId),
	queryFn: async () =>
		await safeFetch(VoicesAPISchema, companyChatRoleplayScenarioEndpoints.voices(companyId), {
			method: 'GET',
		}),
})

const templatesQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) => ({
	queryKey: keys.templates(companyId),
	queryFn: async () =>
		await safeFetch(ScenarioTemplatesAPISchema, companyChatRoleplayScenarioEndpoints.templates(companyId), {
			method: 'GET',
		}),
})

const itemAssignedQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
) => ({
	queryKey: keys.itemAssigned(companyId, scenarioId),
	queryFn: async () =>
		await safeFetch(AssignedUsersAPISchema, companyChatRoleplayScenarioEndpoints.assigned(companyId, scenarioId), {
			method: 'GET',
		}),
})

// const itemManagerQuery = (
// 	companyId: z.infer<typeof CompanySchema.shape.id>,
// 	scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
// 	sessionId: string,
// ) => ({
// 	queryKey: keys.itemManager(companyId, scenarioId, sessionId),
// 	queryFn: async () =>
// 		await safeFetch(
// 			SessionDataSchema,
// 			companyChatRoleplayScenarioEndpoints.itemManager(companyId, scenarioId, sessionId),
// 			{
// 				method: 'GET',
// 			},
// 		),
// })

const queries = {
	list: listQuery,
	item: itemQuery,
	voices: voicesQuery,
	templates: templatesQuery,
	itemAssigned: itemAssignedQuery,
	// itemManager: itemManagerQuery,
}

export { queries as companyChatRoleplayScenarioQueries, keys as companyChatRoleplayScenarioQueriesKeys }
