import { CreateScenarioFormSchema, EditScenarioFormSchema } from '#src/api/icp/company/chat/roleplay/scenario/schemas'

export const getActiveNewScenarioWizardSchema = (activeStep: string) => {
	switch (activeStep) {
		case 'scenario':
			return CreateScenarioFormSchema.pick({
				name: true,
				scenario: true,
				templateId: true,
			})
		case 'persona-mapping':
			return CreateScenarioFormSchema.pick({
				ecosystemId: true,
				verticalId: true,
				personaId: true,
				linkedinUrl: true,
			})
		case 'personality':
			return CreateScenarioFormSchema.pick({
				personality: true,
			})
		case 'evaluation':
			return CreateScenarioFormSchema.pick({ evaluation: true })
		case 'instructions':
			return CreateScenarioFormSchema.pick({ instructions: true })
		default:
			return CreateScenarioFormSchema
	}
}

export const getActiveScenarioTabSchema = (activeTab: string) => {
	switch (activeTab) {
		case 'scenario':
			return EditScenarioFormSchema.pick({
				name: true,
				scenario: true,
				templateId: true,
			})
		case 'persona-mapping':
			return EditScenarioFormSchema.pick({ personaId: true, linkedinUrl: true })
		case 'personality':
			return EditScenarioFormSchema.pick({
				personality: true,
			})
		case 'evaluation':
			return EditScenarioFormSchema.pick({ evaluation: true })
		case 'instructions':
			return EditScenarioFormSchema.pick({ instructions: true })
		default:
			return EditScenarioFormSchema
	}
}
