import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { organizeApi } from '#src/utils/endpoints'
import { type LeadResearchAgentItemAPISchema } from './schemas'

const base = () => `${organizeApi}/lists`

const endpoints = {
	list: (companyId: z.infer<typeof CompanySchema.shape.id>, page: number, sort: string[]) => {
		const query = new URLSearchParams({
			companyId,
			page: page.toString(),
		})
		sort.forEach(value => {
			query.append('sort[]', value)
		})

		return `${base()}/?${new URLSearchParams(query).toString()}`
	},
	item: (jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>) => `${base()}/${jobId}`,
	itemStop: (jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>) => `${base()}/${jobId}/cancel`,
	create: () => `${base()}/`,
	statistics: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base()}/company/${companyId}/statistics`,
}

export { endpoints as orgListsEndpoints }
