import { type ReactNode } from 'react'
import { Logo } from '#src/components/ui/logo'
import { cn } from '#src/utils/misc'

export const ChatAvatar = ({ animate, icon }: { animate: boolean; icon?: ReactNode }) => {
	return (
		<div className="relative z-0 w-max">
			<div
				className={cn(
					'pointer-events-none absolute inset-0 flex select-none flex-col items-center justify-center opacity-0',
					animate && 'animate-pulsing-rings-1',
				)}
			>
				<div className="min-h-[670px] w-[42.5%] min-w-[670px] rounded-full border border-[#0A1E7A] bg-transparent pb-[42.5%] opacity-[0.06]" />
			</div>
			<div
				className={cn(
					'pointer-events-none absolute inset-0 flex select-none flex-col items-center justify-center opacity-0',
					animate && 'animate-pulsing-rings-2',
				)}
			>
				<div className="min-h-[500px] w-[32.5%] min-w-[500px] rounded-full border border-[#0A1E7A] bg-transparent pb-[32.5%] opacity-[0.09]" />
			</div>
			<div
				className={cn(
					'pointer-events-none absolute inset-0 flex select-none flex-col items-center justify-center opacity-0',
					animate && 'animate-pulsing-rings-3',
				)}
			>
				<div className="min-h-[330px] w-[22.5%] min-w-[330px] rounded-full border border-[#0A1E7A] bg-transparent pb-[22.5%] opacity-[0.14]" />
			</div>
			<div
				className={cn(
					'pointer-events-none absolute inset-0 bottom-[-500%] flex select-none flex-col items-center justify-center',
				)}
			>
				<div className="min-h-[300px] w-[32.5%] min-w-[600px] rounded-[300px_300px_0_0] bg-[radial-gradient(circle,rgba(255,255,255,1)_0%,rgba(255,255,255,1)_30%,rgba(255,255,255,0)_75%)]" />
			</div>
			{icon ? icon : <Logo size="xl" type="symbol" />}
		</div>
	)
}
