import { useMutation, useQueryClient, useMutationState } from '@tanstack/react-query'
import { useId, useRef, useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { type z } from 'zod'
import { admin, api } from '#src/utils/endpoints'
import { fetch, safeFetch } from '#src/utils/safeFetch'
import { chatQuery } from './queries'
import {
	ChatMessageSchema,
	ChatSchema,
	ConversationDataSchema,
	MessageStatus,
	NewConversationAPISchema,
	type NewConversationFormSchema,
	type MessageSchema,
	type ValidateLinkedinUrlSchema,
	ValidateLinkedinUrlAPISchema,
} from './schema'
import { streamMessageMutation } from './stream'

const sendMessage = async (companyId: string, conversationId: string, data: z.infer<typeof MessageSchema>) => {
	const response = await fetch(api.chat.messages(companyId, conversationId), {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify(data),
	})

	if (!response.ok) {
		throw new Error('Failed to send message')
	}

	if (!response.body) {
		throw new Error('ReadableStream not supported in this browser.')
	}

	return response
}

type Payload = {
	message: string
	intent: 'create' | 'resend'
}

export const useSendMessageMutation = ({
	companyId,
	conversationId,
}: {
	companyId: string
	conversationId: string
}) => {
	const id = useId()
	const queryClient = useQueryClient()
	const dataRef = useRef<string>('')
	const [errorMsg, setErrorMsg] = useState<string | null>(null)

	const mutation = useMutation({
		mutationKey: ['send-message'],
		mutationFn: async ({ message }: Payload) => sendMessage(companyId, conversationId, { message }),
		onMutate: async (payload: Payload) => {
			await queryClient.cancelQueries(chatQuery(companyId, conversationId))

			const previousChat = queryClient.getQueryData(chatQuery(companyId, conversationId).queryKey)
			const newMessage = ChatMessageSchema.parse({
				id,
				status: MessageStatus.Sending,
				message: payload.message,
				isAi: false,
			})

			if (previousChat) {
				const optimisticData =
					payload.intent === 'create'
						? [...previousChat, newMessage]
						: [...previousChat.filter(message => message.message !== payload.message), newMessage]

				ChatSchema.parse(optimisticData)

				void queryClient.setQueryData(chatQuery(companyId, conversationId).queryKey, optimisticData)

				return { previousChat }
			}
		},
		onError: async (error, payload, context) => {
			// Parsing the error response to get the actual error message
			if (error instanceof Response) {
				const errorData: { message?: string } = (await error.json().catch(() => ({}))) as { message?: string }

				if (errorData && errorData.message) {
					setErrorMsg(errorData.message || 'An unknown error occurred')
				}
			}

			const previousChat = context?.previousChat
			const newMessage = ChatMessageSchema.parse({
				id,
				status: MessageStatus.Error,
				message: payload.message,
				isAi: false,
			})
			const errorChat =
				payload.intent === 'create'
					? [...(previousChat ?? []), newMessage]
					: [...(previousChat ? previousChat.filter(message => message.message !== payload.message) : []), newMessage]

			ChatSchema.parse(errorChat)

			void queryClient.setQueryData(chatQuery(companyId, conversationId).queryKey, errorChat)
		},
		onSuccess: async response => {
			await queryClient.invalidateQueries(chatQuery(companyId, conversationId))
			setErrorMsg(null)

			const reader = response.body!.getReader()
			// eslint-disable-next-line no-constant-condition
			while (true) {
				const { value, done } = await reader.read()

				if (done) {
					await queryClient.invalidateQueries(chatQuery(companyId, conversationId))
					dataRef.current = ''
					streamMessageMutation({
						companyId,
						conversationId,
						message: null,
					})
					break
				}

				const chunk = new TextDecoder().decode(value)
				dataRef.current = dataRef.current + chunk
				streamMessageMutation({
					companyId,
					conversationId,
					message: ChatMessageSchema.parse({
						id,
						status: MessageStatus.Generating,
						message: dataRef.current,
						isAi: true,
					}),
				})
			}
		},
	})
	const status = useCanSendMessageStatus()

	return {
		isStreaming: !!dataRef.current,
		status,
		send: (message: string, intent: 'create' | 'resend') => {
			mutation.mutate({ message, intent })
		},
		errorMsg,
	}
}

export const useCanSendMessageStatus = () => {
	const mutationState = useMutationState({
		filters: {
			mutationKey: ['send-message'],
		},
	}).slice(-1)?.[0]
	const fetcher = useFetcher({ key: 'chat-restart' })

	if (fetcher.state !== 'idle' || mutationState?.status === 'pending') return 'pending'
	else return mutationState?.status ?? 'idle'
}

export const restartConversationChat = async (companyId: string, conversationId: string) => {
	return await safeFetch(ConversationDataSchema, admin.conversation(companyId, conversationId), {
		method: 'PUT',
	})
}

export const deleteConversation = async (companyId: string, conversationId: string) => {
	return await fetch(api.chat.delete(companyId, conversationId), {
		method: 'DELETE',
	})
}

export const createChat = async (
	companyId: string,
	data: Pick<z.infer<typeof NewConversationFormSchema>, 'personaId' | 'linkedinUrl'>,
) => {
	return await safeFetch(NewConversationAPISchema, api.chat.new(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}

export const validateLinkedinUrl = async (companyId: string, data: z.infer<typeof ValidateLinkedinUrlSchema>) => {
	return await safeFetch(ValidateLinkedinUrlAPISchema, api.chat.validateLinkedinUrl(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}

export const stopConversationResponse = async (companyId: string, conversationId: string) =>
	await fetch(api.chat.stop(companyId, conversationId), {
		method: 'POST',
	})
