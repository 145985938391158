import { type ActionFunctionArgs } from 'react-router-dom'
import { orgDataEnrichmentWorkflowsMutations } from '#src/api/organise/data-enrichment/workflows/mutations'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['jobId'])

	try {
		await orgDataEnrichmentWorkflowsMutations.stop(params.jobId)

		showToast({
			message: 'Job stopped successfully',
			type: 'success',
		})

		return {
			success: true,
		}
	} catch (e) {
		const message = `${(e as Response).status}: ${(e as Response).statusText} | ${(e as Response).url}`
		showToast({
			message,
			type: 'error',
		})
		return {
			success: false,
			message,
		}
	}
}
