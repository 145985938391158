import { Link, useLoaderData, useNavigate } from 'react-router-dom'
import { EmptyStateCard } from '#src/components'
import ContactAvatar from '#src/components/chat/avatar'
import ProgressBar from '#src/components/ProgressBar'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { PageLayout, type PageLayoutPropsAction } from '#src/components/ui/PageLayout'
import { Surface } from '#src/components/ui/surface'
import { Table } from '#src/components/ui/Table'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { type LoaderRes } from '#src/routes/enable/roleplay-scenarios/loaders/item'
import { DeleteScenarioModal } from '#src/routes/enable/roleplay-scenarios/views/components/DeleteScenarioModal'
import { ScenarioTabs } from '#src/routes/enable/roleplay-scenarios/views/components/ScenarioTabs'
import { formatDateToReadableString } from '#src/utils/date'
import { calculatePct, formatAvatarAbbr } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

const View = () => {
	const { companyId } = useCompany()
	const { scenario, voices } = useLoaderData() as LoaderRes
	const { user } = useAuth()

	return (
		<PageLayout
			title={scenario.name ?? scenario.title ?? 'Scenario Details'}
			actions={[
				...(scenario.personaMapping
					? [
							{
								to: routes.enable.roleplay.scenario.testSession({
									companyId,
									scenarioId: scenario.id,
								}),
								icon: 'play-filled-alt',
								title: 'Test Roleplay Session',
								variant: 'outline',
							} satisfies PageLayoutPropsAction,
						]
					: []),
				...(user.email === scenario.createdBy
					? [
							<DeleteScenarioModal scenarioId={scenario.id} key="delete-scenario">
								<Button variant="danger-outline" className="flex flex-nowrap items-center gap-2">
									<Icon name="trash" size="font" className="text-status-danger-fg" />
								</Button>
							</DeleteScenarioModal>,
						]
					: []),
			]}
		>
			<Surface className="w-full">
				<ScenarioTabs scenario={scenario} voices={voices} />
			</Surface>
			<Participants />
		</PageLayout>
	)
}

const Participants = () => {
	const { scenario } = useLoaderData() as LoaderRes
	const { companyId } = useCompany()
	const navigate = useNavigate()

	return (
		<Surface className="w-full p-10">
			<div className="flex justify-between pb-6">
				<div className="flex items-center gap-4">
					<h2 className="flex text-title-md text-neutral-1-fg">Participants</h2>
					{!!scenario.sessions.length && (
						<div className="flex w-[150px] items-center gap-1.5">
							<ProgressBar percent={calculatePct(scenario.completed, scenario.total)} variant="status" />
							<span className="text-body-sm text-neutral-3-fg">{`${scenario.completed}/${scenario.total}`}</span>
						</div>
					)}
				</div>
				{!!scenario.sessions.length && (
					<Button asChild className="flex flex-nowrap items-center gap-2">
						<Link
							to={routes.enable.roleplay.scenario.invite({
								companyId,
								scenarioId: scenario.id,
							})}
						>
							<Icon name="add-alt" />
							Invite Participants
						</Link>
					</Button>
				)}
			</div>
			{!scenario.sessions.length ? (
				<EmptyStateCard
					icon="group"
					title="No participants"
					description="You didn’t invited any team participants yet"
					actions={[
						{
							title: 'Invite',
							to: routes.enable.roleplay.scenario.invite({
								companyId,
								scenarioId: scenario.id,
							}),
						},
					]}
				/>
			) : (
				<Table
					data={scenario.sessions}
					onItemClick={session =>
						navigate(
							routes.enable.roleplay.scenario.attempts({
								companyId,
								scenarioId: scenario.id,
								sessionId: session.id,
							}),
						)
					}
					columns={[
						{
							name: 'user',
							heading: 'User',
							render: session => (
								<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
									<ContactAvatar
										className="inline-flex shrink-0 items-center justify-center rounded-full"
										variant="gray"
										size="sm"
										initial={formatAvatarAbbr(session.username)}
									/>
									{session.username}
								</div>
							),
						},
						{
							name: 'attempts',
							heading: 'Attempts',
							render: session => session.attempts,
						},
						{
							name: 'invited',
							heading: 'Invited at',
							render: session => formatDateToReadableString(session.createdAt),
						},
						{
							name: 'completed',
							heading: 'Completed at',
							render: session => (session.completedAt ? formatDateToReadableString(session.completedAt) : '-'),
						},
					]}
				/>
			)}
		</Surface>
	)
}

export default View
