import { type LoaderFunctionArgs } from 'react-router-dom'
import { type z } from 'zod'
import { client } from '#src/main'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/verticals/criteria/constants'
import {
	criteriaListQuery,
	criteriaTemplatesQuery,
	externalCriteriaQuery,
} from '#src/routes/calibrate/verticals/criteria/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(type: z.infer<typeof CRITERION_TYPE_ENUM>) =>
	async ({ params }: LoaderFunctionArgs) => {
		validateRouteParams(params, ['ecosystemId'])
		const { company } = await getCompany(params)

		const [criteriaList, externalCriteriaList, criteriaTemplatesList] = await Promise.all([
			client
				.fetchQuery(criteriaListQuery(company.id))
				.then(data => data.filter(item => (type === 'enrichment' ? item.isEnrichment : !item.isEnrichment))),
			client
				.fetchQuery(externalCriteriaQuery(company.id))
				.then(data => data.filter(item => (type === 'enrichment' ? item.isEnrichment : !item.isEnrichment))),
			client
				.fetchQuery(criteriaTemplatesQuery(company.id))
				.then(data => data.filter(item => (type === 'enrichment' ? item.isEnrichment : !item.isEnrichment))),
		])

		return {
			values: {
				ecosystemId: params.ecosystemId,
				type,
			},
			data: {
				criteriaList,
				externalCriteriaList,
				criteriaTemplatesList,
			},
		}
	}
