import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import DeleteConfirm from '#src/components/delete-confirm'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { criteriaKeys } from '#src/routes/calibrate/verticals/criteria/queries'
import { cn, validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { deleteVertical } from './mutations'
import { verticalKeys } from './queries'

export const action = async ({ params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['verticalId', 'ecosystemId'])
	const { company, companyId } = await getCompany(params)

	await deleteVertical(company.id, params.verticalId)

	await client.invalidateQueries({
		queryKey: verticalKeys.all,
	})
	void client.invalidateQueries({
		queryKey: ecosystemKeys.list(company.id),
	})
	void client.invalidateQueries({
		queryKey: criteriaKeys.all,
	})

	return redirect(
		routes.calibrate.verticals.index({
			companyId,
			ecosystemId: params.ecosystemId,
		}),
	)
}

export interface DeleteVerticalAsyncFormProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystemId: number
	verticalId: string
	children: ReactNode
}

export const DeleteVerticalAsyncForm = forwardRef<HTMLButtonElement, DeleteVerticalAsyncFormProps>(
	({ ecosystemId, verticalId, children, className, ...props }, ref) => {
		const { companyId } = useCompany()
		const action = routes.calibrate.verticals.delete({
			companyId,
			ecosystemId: ecosystemId.toString(),
			verticalId: verticalId,
		})

		return (
			<DeleteConfirm
				trigger={
					<button {...props} ref={ref} type="button" className={cn('flex items-center justify-center', className)}>
						{children}
					</button>
				}
				action={action}
				title="Delete vertical?"
				description="This action cannot be undone. This will permanently delete the vertical and remove any associated data such as personas."
			/>
		)
	},
)
DeleteVerticalAsyncForm.displayName = 'DeleteVerticalAsyncForm'
