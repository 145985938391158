import { type ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Button, type ButtonProps } from '#src/components/ui/button'
import { Dropdown, type DropdownContentProps, DropdownItem } from '#src/components/ui/dropdown'
import { Icon, type IconProps } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'

type DropdownButtonProps = {
	buttonProps?: ButtonProps
	iconProps?: IconProps
	contentProps?: DropdownContentProps
	title?: ReactNode
	items: {
		label: ReactNode
		to?: string
		onSelect?: (event: Event) => void
		asChild?: boolean
		className?: string
	}[]
}

export const DropdownButton = ({
	buttonProps,
	iconProps,
	contentProps = { side: 'bottom', align: 'start' },
	title,
	items,
}: DropdownButtonProps) => {
	return (
		<Dropdown
			trigger={
				<Button type="button" {...buttonProps} className={cn('flex items-center gap-1.5', buttonProps?.className)}>
					{title}
					{iconProps && <Icon {...iconProps} />}
				</Button>
			}
			contentProps={contentProps}
		>
			{items.map((item, index) =>
				item.to ? (
					<DropdownItem key={index} asChild={true} className={item.className}>
						<Link to={item.to}>{item.label}</Link>
					</DropdownItem>
				) : (
					<DropdownItem key={index} asChild={item.asChild} className={item.className} onSelect={item.onSelect}>
						{item.label}
					</DropdownItem>
				),
			)}
		</Dropdown>
	)
}
