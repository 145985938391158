import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { valuePropositionMutations } from '#src/api/icp/company/value-proposition/mutations'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ params }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['pointId'])

	try {
		await valuePropositionMutations.delete(company.id, Number(params.pointId))
	} catch (err) {
		captureException(err)
		showToast({
			message: 'Unexpected error while deleting value proposition point',
			type: 'error',
		})
	}

	return redirect(routes.calibrate.valueProposition.index({ companyId }))
}
