import { getFormProps, getInputProps, getSelectProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs, redirect, Form, Link } from 'react-router-dom'
import { type z } from 'zod'
import { ErrorList, Field, Select } from '#src/components/forms'
import { Button } from '#src/components/ui/button'
import { StatusButton } from '#src/components/ui/status-button'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { createEcosystem, createEcosystemFromTemplate, updateEcosystem } from './mutations'
import { ecosystemKeys } from './queries'
import { type EcosystemAPISchema, EcosystemFormSchema, type EcosystemTemplateListAPISchema } from './schema'

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)

	const formData = await request.formData()
	const intent = formData.get('intent')

	if (intent === 'ecosystem') {
		const submission = parseWithZod(formData, { schema: EcosystemFormSchema })

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		const { id, template, ...data } = submission.value

		if (template !== 'new' && template) {
			const response = await createEcosystemFromTemplate(company.id, {
				ecosystemId: template,
			})

			await client.invalidateQueries({
				queryKey: ecosystemKeys.all,
			})

			return redirect(
				routes.calibrate.verticals.index({
					companyId,
					ecosystemId: response.id.toString(),
				}),
			)
		} else if (id) {
			await updateEcosystem(company.id, Number(id), data)

			await client.invalidateQueries({
				queryKey: ecosystemKeys.all,
			})

			return redirect(
				routes.calibrate.verticals.index({
					companyId,
					ecosystemId: id,
				}),
			)
		} else {
			const response = await createEcosystem(company.id, data)

			await client.invalidateQueries({
				queryKey: ecosystemKeys.all,
			})

			return redirect(
				routes.calibrate.verticals.create({
					companyId,
					ecosystemId: response.id.toString(),
				}),
			)
		}
	}

	return redirect(
		routes.calibrate.index({
			companyId,
		}),
	)
}

export function EcosystemForm({
	ecosystem,
	ecosystemTemplates,
	isKeyAccounts,
}: {
	ecosystem: z.infer<typeof EcosystemAPISchema> | null
	ecosystemTemplates: z.infer<typeof EcosystemTemplateListAPISchema>
	isKeyAccounts?: boolean
}) {
	const { companyId } = useCompany()
	const action = ecosystem?.id
		? routes.calibrate.ecosystem.save({
				companyId,
				ecosystemId: ecosystem.id.toString(),
			})
		: routes.calibrate.ecosystem.save({
				companyId,
				ecosystemId: null,
			})
	const method = ecosystem?.id ? 'PATCH' : 'POST'

	const isPending = useIsPending({ formAction: action, formMethod: method })

	const defaultValue = {
		id: ecosystem?.id,
		name: ecosystem?.name ?? '',
		template: 'new',
		verticalAsCompany: ecosystem?.verticalAsCompany || isKeyAccounts ? 'true' : 'false',
	}

	const [form, fields] = useForm({
		id: ecosystem?.id ? 'ecosystem-form-' + ecosystem.id : 'ecosystem-form',
		constraint: getZodConstraint(EcosystemFormSchema),
		defaultValue,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: EcosystemFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	return (
		<Form method={method} {...getFormProps(form)} className="flex flex-col gap-4">
			<input {...getInputProps(fields.id, { type: 'hidden' })} />
			<input {...getInputProps(fields.verticalAsCompany, { type: 'hidden' })} />

			{!ecosystem && !isKeyAccounts ? (
				<Select
					labelProps={{ children: 'Select a template or start from scratch' }}
					inputProps={{
						...getSelectProps(fields.template),
						defaultValue: fields.template.initialValue,
						value: fields.template.value,
						placeholder: 'Select template',
					}}
					options={[{ value: 'new', label: 'Create Ecosystem' }].concat(
						ecosystemTemplates.map(template => ({
							value: template.id.toString(),
							label: template.name,
						})),
					)}
				/>
			) : null}

			<Field
				labelProps={{ children: 'Name' }}
				inputProps={{
					...getInputProps(fields.name, { type: 'text' }),
					placeholder: 'Enter here',
				}}
				errors={fields.name.errors}
			/>

			<ErrorList errors={form.errors} id={form.errorId} />

			<div className="flex items-center justify-end gap-2 pt-4">
				<Button className="min-w-24" size="sm" variant="outline" disabled={isPending} asChild>
					<Link
						to={routes.calibrate.index({
							companyId: companyId!,
						})}
					>
						Cancel
					</Link>
				</Button>
				<StatusButton
					className="min-w-24"
					size="sm"
					status={isPending ? 'pending' : 'idle'}
					type="submit"
					name="intent"
					value="ecosystem"
					disabled={isPending}
					formAction={action}
				>
					{ecosystem ? 'Update' : 'Create'}
				</StatusButton>
			</div>
		</Form>
	)
}
