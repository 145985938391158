import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { copyPersonas } from '#src/routes/calibrate/personas/mutations'
import { personaKeys } from '#src/routes/calibrate/personas/queries'
import { CopyPersonaFormSchema } from '#src/routes/calibrate/personas/schema'
import { verticalKeys } from '#src/routes/calibrate/verticals/queries'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const copyPersonasAction = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId'])
	const { company, companyId } = await getCompany(params)

	const formData = await request.formData()

	const submission = parseWithZod(formData, {
		schema: CopyPersonaFormSchema,
	})

	if (submission.status !== 'success') {
		throw Error('Failed to parse form data')
	}

	await copyPersonas(company.id, {
		personas: submission.value.personas,
		targetVerticals: submission.value.targetVerticals,
	})

	await client.invalidateQueries({
		queryKey: verticalKeys.all,
	})

	await client.invalidateQueries({
		queryKey: ecosystemKeys.all,
	})

	await client.invalidateQueries({
		queryKey: personaKeys.all,
	})

	showToast({
		message: 'Personas successfully copied',
		type: 'success',
	})

	return redirect(
		routes.calibrate.verticals.index({
			companyId,
			ecosystemId: params.ecosystemId,
		}),
	)
}
