import { type RouteObject } from 'react-router-dom'
import { type z } from 'zod'
import { DialogFooterWarning } from '#src/components/DialogFooterWarning'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/verticals/criteria/constants'
import { routes } from '#src/utils/routes'
import { action as criterionAssignAction } from './actions/assign'
import { action as criterionUnassignAction } from './actions/unassign'
import { ModalLayout, type ModalHandle } from './layouts'
import { loader as modalLayoutLoader } from './loaders/modalLayout'
import { CriteriaDetailsView } from './routes/details'
import { criteriaEditAnswersAction, criteriaEditAnswersLoader, CriteriaEditView } from './routes/editAnswers'
import { criteriaListLoader, CriteriaListView } from './routes/list'
import { criteriaSaveAction, criteriaSaveLoader, type CriteriaSaveLoaderType, CriteriaSaveView } from './routes/save'

type RouteObjectWithHandle = RouteObject & {
	handle?: ModalHandle
}

function createCriteriaRoutes(criterionType: z.infer<typeof CRITERION_TYPE_ENUM>): RouteObjectWithHandle[] {
	return [
		{
			path: 'answers',
			loader: criteriaEditAnswersLoader(criterionType),
			action: criteriaEditAnswersAction(criterionType),
			element: <CriteriaEditView key={criterionType} />,
		},
		{
			loader: modalLayoutLoader(criterionType),
			element: <ModalLayout />,
			children: [
				{
					path: 'list',
					loader: criteriaListLoader(criterionType),
					element: <CriteriaListView />,
					handle: {
						getDialogConfig: () => ({
							footer: null,
							contentProps: { disableScrollbar: true, className: 'h-[90vh]' },
						}),
					},
				},
				{
					path: 'new',
					loader: criteriaSaveLoader(criterionType),
					action: criteriaSaveAction(criterionType),
					element: <CriteriaSaveView />,
					handle: {
						getDialogConfig: (data: CriteriaSaveLoaderType) => ({
							actions: [
								{
									type: 'cancel',
									label: 'Back',
									to: routes.calibrate.criteria.list({
										companyId: data.values.companyId,
										ecosystemId: data.values.ecosystemId,
										criterionType: data.values.type,
									}),
								},
								{
									type: 'submit',
									label: 'Use Criterion',
									formId: 'saveCriterionForm',
									name: 'submitAction',
									value: 'update-close',
								},
							],
						}),
					},
				},
				{
					path: ':criterionId',
					children: [
						{
							path: 'new',
							loader: criteriaSaveLoader(criterionType),
							action: criteriaSaveAction(criterionType),
							element: <CriteriaSaveView />,
							handle: {
								getDialogConfig: (data: CriteriaSaveLoaderType) => ({
									actions: [
										{
											type: 'cancel',
											label: 'Back',
											to: routes.calibrate.criteria.list({
												companyId: data.values.companyId,
												ecosystemId: data.values.ecosystemId,
												criterionType: data.values.type,
											}),
										},
										{
											type: 'submit',
											label: 'Use Criterion',
											formId: 'saveCriterionForm',
											name: 'submitAction',
											value: 'update-close',
										},
									],
								}),
							},
						},
						{
							path: 'edit',
							loader: criteriaSaveLoader(criterionType),
							action: criteriaSaveAction(criterionType),
							element: <CriteriaSaveView />,
							handle: {
								getDialogConfig: (data: CriteriaSaveLoaderType) => ({
									actions: [
										{
											type: 'submit',
											label: 'Update & Close',
											formId: 'saveCriterionForm',
											variant: 'outline',
											name: 'submitAction',
											value: 'update-close',
										},
										{ type: 'submit', label: 'Update', formId: 'saveCriterionForm' },
									],
									footerInfo: data.data.criterion?.ecosystems.length && (
										<DialogFooterWarning
											count={data.data.criterion.ecosystems.length}
											entityName="Ecosystem"
											tooltipLabel={data.data.criterion.ecosystems.join(', ')}
											message="will be affected by changes"
										/>
									),
								}),
							},
						},
						{
							path: 'assign',
							action: criterionAssignAction(criterionType),
						},
						{
							path: 'unassign',
							action: criterionUnassignAction(criterionType),
						},
						{
							path: 'details',
							loader: criteriaSaveLoader(criterionType),
							element: <CriteriaDetailsView />,
							handle: {
								getDialogConfig: (data: CriteriaSaveLoaderType) => ({
									actions: [
										{
											type: 'cancel',
											label: 'Back',
											to: routes.calibrate.criteria.list({
												companyId: data.values.companyId,
												ecosystemId: data.values.ecosystemId,
												criterionType: data.values.type,
											}),
										},
										{
											type: 'submit',
											label: 'Use Criterion',
											formId: 'assignCriterionForm',
											name: 'submitAction',
											value: 'update-close',
										},
									],
								}),
							},
						},
					],
				},
			],
		},
	]
}

export default {
	path: 'criteria',
	children: [
		{
			path: 'qualification',
			children: createCriteriaRoutes('qualification'),
		},
		{
			path: 'enrichment',
			children: createCriteriaRoutes('enrichment'),
		},
	],
} satisfies RouteObjectWithHandle
