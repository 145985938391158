import { useMemo } from 'react'
import { useLoaderData } from 'react-router-dom'
import ProductTip from '#src/components/product-tip'
import { DropdownButton } from '#src/components/ui/DropdownButton'
import { PageLayout } from '#src/components/ui/PageLayout'
import { TabSwitcher } from '#src/components/ui/tabs'
import useCompany from '#src/hooks/useCompany'
import { type IndexLoaderRes } from '#src/routes/calibrate/dashboard/routes'
import { EcosystemsList, Cards } from '#src/routes/calibrate/dashboard/views/components'
import { routes } from '#src/utils/routes'

export const View = () => {
	const { companyId } = useCompany()
	const {
		data: { ecosystems },
	} = useLoaderData() as IndexLoaderRes

	const tabs = useMemo(() => {
		const globalEcosystems = ecosystems.filter(item => !item.verticalAsCompany)
		const keyAccountsEcosystems = ecosystems.filter(item => item.verticalAsCompany)

		return [
			{
				label: (
					<span className="flex items-center gap-1.5">
						Ecosystems
						<ProductTip
							className="text-content-neutral-tertiary text-body-sm font-normal"
							content="Ecosystems: are networks of verticals with common goals and aligned customer targets, where ICPs  shape interactions and shared expertise, customer bases, and strategies."
						/>
					</span>
				),
				value: 'ecosystems',
				content: <EcosystemsList ecosystems={globalEcosystems} type="ecosystems" />,
			},
			//TODO: Make always visible after migration
			...(keyAccountsEcosystems.length
				? [
						{
							label: 'Key Accounts',
							value: 'key-accounts',
							content: <EcosystemsList ecosystems={keyAccountsEcosystems} type="keyAccounts" />,
						},
					]
				: []),
		]
	}, [ecosystems])

	return (
		<PageLayout
			title="Centralized Intelligence"
			description="This unique dataset is a foundation for all Evergrowth agents and features. Calibrating your Value Proposition, Verticals, ICP's, and IBP's will affect the outcome of Evergrowth platform."
		>
			<Cards />
			<TabSwitcher
				tabs={tabs}
				actions={[
					<DropdownButton
						title="New"
						buttonProps={{ size: 'sm' }}
						iconProps={{ name: 'carret-down' }}
						key="new"
						items={[
							{
								label: 'Ecosystem',
								to: routes.calibrate.ecosystem.create({ companyId }),
							},
							//TODO: Enable after migration
							...(import.meta.env.VITE_APP_ENVIRONMENT !== 'production'
								? [
										{
											label: 'Key Accounts',
											to: routes.calibrate.ecosystem.createKeyAccounts({ companyId }),
										},
									]
								: []),
						]}
					/>,
				]}
			/>
		</PageLayout>
	)
}
