import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { type z } from 'zod'
import { type PersonaSignalsAPISchema } from '#src/api/icp/company/persona/schemas'
import { type PlaysListItemAPISchema } from '#src/api/icp/company/plays/schemas'
import { EmptyStateCard } from '#src/components'
import Markdown from '#src/components/markdown'
import { AccordionRoot } from '#src/components/ui/accordion'
import { FullPageAccordion } from '#src/components/ui/FullPageAccordion'
import { InlineError } from '#src/components/ui/InlineError'
import { InlineSpinner } from '#src/components/ui/InlineSpinner'
import { TabSwitcher } from '#src/components/ui/tabs'
import useCompany from '#src/hooks/useCompany'
import { type PersonaAPISchema } from '#src/routes/calibrate/personas/schema'
import { renderCriterionAnswerValue } from '#src/routes/calibrate/verticals/criteria/utils'
import { type VerticalAPISchema } from '#src/routes/calibrate/verticals/schema'
import PlayAccordion from '#src/routes/enable/plays/views/components/PlayAccordion'
import { SignalUsageExample } from '#src/routes/prioritize/views/components/item/SignalUsageExample'
import { routes } from '#src/utils/routes'
import { PersonaCriteriaAside, PersonaAIGeneratingBody, PersonaMappingNavigator, PersonaSalesCoachSection } from './'

type PersonasDetailsBodyProps = {
	persona: z.infer<typeof PersonaAPISchema>
	vertical?: z.infer<typeof VerticalAPISchema>
	plays: z.infer<typeof PlaysListItemAPISchema>
	intents: z.infer<typeof PersonaSignalsAPISchema>
}

export const PersonasDetailsBody = (props: PersonasDetailsBodyProps) => {
	const { persona, vertical, plays, intents } = props
	const { companyId } = useCompany()

	const isVerticalPromptGenerating = vertical?.prompt?.status !== 'Completed' && vertical?.prompt?.status !== 'Crashed'
	const hasVerticalPromptCrashed = vertical?.prompt?.status === 'Crashed'

	const isPersonaPromptGenerating = persona?.prompt?.status !== 'Completed' && persona?.prompt?.status !== 'Crashed'
	const hasPersonaPromptCrashed = persona?.prompt?.status === 'Crashed'

	const criteriaList = useMemo(() => {
		const filtered = vertical?.answers?.filter(item => !item.isEnrichment)

		return (
			filtered?.map(item => ({
				value: renderCriterionAnswerValue(item),
				label: <div className="flex items-center gap-1">{item.question}</div>,
			})) || []
		)
	}, [vertical?.answers])

	const enrichmentCriteriaList = useMemo(() => {
		const filtered = vertical?.answers?.filter(item => item.isEnrichment)

		return (
			filtered?.map(item => ({
				value: renderCriterionAnswerValue(item),
				label: <div className="flex items-center gap-1">{item.question}</div>,
			})) || []
		)
	}, [vertical?.answers])

	const personaMappingItems = useMemo(
		() => [
			{
				id: 'responsibilities',
				heading: 'Responsibilities',
				value: persona.personaMapping?.responsibilities,
			},
			{ id: 'kpis', heading: 'KPIs', value: persona.personaMapping?.kpis },
			{
				id: 'frustrations',
				heading: 'Frustrations',
				value: persona.personaMapping?.frustrations,
			},
			{
				id: 'value-props',
				heading: 'Value Propositions',
				value: persona.personaMapping?.valuePropositions,
			},
			{
				id: 'objections-concerns',
				heading: 'Objections/Concerns',
				value: persona.personaMapping?.objectionsConcerns,
			},
			{
				id: 'objection-addressing',
				heading: 'Objection Addressing',
				value: persona.personaMapping?.objectionAddressing,
			},
			{
				id: 'buyer-journey-info',
				heading: 'Buyer Journey Info',
				value: persona.personaMapping?.buyerJourneyInfo,
			},
			{
				id: 'buyer-journey-use-cases',
				heading: 'Buyer Journey Use Cases',
				value: persona.personaMapping?.buyerJourneyUseCases,
			},
			{
				id: 'gain-information-from',
				heading: 'Gain Information From',
				value: persona.personaMapping?.gainInformationFrom,
			},
		],
		[persona.personaMapping],
	)

	const renderCompanyProfileContent = () => (
		<section className="grid grid-cols-3 gap-8">
			<div className="col-span-2 flex flex-col gap-6">
				<h3 className="text-title-md">Business model description</h3>
				{hasVerticalPromptCrashed ? (
					<InlineError textVariant="output" size="md" />
				) : isVerticalPromptGenerating ? (
					<InlineSpinner />
				) : (
					<Markdown className="text-neutral-2-fg">{vertical?.bizDescription}</Markdown>
				)}
			</div>
			{(!!criteriaList.length || !!enrichmentCriteriaList.length) && (
				<PersonaCriteriaAside
					sections={[
						...(criteriaList.length ? [{ title: 'Qualification Criteria', items: criteriaList }] : []),
						...(enrichmentCriteriaList.length
							? [
									{
										title: 'Enrichment Criteria',
										items: enrichmentCriteriaList,
									},
								]
							: []),
					]}
				/>
			)}
		</section>
	)

	const renderPersonaInfoTabs = () => (
		<TabSwitcher
			tabs={
				[
					{
						label: 'Summary',
						value: 'persona-summary',
						content: (
							<section className="mt-4">
								{!isPersonaPromptGenerating ? (
									<Markdown>{persona.summary}</Markdown>
								) : (
									<InlineSpinner textVariant="output" />
								)}
							</section>
						),
					},
					{
						label: 'Persona mapping',
						value: 'persona-mapping',
						content: (
							<section className="mt-4 grid grid-cols-3 gap-8">
								<div className="col-span-2 flex flex-col gap-6">
									<PersonaAIGeneratingBody
										isGenerating={isPersonaPromptGenerating}
										hasCrashed={hasPersonaPromptCrashed}
										items={personaMappingItems}
									/>
								</div>
								<PersonaMappingNavigator items={personaMappingItems} />
							</section>
						),
					},
					{
						label: 'Pain points',
						value: 'persona-pain-points',
						content: (
							<section className="mt-4">
								{!isPersonaPromptGenerating ? (
									<Markdown>{persona.pain}</Markdown>
								) : (
									<InlineSpinner textVariant="output" />
								)}
							</section>
						),
					},
					{
						label: 'Sales coach',
						value: 'persona-sales-coach',
						content: (
							<section className="mt-4">
								<PersonaSalesCoachSection persona={persona} />
							</section>
						),
					},
					{
						label: 'Boolean eg. for LinkedIn Sales Nav.',
						value: 'persona-mapping.boolean-linkedin',
						content: (
							<section className="mt-4">
								{!isPersonaPromptGenerating ? (
									<Markdown>{persona.personaMapping?.boolLinkedin}</Markdown>
								) : (
									<InlineSpinner textVariant="output" />
								)}
							</section>
						),
					},
					{
						label: (
							<div className="flex flex-row items-center gap-1">
								<p>Intents</p>
								<p className="h-4 min-w-4 rounded bg-brand-3-bg px-1 text-center text-[10px] font-bold leading-[16px] text-neutral-inverse-fg">
									{intents.length}
								</p>
							</div>
						),
						value: 'intents',
						content: (
							<section className="p-10">
								{intents.length ? (
									<div className="flex flex-col gap-4">
										{intents.map(item => (
											<div
												key={`intent-${item.id}`}
												className="flex flex-row gap-2 rounded border border-neutral-1-bd p-6"
											>
												<Link
													to={routes.prioritize.signal.index({
														companyId,
														signalId: String(item.id),
													})}
													className="flex-1 text-title-sm text-neutral-1-fg"
												>
													{item.name}
												</Link>
												<SignalUsageExample
													signalId={String(item.id)}
													personaId={String(persona.id)}
													personaJobTitle={persona.jobTitles ?? persona.expertise ?? ''}
												/>
											</div>
										))}
									</div>
								) : (
									<EmptyStateCard
										icon="upstream"
										title="This persona does not have mapped intents"
										actions={[
											{
												to: routes.prioritize.index({ companyId }),
												title: 'Map intents',
											},
										]}
									/>
								)}
							</section>
						),
					},
					{
						label: (
							<div className="flex flex-row items-center gap-1">
								<p>Plays</p>
								<p className="h-4 min-w-4 rounded bg-brand-3-bg px-1 text-center text-[10px] font-bold leading-[16px] text-neutral-inverse-fg">
									{plays.length}
								</p>
							</div>
						),
						value: 'plays',
						content: (
							<section className="mt-4">
								{plays.length ? (
									<AccordionRoot type="multiple" className="flex flex-col gap-4">
										{plays.map(item => (
											<PlayAccordion key={item.id} play={item} preview />
										))}
									</AccordionRoot>
								) : (
									<EmptyStateCard
										icon="continue"
										title="This persona does not have related plays"
										actions={[
											{
												to: routes.enable.plays.create({ companyId }),
												title: 'Create new play',
											},
										]}
									/>
								)}
							</section>
						),
					},
				] as const
			}
		/>
	)

	return (
		<FullPageAccordion
			defaultExpandedSections={['2']}
			sections={[
				{
					id: '1',
					header: 'Company profile',
					content: renderCompanyProfileContent(),
				},
				{
					id: '2',
					header: 'Persona info',
					content: renderPersonaInfoTabs(),
				},
			]}
		/>
	)
}
