import * as PopoverPrimitive from '@radix-ui/react-popover'
import { type ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'
import { type z } from 'zod'
import { EmptyStateCard } from '#src/components'
import { transformEcosystemsToAccordionData } from '#src/components/ecosystems-accordion'
import EcosystemsSort from '#src/components/ecosystems-sort'
import { PersonaData } from '#src/components/persona'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { DeleteEcosystemAsyncForm } from '#src/routes/calibrate/ecosystem/delete'
import {
	type EcosystemAPISchema,
	type EcosystemAPIVerticalSchema,
	type EcosystemListAPISchema,
} from '#src/routes/calibrate/ecosystem/schema'
import { SortEcosystemsAsyncForm } from '#src/routes/calibrate/ecosystem/sort'
import { autofocusVerticalMutation } from '#src/routes/calibrate/verticals/autofocus'
import { routes } from '#src/utils/routes'

type EcosystemType = 'ecosystems' | 'keyAccounts'

function NoEcosystems({ type }: { type: EcosystemType }) {
	const { company } = useCompany()

	return (
		<Surface className="flex min-h-[260px] flex-col items-center justify-center gap-4">
			<Icon name="ibm-cloud-pak" size="xl" className="text-neutral-3-fg" />
			<h1 className="text-body-lg text-neutral-2-fg">
				{`You don't have any ${type === 'keyAccounts' ? 'key accounts' : 'ecosystems'} yet`}
			</h1>
			<div>
				<Button asChild variant="outline" className="flex flex-nowrap items-center gap-1">
					<Link
						to={
							type === 'keyAccounts'
								? routes.calibrate.ecosystem.createKeyAccounts({
										companyId: company.id,
									})
								: routes.calibrate.ecosystem.create({
										companyId: company.id,
									})
						}
					>
						<Icon name="add" /> {`Add ${type === 'keyAccounts' ? 'Key Accounts' : 'Ecosystem'}`}
					</Link>
				</Button>
			</div>
		</Surface>
	)
}

function EcosystemData({ ecosystem }: { ecosystem: z.infer<typeof EcosystemAPISchema> }) {
	const { company } = useCompany()
	const { readOnlySession } = useAuth()
	const title = `${ecosystem.verticalAsCompany ? 'these key accounts' : 'this ecosystem'}`

	return ecosystem.verticals?.length ? (
		<section className="grid auto-rows-max grid-cols-3 gap-x-5 gap-y-8">
			{ecosystem.verticals.map(vertical => (
				<VerticalData key={vertical.id} vertical={vertical} ecosystemId={ecosystem.id} />
			))}
		</section>
	) : (
		<EmptyStateCard
			icon="category"
			title={`You don't have any verticals and personas for ${title} yet`}
			actions={
				!readOnlySession
					? [
							{
								title: 'Add vertical',
								to: routes.calibrate.verticals.create({
									companyId: company.id,
									ecosystemId: ecosystem.id.toString(),
								}),
							},
						]
					: []
			}
		/>
	)
}

function VerticalData({
	ecosystemId,
	vertical,
}: {
	ecosystemId: number
	vertical: z.infer<typeof EcosystemAPIVerticalSchema>
}) {
	const { company } = useCompany()

	const { readOnlySession } = useAuth()

	return (
		<div className="space-y-3">
			<div className="grid grid-cols-[1fr,max-content] grid-rows-1 overflow-hidden border-b border-b-neutral-1-bd pb-0.5 pl-2">
				<Link
					to={routes.calibrate.verticals.index({
						companyId: company.id,
						ecosystemId: ecosystemId.toString(),
					})}
					className="truncate"
					onClick={() => autofocusVerticalMutation(vertical.id.toString())}
				>
					<span className="text-label-md transition-colors hover:text-brand-1-fg">{vertical.name}</span>{' '}
					<span className="text-body-sm text-neutral-3-fg">{`${vertical.personas?.length ?? 0}`}</span>
				</Link>

				{readOnlySession ? null : (
					<Link
						to={routes.calibrate.persona.edit({
							companyId: company.id,
							ecosystemId: ecosystemId.toString(),
							verticalId: vertical.id.toString(),
							personaId: vertical.personas?.[0]?.id.toString() ?? null,
						})}
						className="flex h-5 w-5 items-center justify-center rounded-sm text-label-sm font-normal text-neutral-3-fg outline-none transition-colors hover:bg-neutral-1-bg-hover"
					>
						<Icon name="add" size="sm" />
					</Link>
				)}
			</div>
			<div className="space-y-4">
				{vertical.personas?.length ? (
					vertical.personas.map(persona => (
						<PersonaData
							key={persona.id}
							id={persona.id}
							type={persona.type}
							status={persona.status}
							priority={persona.priority}
							expertise={persona.expertise}
							ecosystemId={ecosystemId}
							verticalId={vertical.id.toString()}
						/>
					))
				) : readOnlySession ? null : (
					<Link
						to={routes.calibrate.persona.edit({
							companyId: company.id,
							ecosystemId: ecosystemId.toString(),
							verticalId: vertical.id.toString(),
							personaId: vertical.personas?.[0]?.id.toString() ?? null,
						})}
						className="flex flex-nowrap items-center gap-1 bg-transparent py-2 text-button-sm text-link hover:text-link-hover active:text-link-pressed"
					>
						<Icon name="add" /> Add Persona
					</Link>
				)}
			</div>
		</div>
	)
}

function EcosystemContextMenu({ ecosystem }: { ecosystem: z.infer<typeof EcosystemAPISchema> }) {
	const { companyId } = useCompany()
	const [open, setOpen] = useState(false)
	return (
		<PopoverPrimitive.Root open={open} onOpenChange={setOpen}>
			<PopoverPrimitive.Trigger asChild>
				<button
					type="button"
					className="flex h-5 w-5 items-center justify-center rounded-sm text-label-sm font-normal text-neutral-3-fg outline-none transition-colors hover:bg-neutral-2-bg-hover"
				>
					<Icon name="overflow-menu-horizontal" size="sm" />
				</button>
			</PopoverPrimitive.Trigger>

			<PopoverPrimitive.Portal>
				<PopoverPrimitive.Content
					updatePositionStrategy={undefined}
					hideWhenDetached={false}
					className="z-50 flex max-h-[20rem] max-w-[20rem] flex-col overflow-hidden overflow-y-auto rounded border border-neutral-1-bd bg-neutral-1-bg p-2 text-body-md text-neutral-2-fg shadow"
					side="bottom"
					sideOffset={5}
					align="start"
					collisionPadding={20}
				>
					<Link
						to={routes.calibrate.ecosystem.edit({
							companyId,
							ecosystemId: String(ecosystem.id),
						})}
						className="flex select-none items-center gap-2 rounded-sm px-2 py-1.5 hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover"
						onClick={() => setOpen(false)}
					>
						<Icon name="pencil-1" size="sm" />
						Edit
					</Link>

					<Link
						to={routes.calibrate.verticals.create({
							companyId,
							ecosystemId: String(ecosystem.id),
						})}
						className="flex select-none items-center gap-2 rounded-sm px-2 py-1.5 hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover"
						onClick={() => setOpen(false)}
					>
						<Icon name="category-new" size="sm" />
						{`Add ${ecosystem.verticalAsCompany ? 'Key Account' : 'Vertical'}`}
					</Link>

					<DeleteEcosystemAsyncForm ecosystem={ecosystem}>
						<div className="flex select-none items-center gap-2 rounded-sm px-2 py-1.5 hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover">
							<Icon name="trash" size="sm" />
							{`Delete ${ecosystem.verticalAsCompany ? 'Key Accounts' : 'Ecosystem'}`}
						</div>
					</DeleteEcosystemAsyncForm>
				</PopoverPrimitive.Content>
			</PopoverPrimitive.Portal>
		</PopoverPrimitive.Root>
	)
}

function Section({
	title,
	ctaOrSubtitle,
	children,
}: {
	title?: ReactNode
	ctaOrSubtitle: ReactNode | null
	children: ReactNode
}) {
	return (
		<section className="flex w-full flex-col gap-4">
			<div className="grid grid-cols-2 grid-rows-1 items-center">
				<h2 className="text-label-lg text-neutral-3-fg">{title ?? ''}</h2>
				{ctaOrSubtitle ? <div className="justify-self-end">{ctaOrSubtitle}</div> : null}
			</div>
			<div>{children}</div>
		</section>
	)
}

type EcosystemsListProps = {
	ecosystems: z.infer<typeof EcosystemListAPISchema>
	type: EcosystemType
}

export const EcosystemsList = ({ ecosystems, type }: EcosystemsListProps) => {
	const { readOnlySession } = useAuth()
	const [reorder, setReorder] = useState(false)
	const [order, setOrder] = useState(ecosystems.map(({ id }) => id))

	return (
		<Section
			ctaOrSubtitle={
				readOnlySession ? null : (
					<div className="flex items-center gap-2">
						<SortEcosystemsAsyncForm
							enabled={reorder}
							toggleEnabled={() => setReorder(!reorder)}
							order={order}
							onError={() => setOrder(ecosystems.map(({ id }) => id))}
							onCancel={() => setOrder(ecosystems.map(({ id }) => id))}
						/>
					</div>
				)
			}
		>
			{ecosystems?.length ? (
				<EcosystemsSort
					ecosystems={transformEcosystemsToAccordionData(
						ecosystems.map(es => ({
							...es,
							content: <EcosystemData ecosystem={es} />,
							contextMenu: readOnlySession ? null : <EcosystemContextMenu ecosystem={es} />,
						})),
					)}
					order={order}
					onReorder={setOrder}
					enabled={reorder}
				/>
			) : (
				<NoEcosystems type={type} />
			)}
		</Section>
	)
}
