import { z } from 'zod'
import { PersonalitySchema } from '#src/api/icp/company/chat/roleplay/session/schemas'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { zNumber } from '#src/utils/validation'

export enum Status {
	WaitingForContact = 'waiting_for_contact',
	Closed = 'closed',
	Ready = 'ready',
	Initializing = 'initializing',
	Crashed = 'crashed',
}

export const StatusSchema = z.enum([
	Status.WaitingForContact,
	Status.Closed,
	Status.Ready,
	Status.Initializing,
	Status.Crashed,
])

export const Participant = z.object({
	id: z.string(),
	status: StatusSchema,
	username: z.string(),
	createdAt: z.string(),
	completedAt: z.string().nullable(),
	attempts: z.number(),
})

export const ScenarioAPISchema = z.object({
	id: z.string(),
	// NOTE: this is user's custom name for scenario
	name: z.string(),
	// NOTE: this is scenario name from dropdown list
	title: z.string(),
	// NOTE: this is scenario tuning
	scenario: z.string().optional().nullable(),
	personaId: z.number(),
	templateId: z.number().nullable(),
	personaMapping: z
		.object({
			ecosystem: z.string(),
			vertical: z.string(),
			personaType: z.string(),
			expertise: z.string(),
		})
		.nullable(),
	linkedinUrl: z.string().optional().nullable(),
	contact: z.string().optional().nullable(),
	personality: PersonalitySchema,
	evaluation: z.string().optional().nullable(),
	instructions: z.string(),
	sessions: Participant.array(),
	createdBy: z.string(),
	createdAt: z.string(),
	completed: z.number(),
	total: z.number(),
})

export const ScenariosAPISchema = PaginatedAPIResSchema(ScenarioAPISchema.omit({ sessions: true }))

export const ScenarioTemplateAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	scenario: z.string().optional().nullable(),
	evaluation: z.string().optional(),
})
export const ScenarioTemplatesAPISchema = ScenarioTemplateAPISchema.array()

export const VoiceAPISchema = z.object({
	voiceId: z.string(),
	name: z.string(),
})

export const VoicesAPISchema = VoiceAPISchema.array()

export const CreateScenarioTestSessionSchema = z.object({
	conversationId: z.string(),
	company: z.string(),
})
export const AssignedUserSchema = z.object({
	id: z.number(),
	username: z.string(),
	assigned: z.boolean(),
})
export const AssignedUsersAPISchema = AssignedUserSchema.array()
export const ScenarioFormSchema = z.object({
	name: z.string(),
	ecosystemId: z.number(),
	verticalId: z.number(),
	personaId: z.number(),
	linkedinUrl: z.string().url().optional(),
	contactId: z.string().optional(),
	personality: PersonalitySchema,
	template: ScenarioTemplateAPISchema,
	evaluation: z.string().optional(),
	instructions: z.string(),
	scenario: z.string().optional().nullable(),
})
export const EditScenarioFormSchema = ScenarioFormSchema.omit({
	ecosystemId: true,
	verticalId: true,
	template: true,
}).merge(
	z.object({
		templateId: z.number(),
	}),
)
export const CreateScenarioFormSchema = ScenarioFormSchema.omit({
	template: true,
}).merge(
	z.object({
		templateId: z.number(),
		scenario: z.string().optional().nullable(),
		linkedinUrl: z.string().url().optional(),
		contactId: z.string().optional(),
	}),
)
export type CreateScenarioFormSchemaType = z.infer<typeof CreateScenarioFormSchema>
export const ScenarioFormPayloadSchema = ScenarioFormSchema.omit({
	ecosystemId: true,
	verticalId: true,
	template: true,
}).merge(
	z.object({
		scenario: z.string().optional().nullable(),
		templateId: z.number(),
	}),
)
export const ScenarioInviteFormSchema = z.object({
	members: z.array(zNumber(z.number())).nonempty('You need to select at least one participant'),
})
