import { type ReactNode } from 'react'
import { useFetcher } from 'react-router-dom'
import { type z } from 'zod'
import { type ContactWaterfallingItemAPISchema } from '#src/api/organise/data-enrichment/workflows/schemas'
import DeleteConfirm from '#src/components/delete-confirm'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'
import { type LoaderRes } from '../../loaders/stop'

type Props = {
	jobId: z.infer<typeof ContactWaterfallingItemAPISchema.shape.id>
	children: ReactNode
}

export const JobStopModal = (props: Props) => {
	const { jobId, children } = props
	const loaderFetcher = useFetcher<LoaderRes>()
	const { companyId } = useCompany()

	const onOpenChange = (data: boolean) => {
		if (data) {
			if (loaderFetcher.state === 'idle' && !loaderFetcher.data) {
				loaderFetcher.load(routes.organize.contactWaterfalling.stop({ jobId, companyId }))
			}
		}
	}

	return (
		<DeleteConfirm
			trigger={children}
			action={routes.organize.contactWaterfalling.stop({ companyId, jobId })}
			actionTitle="Stop job"
			title={`Stop "${loaderFetcher.data?.data.job.title ?? '...'}"?`}
			description={
				<>
					Are you sure you want to stop the <b>{loaderFetcher.data?.data.job.title ?? '...'}</b>?
				</>
			}
			onOpenChange={onOpenChange}
		/>
	)
}
