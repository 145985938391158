import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch, safeFetchMeta } from '#src/utils/safeFetch'
import { companyChatRoleplaySessionEndpoints } from './endpoints'
import { SessionsAPISchema, SessionsFiltersAPISchema, SessionAPISchema, SessionStatusAPISchema } from './schemas'

const keys = {
	all: ['icp', 'company', 'chat', 'roleplay', 'session'] as const,
	list: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		page: number,
		sort: string[],
		filters: Record<string, string | string[]>,
	) => [...keys.all, 'list', companyId, page, ...sort, JSON.stringify(filters)] as const,
	listFilters: (companyId: z.infer<typeof CompanySchema.shape.id>) => [...keys.all, 'list-filters', companyId],
	item: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		sessionId: z.infer<typeof SessionAPISchema.shape.conversation.shape.id>,
	) => [...keys.all, 'item', companyId, sessionId] as const,
	itemStatus: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		sessionId: z.infer<typeof SessionAPISchema.shape.conversation.shape.id>,
	) => [...keys.all, 'item', 'status', companyId, sessionId] as const,
}

const listQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	page: number,
	sort: string[],
	filters: Record<string, string | string[]>,
) => ({
	queryKey: keys.list(companyId, page, sort, filters),
	queryFn: async () =>
		await safeFetchMeta(SessionsAPISchema, companyChatRoleplaySessionEndpoints.list(companyId, page, sort, filters), {
			method: 'GET',
		}),
})

const listFiltersQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) => ({
	queryKey: keys.listFilters(companyId),
	queryFn: async () =>
		await safeFetch(SessionsFiltersAPISchema, companyChatRoleplaySessionEndpoints.listFilters(companyId), {
			method: 'GET',
		}),
})

const itemQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	sessionId: z.infer<typeof SessionAPISchema.shape.conversation.shape.id>,
) => ({
	queryKey: keys.item(companyId, sessionId),
	queryFn: async () =>
		await safeFetch(SessionAPISchema, companyChatRoleplaySessionEndpoints.item(companyId, sessionId), {
			method: 'GET',
		}),
})

const itemStatusQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	sessionId: z.infer<typeof SessionAPISchema.shape.conversation.shape.id>,
) => ({
	queryKey: keys.itemStatus(companyId, sessionId),
	queryFn: async () =>
		await safeFetch(SessionStatusAPISchema, companyChatRoleplaySessionEndpoints.itemStatus(companyId, sessionId), {
			method: 'GET',
		}),
})

const queries = {
	list: listQuery,
	listFilters: listFiltersQuery,
	item: itemQuery,
	itemStatus: itemStatusQuery,
}

export { queries as companyChatRoleplaySessionQueries, keys as companyChatRoleplaySessionQueriesKeys }
