import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { companyChatRoleplayScenarioMutations } from '#src/api/icp/company/chat/roleplay/scenario/mutations'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const loader = async ({ params }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['scenarioId'])

	const data = await companyChatRoleplayScenarioMutations.testSession(company.id, params.scenarioId)

	return redirect(
		routes.enable.roleplay.session.index({
			companyId,
			conversationId: data.conversationId,
		}),
	)
}
