import { type ChangeEvent, forwardRef, type InputHTMLAttributes } from 'react'
import { Icon, type IconName } from '#src/components/ui/icon'
import { fileInputClasses, inputCommonClasses } from '#src/theme'
import { cn } from '#src/utils/misc'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	isLoading?: boolean
	clearable?: boolean
	iconRight?: IconName
	iconLeft?: IconName
	onClear?: () => void
}

const Input = forwardRef<HTMLInputElement, InputProps>(
	({ className, type, readOnly, isLoading, clearable, iconLeft, iconRight, onClear, ...props }, ref) => {
		const handleOnClear = () => {
			const event = {
				target: { value: '' },
			} as ChangeEvent<HTMLInputElement>

			if (props.onChange) {
				props.onChange(event)
			}

			if (onClear) {
				onClear()
			}
		}

		return (
			<div className="relative">
				{iconLeft && (
					<div className="absolute left-3 top-1/2 -translate-y-1/2 transform">
						<Icon name={iconLeft} className="h-4 w-4 text-neutral-4-fg" />
					</div>
				)}
				{iconRight && (!props.value || !clearable) && (
					<div className="absolute right-3 top-1/2 -translate-y-1/2 transform">
						<Icon name={iconRight} className="h-4 w-4 text-neutral-4-fg" />
					</div>
				)}
				{clearable && props.value && !props.disabled && (
					<button
						onClick={handleOnClear}
						className={cn('absolute right-3 top-1/2 flex -translate-y-1/2 transform cursor-pointer items-center')}
						type="button"
					>
						<Icon name="close-filled" className="h-4 w-4 text-neutral-3-fg hover:text-neutral-4-fg" />
					</button>
				)}
				<input
					type={type}
					className={cn(
						inputCommonClasses,
						fileInputClasses,
						className,
						(clearable || iconRight || onClear) && 'pr-8',
						iconLeft && 'pl-8',
					)}
					ref={ref}
					tabIndex={readOnly ? -1 : undefined}
					readOnly={readOnly}
					{...props}
				/>
				{isLoading && (
					<div className="absolute right-3 top-1/2 -translate-y-1/2 transform">
						<div className="h-4 w-4 animate-spin rounded-full border-2 border-neutral-3-bd border-t-transparent"></div>
					</div>
				)}
			</div>
		)
	},
)
Input.displayName = 'Input'

export { Input }
