import { parseWithZod } from '@conform-to/zod'
import { captureException } from '@sentry/react'
import { type ActionFunctionArgs } from 'react-router-dom'
import { type z } from 'zod'
import { companyChatRoleplayScenarioMutations } from '#src/api/icp/company/chat/roleplay/scenario/mutations'
import { type EditScenarioFormSchema } from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import { showToast } from '#src/context/ToastContext'
import { validateLinkedinUrl } from '#src/routes/enable/chat/mutations'
import { getActiveScenarioTabSchema } from '#src/routes/enable/roleplay-scenarios/schema'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['scenarioId'])

	const formData = await request.formData()
	const activeTab = formData.get('activeTab')
	const TabSchema = getActiveScenarioTabSchema(activeTab as string)

	const submission = parseWithZod(formData, {
		schema: TabSchema,
	})

	if (submission.status !== 'success') {
		throw Error('Failed to parse form data')
	}

	try {
		const fullData = JSON.parse(submission.payload.fullData as string) as z.infer<typeof EditScenarioFormSchema>

		delete submission.payload.activeTab
		delete submission.payload.fullData

		let contactId: string | undefined = fullData.contactId

		const activeTab = formData.get('activeTab') as string

		if (activeTab === 'persona-mapping') {
			if ('linkedinUrl' in submission.value && 'personaId' in submission.value && submission.value.linkedinUrl) {
				const linkedinPayload = {
					linkedinUrl: submission.value.linkedinUrl,
					personaId: submission.value.personaId,
				}
				const res = await validateLinkedinUrl(company.id, linkedinPayload)
				contactId = res.uuid
			} else {
				delete submission.payload.linkedinUrl
				delete fullData.linkedinUrl
				delete submission.payload.contactId
				delete fullData.contactId
				contactId = ''
			}
		}

		const payload = {
			...fullData,
			...submission.payload,
			...(contactId && { contactId }),
		}

		try {
			await companyChatRoleplayScenarioMutations.update(company.id, params.scenarioId, payload)

			showToast({
				message: 'Saved',
				type: 'success',
			})

			return {
				ok: true,
				result: {
					...submission.reply(),
				},
			}
		} catch (err) {
			captureException(err)
		}
	} catch (err) {
		return {
			ok: false,
			result: submission.reply({
				fieldErrors: {
					linkedinUrl: ['Unable to verify LinkedIn profile'],
				},
			}),
		}
	}
}
