import { queryOptions } from '@tanstack/react-query'
import { client as queryClient } from '#src/main'
import { api } from '#src/utils/endpoints'
import { PERSONA_TYPE_RANK, PRIORITY_RANK, STATUS_RANK } from '#src/utils/enumerations'
import { safeFetch } from '#src/utils/safeFetch'
import { EcosystemAPISchema, EcosystemListAPISchema, EcosystemTemplateListAPISchema } from './schema'

export const ecosystemKeys = {
	all: ['ecosystem'] as const,
	list: (
		companyId: string,
		query?: {
			verticals?: boolean
			personas?: boolean
			exclude?: boolean
		},
	) => [...ecosystemKeys.all, 'list', companyId, JSON.stringify(query)] as const,
	details: () => [...ecosystemKeys.all, 'details'] as const,
	detail: (companyId: string, ecosystemId: string) => [...ecosystemKeys.details(), companyId, ecosystemId] as const,
	datapoint: (companyId: string, ecosystemId: string) =>
		[...ecosystemKeys.details(), 'datapoint', companyId, ecosystemId] as const,
	templates: (companyId: string) => [...ecosystemKeys.all, 'templates', companyId] as const,
}

export const ecosystemsQuery = (
	companyId: string,
	query?: {
		verticals?: boolean
		personas?: boolean
		exclude?: boolean
	},
) => ({
	queryKey: ecosystemKeys.list(companyId, query),
	queryFn: async () => {
		const ecosystems = await safeFetch(EcosystemListAPISchema, api.ecosystem.list(companyId, query), {
			method: 'GET',
		})

		return ecosystems.map(ecosystem => {
			const sortedVerticals = [...ecosystem.verticals].sort((a, b) => {
				const statusA = STATUS_RANK[a.status]
				const statusB = STATUS_RANK[b.status]
				const statusComparison = statusA - statusB
				if (statusComparison !== 0) {
					return statusComparison
				}

				return PRIORITY_RANK[a.priority] - PRIORITY_RANK[b.priority]
			})

			const verticals = sortedVerticals.map(vertical => {
				return {
					...vertical,
					personas: [...vertical.personas].sort((a, b) => {
						const typeA = PERSONA_TYPE_RANK[a.type]
						const typeB = PERSONA_TYPE_RANK[b.type]
						const typeComparison = typeA - typeB
						if (typeComparison !== 0) {
							return typeComparison
						}

						const statusA = STATUS_RANK[a.status]
						const statusB = STATUS_RANK[b.status]
						const statusComparison = statusA - statusB
						if (statusComparison !== 0) {
							return statusComparison
						}

						return PRIORITY_RANK[a.priority] - PRIORITY_RANK[b.priority]
					}),
				}
			})

			return {
				...ecosystem,
				verticals,
			}
		})
	},
})
export const ecosystemQuery = (companyId: string, ecosystemId: string) => ({
	queryKey: ecosystemKeys.detail(companyId, ecosystemId),
	queryFn: async () => {
		return await safeFetch(EcosystemAPISchema, api.ecosystem.details(companyId, ecosystemId), {
			method: 'GET',
		})
	},
	initialData: () => {
		const ecosystems = queryClient.getQueryData(ecosystemKeys.list(companyId)) as Awaited<
			ReturnType<ReturnType<typeof ecosystemsQuery>['queryFn']>
		> | null

		if (ecosystems?.length) {
			const ecosystem = ecosystems.find(v => v.id === Number(ecosystemId))

			if (ecosystem && EcosystemAPISchema.safeParse(ecosystem).success) {
				return ecosystem
			}

			return undefined
		}

		return undefined
	},
})

export const ecosystemsTemplatesQuery = (companyId: string) =>
	queryOptions({
		queryKey: ecosystemKeys.templates(companyId),
		queryFn: async () => {
			return await safeFetch(EcosystemTemplateListAPISchema, api.ecosystem.templates(companyId), {
				method: 'GET',
			})
		},
	})
