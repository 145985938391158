import { Link, useLoaderData, useNavigate } from 'react-router-dom'
import { EmptyStateCard } from '#src/components'
import { Chip } from '#src/components/chip'
import FiltersSection from '#src/components/FiltersSection'
import { Icon } from '#src/components/ui/icon'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import { PageLayout } from '#src/components/ui/PageLayout'
import useCompany from '#src/hooks/useCompany'
import LayoutContentHack from '#src/layouts/LayoutContentHack'
import { type PlaysListLoaderRes } from '#src/routes/enable/plays/routes/list'
import { Table } from '#src/routes/prioritize/views/components/Table'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const View = () => {
	const {
		data: { plays },
		filters,
	} = useLoaderData() as PlaysListLoaderRes
	const { companyId } = useCompany()
	const navigate = useNavigate()

	return (
		<PageLayout
			title="Plays"
			actions={[
				{
					to: routes.enable.plays.create({ companyId }),
					icon: 'add',
					title: 'Add new',
					variant: 'default',
				},
			]}
		>
			<FiltersSection
				selected={filters.selected}
				values={filters.values}
				filters={[
					{
						name: 'personaType',
						label: 'Persona type',
					},
					{
						name: 'expertise',
						label: 'Expertise',
					},
					{
						name: 'tags',
						label: 'Tags',
					},
					{
						name: 'inCrm',
						label: 'Used in LRA',
						multiple: false,
					},
				]}
			/>
			{plays.length ? (
				<LayoutContentHack>
					<Table
						data={plays}
						expandable
						onItemClick={item => navigate(routes.enable.plays.edit({ companyId, playId: String(item.id) }))}
						columns={[
							{
								name: 'name',
								heading: {
									title: 'Name',
								},
								body: {
									render: item => item.name,
								},
							},
							{
								name: 'personaType',
								heading: {
									title: 'Persona Type',
								},
								body: {
									render: item => (
										<>
											{item.personaTypes.length ? (
												<div className="flex flex-row flex-wrap gap-1">
													{item.personaTypes.map(item => (
														<Chip variant="blue" key={`personaType-${item}`}>
															{item}
														</Chip>
													))}
												</div>
											) : (
												<span className="text-body-sm text-neutral-3-fg">-</span>
											)}
										</>
									),
								},
							},
							{
								name: 'expertise',
								heading: {
									title: 'Expertise',
								},
								body: {
									render: item => (
										<>
											{item.expertise.length ? (
												<div className="flex flex-row flex-wrap gap-1">
													{item.expertise.map(item => (
														<Chip variant="indigo" key={`expertise-${item}`}>
															{item}
														</Chip>
													))}
												</div>
											) : (
												<span className="text-body-sm text-neutral-3-fg">-</span>
											)}
										</>
									),
								},
							},
							{
								name: 'tags',
								heading: {
									title: 'Tags',
								},
								body: {
									render: item => (
										<>
											{item.tags.length ? (
												<div className="flex flex-row flex-wrap gap-1">
													{item.tags.map(item => (
														<Chip variant="gray" key={`tag-${item}`}>
															{item}
														</Chip>
													))}
												</div>
											) : (
												<span className="text-body-sm text-neutral-3-fg">-</span>
											)}
										</>
									),
								},
							},
							{
								name: 'used_in',
								heading: {
									title: 'Used in',
									className: 'w-[150px]',
								},
								body: {
									render: item => (
										<div className="flex flex-col">
											<div
												className={cn(
													'inline-flex items-center gap-1 text-body-md',
													item.inCrm ? 'text-neutral-1-fg' : 'text-neutral-3-fg',
												)}
											>
												<Icon
													name={item.inCrm ? 'checkmark-filled' : 'checkmark-outline'}
													size="sm"
													className={cn(item.inCrm && 'text-green-70')}
												/>
												LRA
											</div>
											<div
												className={cn(
													'inline-flex items-center gap-1 text-body-md',
													item.inCopilot ? 'text-neutral-1-fg' : 'text-neutral-3-fg',
												)}
											>
												<Icon
													name={item.inCopilot ? 'checkmark-filled' : 'checkmark-outline'}
													size="sm"
													className={cn(item.inCopilot && 'text-green-70')}
												/>
												Copilot
											</div>
										</div>
									),
								},
							},
							{
								name: 'actions',
								heading: {
									title: '',
									className: 'w-20',
								},
								body: {
									render: item => (
										<div
											className="flex flex-row justify-end gap-4"
											onClick={e => {
												e.preventDefault()
												e.stopPropagation()
											}}
										>
											<Link
												to={routes.enable.plays.delete({ companyId, playId: String(item.id) })}
												className="inline-flex h-full items-center gap-1 whitespace-nowrap"
											>
												<Icon name="trash" size="sm" className="text-status-danger-fg" />
											</Link>
										</div>
									),
								},
							},
						]}
						expandableColumns={[
							{
								name: 'empty-1',
								body: {
									render: () => null,
								},
							},
							{
								name: 'instructions',
								body: {
									colSpan: 3,
									render: item => <LabeledValue label="Instructions">{item.instructions}</LabeledValue>,
								},
							},
							{
								name: 'empty-2',
								body: {
									colSpan: 3,
									render: () => null,
								},
							},
						]}
					/>
				</LayoutContentHack>
			) : (
				<EmptyStateCard
					icon="chat"
					title="You don't have any plays"
					actions={[
						{
							to: routes.enable.plays.create({ companyId }),
							title: 'Add new',
						},
					]}
				/>
			)}
		</PageLayout>
	)
}
