import { LRA_JOB_STATUS } from '#src/api/organise/lists/constants'

export const JobStatusName: Record<LRA_JOB_STATUS, string> = {
	[LRA_JOB_STATUS.Launching]: 'In queue',
	[LRA_JOB_STATUS.InProgress]: 'In progress...',
	[LRA_JOB_STATUS.Completed]: 'Completed',
	[LRA_JOB_STATUS.CompletedWithQuestions]: 'Completed',
	[LRA_JOB_STATUS.Cancelled]: 'Stopped',
	[LRA_JOB_STATUS.Error]: 'Error',
} as const

export enum FORM_STEPS {
	File = 'file',
	Verticals = 'verticals',
	Settings = 'settings',
	AdditionalInfo = 'additional_info',
}
