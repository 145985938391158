import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react'
import { selectionControlDisabledVariants, selectionControlSizeVariants } from '#src/theme'
import { cn } from '#src/utils/misc'
import { Icon } from './icon'

export const checkboxVariants = cva('peer rounded-sm border-2 ring-offset-neutral-2-bg', {
	variants: {
		size: selectionControlSizeVariants,
		disabled: {
			true: selectionControlDisabledVariants.true + ' opacity-100 border-neutral-3-bd-disabled',
			false: selectionControlDisabledVariants.false + ' border-neutral-3-bd',
		},
		checked: {
			true: 'bg-brand-3-bg border-brand-3-bg text-white',
		},
	},
	defaultVariants: {
		size: 'md',
		disabled: false,
	},
})

export type CheckboxProps = Omit<ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>, 'type'> & {
	size?: VariantProps<typeof checkboxVariants>['size']
	disabled?: boolean
	checked?: 'indeterminate' | boolean
}

const Checkbox = forwardRef<ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
	({ className, size, disabled, checked, ...props }, ref) => (
		<CheckboxPrimitive.Root
			ref={ref}
			className={cn(checkboxVariants({ size, disabled, checked: !!checked }), className)}
			disabled={disabled}
			checked={checked}
			{...props}
		>
			<CheckboxPrimitive.Indicator className="flex items-center justify-center text-current">
				<Icon name={checked === 'indeterminate' ? 'minus' : 'checkmark'} className="h-3.5 w-3.5" />
			</CheckboxPrimitive.Indicator>
		</CheckboxPrimitive.Root>
	),
)
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
