import { type z } from 'zod'
import { type ValuePropositionItemAPISchema } from '#src/api/icp/company/value-proposition/schemas'
import DeleteConfirm from '#src/components/delete-confirm'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'

export const DeletePointModal = ({ item }: { item: z.infer<typeof ValuePropositionItemAPISchema> }) => {
	const { companyId } = useCompany()

	return (
		<DeleteConfirm
			trigger={
				<Button variant="icon" size="icon" className="text-status-danger-fg">
					<Icon name="trash" size="font" />
				</Button>
			}
			action={routes.calibrate.valueProposition.delete({ companyId, pointId: String(item.id) })}
			title={`Delete ${item.name}?`}
			description={`Are you sure you want to remove ${item.name} point from value proposition?`}
			formId={`ValuePropositionPoint-${item.id}`}
		/>
	)
}
