import { Link, useLoaderData } from 'react-router-dom'
import { DashboardCard, DashboardCardCount } from '#src/components/dashboard-card'
import ProductTip from '#src/components/product-tip'
import useCompany from '#src/hooks/useCompany'
import { type IndexLoaderRes } from '#src/routes/calibrate/dashboard/routes'
import { routes } from '#src/utils/routes'
import { type IconName } from '@/icon-name'

export const Cards = () => {
	const { companyId } = useCompany()
	const {
		data: { statistics },
	} = useLoaderData() as IndexLoaderRes

	const cardData = [
		{
			link: routes.calibrate.valueProposition.index({ companyId }),
			icon: 'white-paper',
			title: (
				<div className="flex items-center gap-1">
					Value prop
					<ProductTip
						className="text-neutral-3-fg"
						content="Explains how your product or service solves a problem, delivers benefits, and why it is better than the alternatives. It includes the most important information about your company, tailored to refine your verticals and personas. Built with a customer-centric approach, including your products’ offerings, features, benefits, verticals, personas, insights from customer stories, USPs, other relevant insights, and pricing (Min. 3500 words)."
					/>
				</div>
			),
			count: statistics.calibrate.value_proposition_count,
			countSuffix: statistics.calibrate.value_proposition_count > 1 ? 'Points' : 'Point',
			condition: true,
		},
		{
			link: {
				pathname: routes.enable.glossary.index({ companyId }),
				search: '?source=calibrate',
			},
			icon: 'notebook-reference',
			title: (
				<div className="flex items-center gap-1">
					Industry jargons
					<ProductTip
						className="text-neutral-3-fg"
						content="Industry Jargon: Specialized terms and phrases commonly used within a specific industry or field, enriched with AI. Synchronized with an ecosystem of your choice to enhance relevance and contextual accuracy."
					/>
				</div>
			),
			count: statistics.enableNew.terms,
			countSuffix: statistics.enableNew.terms ? 'Terms' : 'Term',
			condition: true,
		},
	]

	return (
		<div className="grid w-full grid-cols-3 grid-rows-1 gap-6">
			{cardData.map(
				({ link, icon, title, count, countSuffix, condition }) =>
					condition && (
						<Link key={icon} to={link} className="block">
							<DashboardCard icon={icon as IconName} title={title} isHoverable>
								<DashboardCardCount count={count} countSuffix={countSuffix} />
							</DashboardCard>
						</Link>
					),
			)}
		</div>
	)
}
