import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { type z } from 'zod'
import DeleteConfirm from '#src/components/delete-confirm'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem/schema'
import { cn, validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { deleteEcosystem } from './mutations'
import { ecosystemKeys } from './queries'

export const action = async ({ params }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['ecosystemId'])

	await deleteEcosystem(company.id, params.ecosystemId)

	await client.invalidateQueries({
		queryKey: ecosystemKeys.all,
	})

	return redirect(
		routes.calibrate.index({
			companyId,
		}),
	)
}

export interface DeleteEcosystemAsyncFormProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystem: z.infer<typeof EcosystemAPISchema>
	children: ReactNode
	onSubmit?: () => void
}

export const DeleteEcosystemAsyncForm = forwardRef<HTMLButtonElement, DeleteEcosystemAsyncFormProps>(
	({ ecosystem, children, className }, ref) => {
		const { companyId } = useCompany()
		const action = routes.calibrate.ecosystem.delete({
			companyId,
			ecosystemId: String(ecosystem.id),
		})

		return (
			<DeleteConfirm
				trigger={
					<button type="button" className={cn('w-full', className)} ref={ref}>
						{children}
					</button>
				}
				action={action}
				title={`Delete ${ecosystem.verticalAsCompany ? 'Key Accounts' : 'Ecosystem'}?`}
				description={
					ecosystem.verticalAsCompany
						? 'This action cannot be undone. This will permanently delete the key accounts and remove any associated data.'
						: 'This action cannot be undone. This will permanently delete the ecosystem and remove any associated data such as verticals and personas.'
				}
			/>
		)
	},
)
DeleteEcosystemAsyncForm.displayName = 'DeleteEcosystemAsyncForm'
