import { type z } from 'zod'
import {
	type LeadResearchAgentItemAPISchema,
	type LeadResearchAgentJobFormSchema,
} from '#src/api/organise/lists/schemas'
import { client } from '#src/main'
import { objectToFormData } from '#src/utils/forms'
import { fetch } from '#src/utils/safeFetch'
import { orgListsEndpoints } from './endpoints'
import { orgListsQueriesKeys } from './queries'

const mutations = {
	stop: async (jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>) => {
		const res = await fetch(orgListsEndpoints.itemStop(jobId), {
			method: 'POST',
		})

		await client.invalidateQueries({
			queryKey: orgListsQueriesKeys.all,
		})

		return res
	},
	create: async (data: z.infer<typeof LeadResearchAgentJobFormSchema>) => {
		const body = objectToFormData(data)
		const res = await fetch(orgListsEndpoints.create(), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
			},
			body,
		})

		await client.invalidateQueries({
			queryKey: orgListsQueriesKeys.all,
		})

		return res
	},
}

export { mutations as orgListsMutations }
