import { z } from 'zod'

export const DashboardSchema = z.object({
	id: z.number(),
	name: z.string(),
	url: z.string().url().nullable().optional(),
})

export const CompanySchema = z.object({
	id: z.string(),
	name: z.string(),
	dashboard: DashboardSchema.array().nullable().optional(),
})

export const CompaniesSchema = CompanySchema.array()
