import { zodResolver } from '@hookform/resolvers/zod'
import { type ReactNode, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router-dom'
import { type z } from 'zod'
import {
	type ContactWaterfallingItemAPISchema,
	ContactWaterfallingJobDownloadFormSchema,
	WORKFLOW_EXPORT_TYPE,
} from '#src/api/organise/data-enrichment/workflows/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Dialog, DialogTrigger } from '#src/components/ui/dialog'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'
import { type ActionRes } from '../../actions/download'
import { type LoaderRes } from '../../loaders/download'

const getFormName = (jobId: z.infer<typeof ContactWaterfallingItemAPISchema.shape.id>) => `cw-job-${jobId}-download`

type FormProps = {
	jobId: z.infer<typeof ContactWaterfallingItemAPISchema.shape.id>
}

const Form = (props: FormProps) => {
	const { jobId } = props
	const loaderFetcher = useFetcher<LoaderRes>()
	const actionFetcher = useFetcher<ActionRes>({
		key: 'cw-job-download-action',
	})
	const { companyId } = useCompany()

	// const data = loaderFetcher.data
	// const job = data?.data.job

	useEffect(() => {
		if (loaderFetcher.state === 'idle' && !loaderFetcher.data) {
			loaderFetcher.load(routes.organize.contactWaterfalling.download({ jobId, companyId }))
		}
	}, [loaderFetcher, jobId, companyId])

	const form = useForm<z.infer<typeof ContactWaterfallingJobDownloadFormSchema>>({
		resolver: zodResolver(ContactWaterfallingJobDownloadFormSchema),
		defaultValues: {
			type: WORKFLOW_EXPORT_TYPE.All,
		},
		mode: 'onSubmit',
	})

	return (
		<FormWrapper
			formId={getFormName(jobId)}
			formProps={form}
			onSubmit={e =>
				actionFetcher.submit(e, {
					method: 'POST',
					action: routes.organize.contactWaterfalling.download({ jobId, companyId }),
				})
			}
		>
			<FormField
				fieldType="radioCards"
				disableLabel
				name="type"
				className="flex-col"
				options={[
					{
						value: WORKFLOW_EXPORT_TYPE.All,
						label: 'All contacts',
					},
					{
						value: WORKFLOW_EXPORT_TYPE.OnlyFound,
						label: 'Only contacts with email or phone found',
					},
				]}
			/>
		</FormWrapper>
	)
}

type JobDownloadFormProps = {
	jobId: z.infer<typeof ContactWaterfallingItemAPISchema.shape.id>
	children: ReactNode
}

export const JobDownloadModal = (props: JobDownloadFormProps) => {
	const { jobId, children } = props
	const actionFetcher = useFetcher<ActionRes>({
		key: 'cw-job-download-action',
	})

	const [open, setOpen] = useState(false)

	return (
		<Dialog
			open={open}
			onOpenChange={setOpen}
			trigger={<DialogTrigger asChild>{children}</DialogTrigger>}
			dialogHeading="Select export options"
			isSubmitting={actionFetcher.state === 'submitting'}
			headerProps={{
				dialogHeading: 'Select export options',
			}}
			actions={[
				{
					type: 'cancel',
					label: 'Cancel',
					onClick: () => setOpen(false),
				},
				{
					type: 'submit',
					label: 'Download',
					formId: getFormName(jobId),
				},
			]}
		>
			<Form jobId={jobId} />
		</Dialog>
	)
}
