import { type RouteObject } from 'react-router-dom'
import copilotRouter from '#src/routes/settings/copilot/router'
import { loader as settingsLoader } from '#src/routes/settings/dashboard/loaders/root'
import { View as SettingsDashboardView } from '#src/routes/settings/dashboard/views/dashboard'
import teamRouter from '#src/routes/settings/team/router'

export default {
	path: 'settings',
	loader: settingsLoader,
	handle: {
		crumb: () => [
			{
				label: 'Settings',
				icon: 'settings',
			},
		],
	},
	children: [
		{
			index: true,
			element: <SettingsDashboardView />,
		},
		teamRouter,
		copilotRouter,
	],
} satisfies RouteObject
