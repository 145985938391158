import { useCallback } from 'react'
import { useLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { type ScenarioTemplatesAPISchema } from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import { TrainingAgenda } from '#src/components/training-agenda'

type UseScenarioTemplateLoaderType = {
	scenarioTemplates: z.infer<typeof ScenarioTemplatesAPISchema>
}

export const useScenarioTemplate = () => {
	const { scenarioTemplates } = useLoaderData() as UseScenarioTemplateLoaderType

	const getAgendaOptions = useCallback(() => {
		return scenarioTemplates.map(({ id, name }) => ({
			value: id.toString(),
			label: <TrainingAgenda title={name} icon="user-service-desk" />,
		}))
	}, [scenarioTemplates])

	const getTemplateById = useCallback(
		(id: string) => {
			return scenarioTemplates.find(template => template.id.toString() === id) || null
		},
		[scenarioTemplates],
	)

	return { getAgendaOptions, getTemplateById, scenarioTemplates }
}
