// eslint-disable-next-line import/no-named-as-default
import Intercom from '@intercom/messenger-js-sdk'
import useAuth from '#src/hooks/useAuth'

const SupportInit = () => {
	const { user } = useAuth()

	if (import.meta.env.VITE_INTERCOM_ID) {
		Intercom({
			app_id: import.meta.env.VITE_INTERCOM_ID,
			user_id: user.userId,
			company_id: user.companyId,
		})
	}

	return null
}

export default SupportInit
