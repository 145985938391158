import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import DeleteConfirm from '#src/components/delete-confirm'
import { showToast } from '#src/context/ToastContext'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { cn, validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { deletePersona } from './mutations'
import { personaKeys } from './queries'

export const action = async ({ params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['personaId', 'verticalId', 'ecosystemId'])
	const { company, companyId } = await getCompany(params)

	try {
		await deletePersona(company.id, params.personaId)
		await client.invalidateQueries({
			queryKey: personaKeys.all,
		})
		await client.invalidateQueries({
			queryKey: ecosystemKeys.list(company.id),
		})
		showToast({
			type: 'success',
			message: 'Persona deleted.',
		})
		return redirect(
			routes.calibrate.persona.index({
				companyId,
				ecosystemId: params.ecosystemId,
				verticalId: params.verticalId,
				personaId: null,
			}),
		)
	} catch (_) {
		showToast({
			type: 'error',
			message: 'Unexpected error occurred.',
		})
	}

	return null
}

export interface DeletePersonaAsyncFormProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystemId: string
	verticalId: string
	personaId: string
	children: ReactNode
}

export const DeletePersonaAsyncForm = forwardRef<HTMLButtonElement, DeletePersonaAsyncFormProps>(
	({ ecosystemId, verticalId, personaId, children, className, ...props }, ref) => {
		const { companyId } = useCompany()
		const action = routes.calibrate.persona.delete({
			companyId,
			ecosystemId: ecosystemId,
			verticalId: verticalId,
			personaId: personaId,
		})

		return (
			<DeleteConfirm
				trigger={
					<button {...props} ref={ref} type="button" className={cn('flex items-center justify-center', className)}>
						{children}
					</button>
				}
				action={action}
				title="Delete persona?"
				description="This action cannot be undone. This will permanently delete the persona and it's associated data."
			/>
		)
	},
)
DeletePersonaAsyncForm.displayName = 'DeletePersonaAsyncForm'
